import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ConverterService,
  OccEndpointsService,
  UserIdService,
  backOff,
  isJaloError,
  tryNormalizeHttpError
} from '@spartacus/core';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, map, mergeAll, take } from 'rxjs/operators';
import { CustomOcc } from '../../../occ-models/custom-occ.models';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { PaymentType, CART_NORMALIZER, ActiveCartFacade } from '@spartacus/cart/base/root';
import { OccCheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/occ';
import { CHECKOUT_PAYMENT_TYPE_NORMALIZER, CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
import { CustomCheckoutPaymentTypeAdapter } from '../../../../checkout/connectors/payment-type/custom-checkout-payment-type.adapter';
import { CheckoutState } from '@spartacus/checkout/base/root';
import { CHECKOUT_NORMALIZER } from '@spartacus/checkout/base/core';

@Injectable()
export class CustomOccCheckoutPaymentTypeAdapter extends OccCheckoutPaymentTypeAdapter implements CustomCheckoutPaymentTypeAdapter {
  cartId: string;
  userId: string;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected activeCartService: ActiveCartService,
    protected store: Store,
    protected userIdService: UserIdService,
    protected activeCartFacade: ActiveCartFacade,
  ) {
    super(http, occEndpoints, converter);
  }

  // set activeCartId to cartId
  getCartId(): void {
    this.activeCartService
      .getActiveCartId()
      .subscribe((response) => {
        this.cartId = response;
      })
      .unsubscribe();
  }

  getUserID(): void {
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (this.userId = occUserId))
      .unsubscribe();
  }

  getPaymentTypes(): Observable<PaymentType[]> {
    var paymentTypes$ = combineLatest([
      this.activeCartFacade.takeActiveCartId(),
      this.userIdService.getUserId()
    ]).pipe(
      take(1),
      map(([cartId, userId]) => {
        var url = this.occEndpoints.buildUrl('paymentMethods', {
          urlParams: { userId: userId, cartId: cartId }
        })
        return this.http
          .get<CustomOcc.PaymentTypeList>(url)
          .pipe(
            map((paymentTypeList) => {
              return paymentTypeList.paymentModes
                .filter((paymentType, index, paymentTypes) =>
                  paymentTypes.findIndex(item => item.code === paymentType.code) === index
                ) ?? [];
            }),
            this.converter.pipeableMany(CHECKOUT_PAYMENT_TYPE_NORMALIZER),
          );
      })
    )
    return paymentTypes$.pipe(mergeAll())
  }

  setPaymentType(
    userId: string,
    cartId: string,
    paymentType: string,
    purchaseOrderNumber?: string
  ): Observable<PaymentType> {
    let httpParams = new HttpParams().set('code', paymentType);
    if (purchaseOrderNumber !== undefined) {
      httpParams = httpParams.set('purchaseOrderNumber', purchaseOrderNumber);
    }
    httpParams = httpParams.set(
      'fields',
      'DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,paymentMode(FULL)',
    );

    return this.http
      .put(
        this.getCartEndpoint(userId, cartId),
        {},
        {
          params: httpParams,
        },
      )
      .pipe(this.converter.pipeable(CART_NORMALIZER));
  }

  protected getCartEndpoint(userId: string, cartId: string): string {
    return this.occEndpoints.buildUrl('paymentMode', {
      urlParams: { userId, cartId }
    });
  }

  creditCardList(userId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(this.occEndpoints.buildUrl('creditCardList', {
      urlParams: { userId: userId, cartId: this.cartId }
    }), { headers });
  }

  getSelectedCreditCard(userId: string, paymentDetailsId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(this.occEndpoints.buildUrl('savedCreditCard', {
      urlParams: { userId, paymentDetailsId }
    }), { headers });
  }

  setSelectedCreditCard(userId: string, cartId: string, paymentDetailsId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(this.occEndpoints.buildUrl('updateSavedCreditCard', {
      urlParams: { userId, cartId, paymentDetailsId }
    }), { headers });
  }

  removeSelectedCreditCard(userId: string, paymentDetailsId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete(this.occEndpoints.buildUrl('savedCreditCard', {
      urlParams: { userId, paymentDetailsId }
    }), { headers });
  }

  getCheckoutDetails(
    userId: string,
    cartId: string
  ): Observable<CheckoutState> {
    return this.http
      .get<CheckoutState>(this.getGetCheckoutDetailsEndpoint(userId, cartId))
      .pipe(
        catchError((error) => throwError(() => tryNormalizeHttpError(error, this.logger))),
        backOff({
          shouldRetry: isJaloError,
        }),
        this.converter.pipeable(CHECKOUT_NORMALIZER)
      );
  }

  protected getGetCheckoutDetailsEndpoint(
    userId: string,
    cartId: string
  ): string {
    return this.occEndpoints.buildUrl('getCustomCheckoutDetails', {
      urlParams: {
        userId,
        cartId,
      },
    });
  }
}
