import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGiftboxItemComponent } from './custom-giftbox-item.component';
import { RouterModule } from '@angular/router';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { I18nModule, UrlModule } from '@spartacus/core';
import { CustomAddGiftboxToCartDialogComponent } from '../custom-add-giftbox-to-cart-dialog/custom-add-giftbox-to-cart-dialog.component';
import { CustomAddProductToCartWithGiftboxDialogComponent } from '../custom-add-product-to-cart-with-giftbox-dialog/custom-add-product-to-cart-with-giftbox-dialog.component';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [
    CustomGiftboxItemComponent,
    CustomAddGiftboxToCartDialogComponent,
    CustomAddProductToCartWithGiftboxDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    I18nModule,
    UrlModule,
    MediaModule,
    IconModule,
  ],
  exports: [
    CustomGiftboxItemComponent,
    CustomAddGiftboxToCartDialogComponent,
    CustomAddProductToCartWithGiftboxDialogComponent,
  ],
})
export class CustomGiftboxItemModule {}
