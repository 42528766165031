import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaymentFormComponent } from './custom-payment-form.component';
import { CustomPaymentTypeModule } from '../payment-type/custom-payment-type.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CardModule, FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [CustomPaymentFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    FormErrorsModule,
    CustomPaymentTypeModule,
  ],
  exports: [CustomPaymentFormComponent],
})
export class CustomPaymentFormModule { }
