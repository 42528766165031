import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { CustomNotificationPreferenceComponent } from './custom-notification-preference.component';

@NgModule({
  declarations: [CustomNotificationPreferenceComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NotificationPreferenceComponent: {
          component: CustomNotificationPreferenceComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig)
  ],
  exports: [CustomNotificationPreferenceComponent],
})
export class CustomNotificationPreferenceModule {}
