import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, from, of } from 'rxjs';
import {
  CustomBaseStoreActionTypes,
  LoadBaseStoreList,
  LoadBaseStoreListFail,
  LoadBaseStoreListSuccess,
  PurgeBaseStoreList,
  UpdateBaseStore,
  UpdateBaseStoreFail,
  UpdateBaseStoreSuccess,
} from '../actions/custom-base-store.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CustomBaseStoreConnector } from '../../connectors/custom-base-store.connector';
import { CustomBaseStoreModel } from '../../custom-base-store.model';
import {LoggerService, tryNormalizeHttpError, SiteContextActions, StateWithProcess, UserIdService, WindowRef, withdrawOn} from '@spartacus/core';
import { Action, Store, select } from '@ngrx/store';
import { StateWithBaseStore } from '../custom-base-store.state';
import { CustomOcc } from 'src/app/spartacus/custom/core/occ/occ-models/custom-occ.models';
import { getBaseStoreSelected } from '../selectors/custom-base-store.selectors';


@Injectable()
export class CustomBaseStoreEffects {
  private contextChange$: Observable<Action> = this.actions$.pipe(
    ofType(
      SiteContextActions.LANGUAGE_CHANGE
    )
  );

  constructor(
    private actions$: Actions,
    private customBaseStoreConnector: CustomBaseStoreConnector,
    private userIdService: UserIdService,
    private store: Store<StateWithBaseStore | StateWithProcess<void>>,
    private logger: LoggerService,
    protected winRef?: WindowRef,
  ) {
  }

  loadBaseStores$: Observable<
    | LoadBaseStoreList
    | LoadBaseStoreListSuccess
    | LoadBaseStoreListFail
    | UpdateBaseStoreSuccess
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomBaseStoreActionTypes.LOAD_BASE_STORE_LIST),
    map((action: LoadBaseStoreList) => action.payload),
    mergeMap(({ userId }) =>
      this.customBaseStoreConnector.getAll({ userId }).pipe(
        mergeMap((baseStoreList: CustomOcc.CustomBaseStoreOCCModel) =>
          {
            const actions: Array<
              | LoadBaseStoreListSuccess
              | UpdateBaseStoreSuccess
            > = [
              new LoadBaseStoreListSuccess(baseStoreList.baseStores),
            ];
            let selectedStoreCode: string;
            this.store.pipe(select(getBaseStoreSelected)).subscribe(
              basestoreSelected => {
                selectedStoreCode = basestoreSelected?.code;
              }
            ).unsubscribe();
            if (selectedStoreCode) {
              const updatedSelectedStore = baseStoreList.baseStores.find(baseStore => baseStore.code === selectedStoreCode);
              if (updatedSelectedStore) {
                actions.push(new UpdateBaseStoreSuccess(updatedSelectedStore));
              }
            }
            return from(actions);
          }
        ),
        catchError((error) => {
          this.clearBaseStoreLocalStorage();
          return from([new LoadBaseStoreListFail(tryNormalizeHttpError(error, this.logger))]);
        }),
      )
    ),
    withdrawOn(this.contextChange$)
  ));

  updateBaseStores$: Observable<UpdateBaseStore | UpdateBaseStoreSuccess | UpdateBaseStoreFail | any> =
  createEffect(() => this.actions$.pipe(
    ofType(CustomBaseStoreActionTypes.UPDATE_BASE_STORE),
    map((action: UpdateBaseStore) => action.baseStore),
    mergeMap((formData: CustomBaseStoreModel) => {
      return this.customBaseStoreConnector.get(formData.code).pipe(
        map((baseStore) => {
            return new UpdateBaseStoreSuccess(baseStore);
          },
        ),
        catchError((error) =>
          of(new UpdateBaseStoreFail(tryNormalizeHttpError(error, this.logger))),
        ),
      );
    }),
  ));

  resetLoadBaseStoreListOnSiteContextChange$: Observable<LoadBaseStoreList | PurgeBaseStoreList> =
  createEffect(() => this.actions$.pipe(
    ofType(
      SiteContextActions.LANGUAGE_CHANGE
    ),
    withLatestFrom(
      this.userIdService.takeUserId(),
    ),
    mergeMap(([, userId]) => {
      return [new LoadBaseStoreList({ userId })];
    })
  ));

  clearBaseStoreLocalStorage(): void {
    this.winRef.localStorage.removeItem('currentbasestore');
  }
}
