import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService, EventService, GlobalMessageService, GlobalMessageType, WindowRef } from "@spartacus/core";
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { LoginFormComponentService } from "@spartacus/user/account/components";
import { EcommerceType } from "src/app/spartacus/features/tracking/custom-events/tag-manager-feature.model";
import { CustomUserLoginEvent } from "src/app/spartacus/features/tracking/custom-events/user/custom-user.events";
import { WebAuthnService } from "./webauthn.service";

@Injectable({
    providedIn: 'root'
})
export class CsutomLoginFormComponentService extends LoginFormComponentService {
    loading: boolean = true; 
    constructor(
        protected auth: AuthService,
        protected globalMessage: GlobalMessageService,
        protected winRef: WindowRef,
        protected eventService: EventService,
        protected webAuthnService: WebAuthnService,
    ) {
        super(auth, globalMessage, winRef);
        //this.checkAuthState();
    }

    override form: UntypedFormGroup = new UntypedFormGroup({
      userId: new UntypedFormControl('', {
        validators: [Validators.required, CustomValidators.emailValidator],
        updateOn: 'submit',
      }),
      password: new UntypedFormControl('', {
        validators: Validators.required,
        updateOn: 'submit'
      }),
      useFingerprint: new UntypedFormControl(false),
    });

    checkAuthState(): void {
      this.auth.isUserLoggedIn().subscribe((isLoggedIn) => {
        this.loading = false;
        if (isLoggedIn) {
      
          this.onSuccess(true);
        }
      });
    }

    protected override onSuccess(isLoggedIn: boolean): void {
      if (isLoggedIn) {
        // We want to remove error messages on successful login (primary the bad username/password combination)
        this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
        const useFingerprint = this.form.get('useFingerprint')?.value;
        if (useFingerprint) {
          this.webAuthnService.register(this.form.value.userId.toLowerCase());
        }
        this.form.reset();
      }
  
      this.busy$.next(false);
    }
}
