import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoggerService, tryNormalizeHttpError, WindowRef} from '@spartacus/core';
import { Injectable } from '@angular/core';
import {
  LOAD_CREDIT_CARD_LIST,
  LoadCreditCardList,
  LoadCreditCardListFail,
  LoadCreditCardListSuccess,
  REMOVE_SELECTED_CREDIT_CARD,
  RemoveSelectedCreditCard,
  RemoveSelectedCreditCardFail,
  RemoveSelectedCreditCardSuccess,
  SAVE_CREDIT_CARD,
  SaveCreditCard,
  SaveCreditCardFail,
  SaveCreditCardSuccess,
  SELECT_SAVED_CREDIT_CARD,
  SelectedSavedCreditCard,
  SelectedSavedCreditCardFail,
  SelectedSavedCreditCardSuccess,
} from '../actions/custom-credit-card.action';
import { StorageSaveCreditCard } from '../credit-card.state';
import { CartActions } from '@spartacus/cart/base/core';
import { CustomCheckoutPaymentTypeConnector } from 'src/app/spartacus/custom/core/checkout/connectors/payment-type/custom-checkout-payment-type.connector';


@Injectable()
export class CreditCardEffects {

  LoadCreditCardList$: Observable<| LoadCreditCardList
    | LoadCreditCardListSuccess
    | LoadCreditCardListFail> = createEffect(() => this.actions$.pipe(
    ofType(LOAD_CREDIT_CARD_LIST),
    map((action: LoadCreditCardList) => action.payload),
    switchMap((userId) => {
      return this.paymentTypeConnector.creditCardList(userId).pipe(
        map((cartList) => {
          return new LoadCreditCardListSuccess(cartList);
        }),
        catchError((error) => of(new LoadCreditCardListFail(tryNormalizeHttpError(error, this.logger)))),
      );
    }),
  ));

  saveCreditCard$: Observable<SaveCreditCard
    | SaveCreditCardFail
    | SaveCreditCardSuccess> = createEffect(() => this.actions$.pipe(
    ofType(SAVE_CREDIT_CARD),
    map((action: SaveCreditCard) => action.payload),
    map(({ save }) => {
      this.winRef?.localStorage?.setItem(StorageSaveCreditCard.SAVE_CREDIT_CARD, save.toString());
      return new SaveCreditCardSuccess(save);
    }),
  ));

  selectSavedCreditCard$: Observable<SelectedSavedCreditCard
    | SelectedSavedCreditCardSuccess
    | SelectedSavedCreditCardFail
    | CartActions.LoadCart> = createEffect(() => this.actions$.pipe(
    ofType(SELECT_SAVED_CREDIT_CARD),
    map((action: SelectedSavedCreditCard) => action.payload),
    switchMap(({ userId, cartId, paymentDetailsId }) => {
      return this.paymentTypeConnector.setSelectedCreditCard(userId, cartId, paymentDetailsId).pipe(
        switchMap((creditCard) => {
          return [
            new SelectedSavedCreditCardSuccess(creditCard),
            new CartActions.LoadCart({
              cartId,
              userId,
            }),
          ];
        }),
        catchError((error) => of(new SelectedSavedCreditCardFail(tryNormalizeHttpError(error, this.logger)))),
      );
    }),
  ));

  removeSelectCreditCard$: Observable<RemoveSelectedCreditCard
    | RemoveSelectedCreditCardSuccess
    | RemoveSelectedCreditCardFail
    | LoadCreditCardList> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_SELECTED_CREDIT_CARD),
    map((action: SelectedSavedCreditCard) => action.payload),
    switchMap(({ userId, cartId, paymentType, paymentDetailsId,  }) => {
      return this.paymentTypeConnector.removeSelectedCreditCard(userId, paymentDetailsId).pipe(
        switchMap((creditCard) => {
          return [
            new RemoveSelectedCreditCardSuccess(creditCard),
            new LoadCreditCardList(userId),
          ];
        }),
        catchError((error) => of(new RemoveSelectedCreditCardFail(tryNormalizeHttpError(error, this.logger)))),
      );
    }),
  ));

  constructor(
    private actions$: Actions,
    private paymentTypeConnector: CustomCheckoutPaymentTypeConnector,
    private logger: LoggerService,
    protected winRef?: WindowRef,
  ) {}
}

