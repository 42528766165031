import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthActions,
  AuthService,
  AuthToken,
  OAuthLibWrapperService,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { ASM_LAST_AGENT_USER_ID_LOCAL_STORAGE_KEY } from '../custom-asm-constants';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import {UserAccountFacade} from "@spartacus/user/account/root";

/**
 * Auth service for CS agent. Useful to login/logout agent, start emulation
 * or get information about the status of emulation.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomCsAgentAuthService extends CsAgentAuthService {
  constructor(
    protected authService: AuthService,
    protected authStorageService: AsmAuthStorageService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected store: Store,
    protected userService: UserProfileFacade,
    protected userAccountFacade: UserAccountFacade,
    protected winRef: WindowRef,
  ) {
    super(
      
      
      
      
      
       authService, authStorageService, userIdService, oAuthLibWrapperService, store, userAccountFacade,
    );
  }

  /**
   * Loads access token for a customer support agent.
   * @param userId
   * @param password
   */
  async authorizeCustomerSupportAgent(
    userId: string,
    password: string
  ): Promise<void> {
    let userToken: AuthToken | undefined;
    this.authStorageService
      .getToken()
      .subscribe((token) => (userToken = token))
      .unsubscribe();

    this.authStorageService.switchTokenTargetToCSAgent();
    try {
      await this.oAuthLibWrapperService.authorizeWithPasswordFlow(
        userId,
        password
      );
      // Start emulation for currently logged in user
      let customerId: string | undefined;
      this.userService
        .get()
        .subscribe((user) => (customerId = user?.uid))
        .unsubscribe();
      this.store.dispatch(new AuthActions.Logout());

      if (customerId !== undefined && userToken !== undefined) {
        // OCC specific user id handling. Customize when implementing different backend
        this.userIdService.setUserId(customerId);
        this.authStorageService.setEmulatedUserToken(userToken);
        this.store.dispatch(new AuthActions.Login());
      } else {
        // When we can't get the customerId just end all current sessions
        this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
        this.authStorageService.clearEmulatedUserToken();
      }
    } catch {
      this.authStorageService.switchTokenTargetToUser();
    }
  }

  /**
   * Starts an ASM customer emulation session.
   * A customer emulation session is stopped by calling logout().
   * @param uid
   */
  public startCustomerEmulationSession(uid: string): void {
    this.authStorageService.clearEmulatedUserToken();

    // OCC specific user id handling. Customize when implementing different backend
    this.store.dispatch(new AuthActions.Logout());
    this.userIdService.setUserId(uid);
    this.store.dispatch(new AuthActions.Login());
  }

  setAsmAgentUser(asmAgentId: string): void {
    if (this.winRef.localStorage) {
      this.winRef.localStorage.setItem(ASM_LAST_AGENT_USER_ID_LOCAL_STORAGE_KEY, asmAgentId);
    }
  }

   getAsmAgentUser(): string {
    return this.winRef.localStorage ? this.winRef.localStorage.getItem(ASM_LAST_AGENT_USER_ID_LOCAL_STORAGE_KEY) : '';
  }
}
