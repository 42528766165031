import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomVideoFullWidthComponent } from './custom-video-full-width.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomFullWidthVideoComponent: {
          component: CustomVideoFullWidthComponent,
        },
      },
    } as CmsConfig),
    YouTubePlayerModule,
  ],
  declarations: [CustomVideoFullWidthComponent],
  exports: [CustomVideoFullWidthComponent],
})
export class CustomVideoFullWidthModule {}
