import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CmsCustomImageInfoComponent } from './custom-image-info.model';
import { CmsLinkComponent, CmsService, OccEndpointsService } from '@spartacus/core';

@Component({
  selector: 'app-custom-image-info',
  templateUrl: './custom-image-info.component.html',
})
export class CustomImageInfoComponent implements OnInit, OnDestroy {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  @Input() item: CmsCustomImageInfoComponent;
  @ViewChild('youtubePlayer') youtubePlayer: any;
  buttons: any[] = [];
  subscription = new Subscription();

  data$: Observable<CmsCustomImageInfoComponent>;

  buttons$: Observable<Array<CmsLinkComponent>>

  constructor(
    protected component: CmsComponentData<CmsCustomImageInfoComponent>,
    private cmsService: CmsService,
    protected occEndpointsService: OccEndpointsService,
  ) {}

  ngOnInit(): void {
    this.data$ = (this.item ? of(this.item) : this.component.data$).pipe(
      tap((data) => {
        this.initComponent(data);
      }),
    );
    this.buttons$ = this.data$.pipe(
      switchMap((data: any) =>
        combineLatest(
          (data?.buttons ?? '').trim().split(' ')?.map((codes: any) =>
            this.cmsService.getComponentData(codes)
          ),
        ) as Observable<Array<CmsLinkComponent>>,
      ),
    );
  }

  initComponent(item: CmsCustomImageInfoComponent): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.showBackgroundColor === 'true' || item?.showBackgroundColor === true) {
      this.styleClasses += ' alternate';
      this.backgroundColor = item.showBackgroundColorCode ?? '';
    }
    if (item?.invertColumn === true || item?.invertColumn === 'true') {
      this.styleClasses += ' invert';
    }
    if (item?.fullImageWidth === true || item?.fullImageWidth === 'true') {
      this.styleClasses += ' full-page-width full-image-width';
    } else if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
  }

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  get btnClass(): string {
    return 'btn btn-send mb-3';
  }

  playVideo(): void {
    this.youtubePlayer.playVideo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }}
