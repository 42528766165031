import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LanguageCurrencyComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-language-currency',
  template: `
    <app-custom-site-context-selector [context]="siteContextType.LANGUAGE"></app-custom-site-context-selector>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLanguageCurrencyComponent extends LanguageCurrencyComponent {

  

}
