import { Component, HostBinding } from '@angular/core';
import { BaseSiteService, isNotNullable, WindowRef } from '@spartacus/core';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { CustomPopupShippingService } from 'src/app/custom/cms-components/user/popup-shipping/custom-popup-shipping.service';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';
import { CustomRoutingConfigService } from '../../../core/routing/custom-routing-config.service';
import { CsutomLoginFormComponentService } from '../../../core/user/facade/custom-login-form-component.service';
import { OsborneCaptchaService } from 'src/app/custom/shared/captcha/captcha.service';
import { take, filter, tap } from 'rxjs';
import { WebAuthnService } from '../../../core/user/facade/webauthn.service';
import { OsborneDeviceService } from '../../../shared/utils/device.service';

@Component({
  selector: 'app-custom-login-form',
  templateUrl: './custom-login-form.component.html',
  styleUrls: ['./custom-login-form.component.scss']
})
export class CustomLoginFormComponent extends LoginFormComponent {
  @HostBinding('class') site: string;

  showExtendedLoginInfo: boolean;
  widgetId: any;

  site$ = this.baseSiteService.getActive().pipe(
    tap(site => this.site = site)
  );

  constructor(
    protected winRef: WindowRef,
    protected loginFormComponentService: CsutomLoginFormComponentService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    private readonly captchaService: OsborneCaptchaService,
    protected webAuthnService: WebAuthnService,
    private deviceService: OsborneDeviceService,
    protected baseSiteService: BaseSiteService,
    protected customPopupShippingService?: CustomPopupShippingService,
    protected customRoutingConfigService?: CustomRoutingConfigService,
  ) {
    super(loginFormComponentService);
    this.showExtendedLoginInfo = this.customExtraAppConfigService.showExtendedLoginInfo();
    // Remove currentbasestore if is a private site.
    if (this.winRef?.localStorage?.getItem('currentbasestore') && this.customRoutingConfigService.isRoutingProtected()) {
      this.customPopupShippingService.purgeBaseStores();
    }
  }

  isMobile = this.deviceService.isMobile();

  getWidgetItemId(value): void {
    this.widgetId = value;
  }

  executeRecaptcha(): void {
    this.captchaService
      .getCaptchaConfig()
      .pipe(take(1))
      .subscribe((config) => {
        if (config.enabled && config.publicKey) {
          this.captchaService
            .renderCaptchaV3('login')
            .pipe(filter(isNotNullable), take(1))
            .subscribe((token) => token && this.onSubmit());
        } else {
          this.onSubmit();
        }
      });
  }

  loginWithFingerprint() {
    this.webAuthnService.login();
  }
}
