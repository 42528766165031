import { Component, HostBinding, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { CmsCustomImageInfoComponent } from '../custom-image-info/custom-image-info.model';
import { CmsCustomImageInfoContainerComponent } from './custom-image-info-container.model';

@Component({
  selector: 'app-custom-image-info-container',
  templateUrl: './custom-image-info-container.component.html'
})
export class CustomImageInfoContainerComponent {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  data$: Observable<CmsCustomImageInfoContainerComponent> = this.componentData.data$.pipe(
    tap((component: CmsCustomImageInfoContainerComponent) => {
        return this.initComponent(component);
      },
    ),
  );

  components$: Observable<any[]> = this.data$.pipe(
    distinctUntilChanged((x, y) => x?.components === y?.components),
    switchMap((data: any) =>
      combineLatest(
        (data?.components ?? '').split(' ').map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            distinctUntilChanged(),
          )
        )
      ) as Observable<Array<CmsCustomImageInfoComponent>>,
    )
  );

  constructor(
    protected componentData: CmsComponentData<CmsCustomImageInfoContainerComponent>,
    protected cmsService: CmsService,
  ) {
  }

  initComponent(item: CmsCustomImageInfoContainerComponent): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.fullPageWidth?.toString() === 'true') {
      this.styleClasses += ' full-page-width';
    }
    if (item?.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }
}
