import { Injectable } from '@angular/core';
import * as webauthnJson from '@github/webauthn-json';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthRedirectService,
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  OCC_USER_ID_CURRENT,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { catchError, of, take } from 'rxjs';
import { OsborneWebAuthnConnector } from '../connectors/profile/webauthn.connector';

@Injectable({
  providedIn: 'root',
})
export class WebAuthnService {
  private SAVE_WEBAUTH_USER_STORAGE_KEY = 'osborne-webauthn-publickeyuser';

  constructor(
    protected webAuthnConnector: OsborneWebAuthnConnector,
    protected globalMessageService: GlobalMessageService,
    protected authStorageService: AuthStorageService,
    protected userIdService: UserIdService,
    protected routingService: RoutingService,
    protected store: Store<StateWithClientAuth>,
    protected authRedirectService: AuthRedirectService,
    protected winRef: WindowRef,
    protected userService: UserAccountFacade,
  ) {}

  register(email: string) {
    if (email && webauthnJson.supported()) {
      // get PublicKeyCredentialCreationOptions from server
      this.webAuthnConnector
        .getCredentialCreationOptions()
        .pipe(take(1))
        .subscribe((options) => {
          // create PublicKeyCredential with navigator
          webauthnJson
            .create(options)
            .then((publicKeyCredential) => {
              // verify and save credentials in server
              this.verifyCredentialRegistration(publicKeyCredential, email);
            })
            .catch((err) => {
              console.error('Error creating public key credentials', err);
              this.globalMessageService.add({ key: 'webauthn.register.error' }, GlobalMessageType.MSG_TYPE_ERROR);
            });
        });
    } else {
      this.globalMessageService.add({ key: 'webauthn.noSupported' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  login() {
    const user = this.getSavedUser();
    // check if user exists
    if (!user) {
      this.globalMessageService.add({ key: 'webauthn.noRegistered' }, GlobalMessageType.MSG_TYPE_INFO);
      return;
    }
    if (webauthnJson.supported()) {
      // get PublicKeyCredentialCreationOptions from server
      this.webAuthnConnector
        .getCredentialAuthenticationOptions(user)
        .pipe(take(1))
        .subscribe((options) => {
          // Call WebAuthn ceremony using webauthn-json wrapper
          webauthnJson
            .get(options)
            .then((publicKeyCredential) => {
              this.verifyAuthentication(user, publicKeyCredential);
            })
            .catch((err) => {
              console.error('Error getting public key credentials', err);
              this.globalMessageService.add({ key: 'webauthn.login.error' }, GlobalMessageType.MSG_TYPE_ERROR);
            });
        });
    } else {
      this.globalMessageService.add({ key: 'webauthn.noSupported' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  private saveCurentUser(email: string) {
    this.winRef.localStorage?.setItem(this.SAVE_WEBAUTH_USER_STORAGE_KEY, email);
  }

  private getSavedUser(): string {
    return this.winRef.localStorage?.getItem(this.SAVE_WEBAUTH_USER_STORAGE_KEY) ?? '';
  }

  private verifyCredentialRegistration(publicKeyCredential: webauthnJson.PublicKeyCredentialWithAttestationJSON, email: string) {
    this.webAuthnConnector
      .verifyCredentialRegistration(JSON.stringify(publicKeyCredential))
      .pipe(
        catchError((err) => {
          this.globalMessageService.add({ key: 'webauthn.register.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          console.error('Error on verifyCredentialRegistration', err);
          return of(null);
        }),
      )
      .subscribe(() => {
        this.globalMessageService.add({ key: 'webauthn.register.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
        this.saveCurentUser(email);
      });
  }

  private verifyAuthentication(user: string, publicKeyCredential: webauthnJson.PublicKeyCredentialWithAssertionJSON): void {
    // Return encoded PublicKeyCredential to server
    this.webAuthnConnector
      .verifyAuthentication(user, JSON.stringify(publicKeyCredential))
      .pipe(
        catchError((err) => {
          this.globalMessageService.add({ key: 'webauthn.login.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          console.error('Error on verifyAuthentication', err);
          return of(null);
        }),
      )
      .subscribe((result) => {
        if (result) {
          this.authStorageService.setToken(result);
          // OCC specific user id handling. Customize when implementing different backend
          this.userIdService.setUserId(OCC_USER_ID_CURRENT);
          this.store.dispatch(new AuthActions.Login());
          this.authRedirectService.redirect();
          this.globalMessageService.add({ key: 'webauthn.login.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
        } else {
          this.globalMessageService.add({ key: 'webauthn.login.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          console.error('Error on verifyAuthentication, result empty');
        }
      });
  }
}
