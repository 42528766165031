import { ValidatorFn, Validator } from '@angular/forms';
import {
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { EMAIL_PATTERN } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { BusinessFriends } from 'src/app/custom/cms-components/myaccount/custom-friends/enum/business-friends.enum';
import { CUSTOM_PASSWORD_PATTERN } from 'src/app/custom/util/custom-regex-pattern';

export class CustomValidators extends CustomFormValidators {
  static isNifvalid: boolean = true;
  /**
   * Checks control's value with predefined email regexp
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'cxInvalidEmail' validator error
   * @memberof CustomFormValidators
   */
  static emailValidator(control: AbstractControl): ValidationErrors | null {
    const email = control.value as string;
    const EMAIL_PATTERN = /^^[a-zA-Z0-9\!\#\$\%\&\*\+\-\/\=\?\_\{\}\|\~\.]{1,}\@[a-zA-Z0-9\!\#\$\%\&\*\+\-\/\=\?\_\{\}\|\~\.]{1,}\.[a-zA-Z0-9\!\#\$\%\&\*\+\-\/\=\?\_\{\}\|\~\.]{1,}$/;

    return EMAIL_PATTERN.test(email) ? null :{ cxInvalidEmail: true };
  }

  /**
   * Checks if control's value is a valid IBAN
   *
   * NOTE: Use it as a control validator
   */
  static iban(control: AbstractControl): ValidationErrors | null {
    let ibanIn = control.value as string;
    ibanIn = ibanIn?.replace(/ /g, '');

    function hasValidFirst4Chars(prefIban: string): boolean {
      // Validación de los cuatro primeros caracteres del IBAN (el prefijo)
      // No permitimos Malta porque tiene 31 caracteres y en JDE solo nos caben hasta 29
      // Cód país y dos números
      const prefIbanPat = /^(AT|BE|BG|CH|CY|CZ|DE|DK|EE|ES|FI|FR|GB|GI|GR|HR|HU|IE|IS|IT|LI|LT|LU|LV|MC|NL|NO|PL|PT|RO|SE|SI|SK|SM)(\d{2})$/;
      return prefIban.match(prefIbanPat) === null ? false : true;
    }

    function calcIbanLetter(letter): number {
      const pos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.search(letter);
      return pos !== -1 ? pos + 10 : pos;
    }

    function reemplaza(cadena, busqueda, reemplazo): string {
      let p = 0;
      while (p < cadena.length && (p = cadena.indexOf(busqueda, p)) >= 0) {
          cadena = cadena.substring(0, p) + reemplazo + cadena.substring(p + busqueda.length, cadena.length);
          p += reemplazo.length;
      }
      return cadena;
    }

    if (ibanIn) {
      ibanIn = ibanIn.toUpperCase();

      if (ibanIn.length < 15 || ibanIn.length > 29 || !hasValidFirst4Chars(ibanIn.substring(0, 4))) {
        return { cxIban: true };
      }

      let iban = ibanIn.substring(4, ibanIn.length) + ibanIn.substring(0, 4);

      // Cambiamos las letras por números
      let hasta = iban.length;
      for (let i = 0; i < hasta; i++) {
        const letter = iban.substring(i, i + 1);
        const num = calcIbanLetter(letter);
        if (num !== -1) {
          iban = reemplaza(iban, letter, num);
          hasta ++;
          i++;
        }
      }

      // Dividimos el iban en cadenas más cortas para evitar los desbordamientos
      const sIban = iban.split('');
      const sCadenas = new Array();
      let pos = 0;
      let veces = 0;
      let cadena = '';

      for (let i = 0; i < 60 && i < iban.length; i++) {
        pos ++;
        cadena += sIban[i];
        if (pos === 12 || i === 59){
          sCadenas[veces] = cadena;
          veces ++;
          pos = 0;
          cadena = '';
        }
      }
      if (cadena !== '') {
        sCadenas[veces] = cadena;
      }

      // Cálculo del módulo 97 de cada cadena y se antepone a la siguiente cadena
      let iResto = 0;
      let sResto = '';
      for (let i = 0 ; i <= veces ; i++){
        let sDividendo: string;
        if (sCadenas[i] !== undefined) {
          sDividendo = sResto + sCadenas[i];
          iResto = Number(sDividendo) % 97;
          sResto = '' + iResto;
        }
      }

      if (Number(sResto) !== 1){
        return { cxIban: true };
      }

      // Podríamos validar el dígido de control de la cuenta (si la cuenta es española) pero con validar el IBAN creo que nos vale
    }
    return null;
  }

  /**
   * Checks control's value NIE/NIF/CIF having user role
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {roleControl} user role control or role
   * @returns {(ValidationErrors | null)} Uses 'cxNif' and 'cxCif' validator errors
   * @memberof CustomFormValidators
   */
  static documentIdentifier(roleControl: string, countryControl?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dni = (control.value as string)?.toUpperCase();
      const selectedCountry = control.parent?.get(countryControl)?.value;
      if (!CustomValidators.isNifvalid) {
        return null;
      }

      if (!dni || !selectedCountry) {
        return null;
      }
      function validateDNIPattern(pattern: RegExp): ValidationErrors | null {
        return pattern.test(dni) ? null : { cxNif: true };
      }
      if (/^ES/.test(selectedCountry)) {
        const role = (Object as any).values(BusinessFriends).includes(roleControl) ? roleControl : control.parent?.get(roleControl)?.value;
        const regular = /^(\d{8})([A-HJ-NP-TV-Z])$/;
        const regularNIE = /^[xyzXYZ]\d{7}[A-Za-z]$/;
        const regularCIF = /^[ABCDEFGHJNPRQSUVW]\d\d\d\d\d\d\d[0-9,A-J]$/;
        const VALIDATOR_CIF_START_BY_KPQS = /^[KPQS]\d{2}\d{5}[A-Z]$/;
        const VALIDATOR_CIF_START_BY_ABEH = /^[ABEH]\d{2}\d{5}[0-9]$/;
        const VALIDATOR_CIF_START_BY_CDFGLMN = /^[CDFGLMN]\d{2}\d{5}[A-Z0-9]$/;
        let tipo = '';

        function validarCIF(checkDni: string): boolean {
          return VALIDATOR_CIF_START_BY_KPQS.test(checkDni) ||
            VALIDATOR_CIF_START_BY_ABEH.test(checkDni) ||
            VALIDATOR_CIF_START_BY_CDFGLMN.test(checkDni);
        }

        if (![BusinessFriends.MARKETPLACE, BusinessFriends.COMPANY, BusinessFriends.PUBLIC].includes(role) && regularCIF.test(dni)){
          return { cxCif: true };
        }

        if (!regularNIE.test(dni)) {
          if (!regular.test(dni)){
            if (regularCIF.test(dni)){
                // es tipo CIF
                return validarCIF(dni) ? null : { cxNif: true };
            } else {
              return { cxNif: true };
            }
          } else {
            tipo = 'DNI';
          }
        } else {
          tipo = 'NIE';
        }

        // no es tipo CIF
        // se separa el numero de la letra
        let numero: string;
        if (tipo === 'NIE'){
          numero = dni.substr(1, 7);
          const letraI = dni.substr(0, 1);
          if (letraI === 'Y'){
            numero = '1' + numero;
          } else if (letraI === 'Z'){
            numero = '2' + numero;
          } else if (letraI === 'X'){
            numero = '0' + numero;
          }
        } else {
          numero = dni.substr(0, 8);
        }
        const letra = dni.substr(8, 9);
        // se pasa la cadena numero a entero
        let valor = parseInt(numero, 10);
        valor = valor / 23;
        valor = parseInt(valor.toString(), 10);
        valor = valor * 23;
        valor = Number(numero) - valor;
        const letras = 'TRWAGMYFPDXBNJZSQVHLCKE';
        const letraNif = letras.charAt(valor);
        return letraNif === letra ? null : { cxNif: true };
      } else if (/^AT/.test(selectedCountry)) {
        return validateDNIPattern(/^(AT)?U[0-9]{8}$/);
      } else if (/^BE/.test(selectedCountry)) {
        return validateDNIPattern(/^(BE)?(0|1)[0-9]{9}$/);
      } else if (/^BG/.test(selectedCountry)) {
        return validateDNIPattern(/^(BG)?[0-9]{9,10}$/);
      } else if (/^CY/.test(selectedCountry)) {
        return validateDNIPattern(/^(CY)?[0-9]{8}[A-Z]$/);
      } else if (/^CZ/.test(selectedCountry)) {
        return validateDNIPattern(/^(CZ)?[0-9]{8,10}$/);
      } else if (/^DE/.test(selectedCountry)) {
        return validateDNIPattern(/^(DE)?[0-9]{9}$/);
      } else if (/^DK/.test(selectedCountry)) {
        return validateDNIPattern(/^(DK)?[0-9]{8}$/);
      } else if (/^EE/.test(selectedCountry)) {
        return validateDNIPattern(/^(EE)?[0-9]{9}$/);
      } else if (/^(EL|GR)/.test(selectedCountry)) {
        return validateDNIPattern(/^(EL|GR)?[0-9]{9}$/);
      } else if (/^FI/.test(selectedCountry)) {
        return validateDNIPattern(/^(FI)?[0-9]{8}$/);
      } else if (/^FR/.test(selectedCountry)) {
        return validateDNIPattern(/^(FR)?[0-9A-Z]{2}[0-9]{9}$/);
      } else if (/^(GB|XI)/.test(selectedCountry)) {
        return validateDNIPattern(/^(GB|XI)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/);
      } else if (/^HU/.test(selectedCountry)) {
        return validateDNIPattern(/^(HU)?[0-9]{8}$/);
      } else if (/^IE/.test(selectedCountry)) {
        return validateDNIPattern(/^(IE)?[0-9A-Z]{8,9}$/);
      } else if (/^IT/.test(selectedCountry)) {
        return validateDNIPattern(/^(IT)?[0-9]{11}$/);
      } else if (/^LT/.test(selectedCountry)) {
        return validateDNIPattern(/^(LT)?([0-9]{9}|[0-9]{12})$/);
      } else if (/^LU/.test(selectedCountry)) {
        return validateDNIPattern(/^(LU)?[0-9]{8}$/);
      } else if (/^LV/.test(selectedCountry)) {
        return validateDNIPattern(/^(LV)?[0-9]{11}$/);
      } else if (/^MT/.test(selectedCountry)) {
        return validateDNIPattern(/^(MT)?[0-9]{8}$/);
      } else if (/^NL/.test(selectedCountry)) {
        return validateDNIPattern(/^(NL)?[0-9]{9}B[0-9]{2}$/);
      } else if (/^PL/.test(selectedCountry)) {
        return validateDNIPattern(/^(PL)?[0-9]{10}$/);
      } else if (/^PT/.test(selectedCountry)) {
        return validateDNIPattern(/^(PT)?[0-9]{9}$/);
      } else if (/^RO/.test(selectedCountry)) {
        return validateDNIPattern(/^(RO)?[0-9]{2,10}$/);
      } else if (/^SE/.test(selectedCountry)) {
        return validateDNIPattern(/^(SE)?[0-9]{12}$/);
      } else if (/^SI/.test(selectedCountry)) {
        return validateDNIPattern(/^(SI)?[0-9]{8}$/);
      } else if (/^SK/.test(selectedCountry)) {
        return validateDNIPattern(/^(SK)?[0-9]{10}$/);
      }
      return null;
    };
  }

  /**
   * Checks control's value is a valid postal code
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {countryControl} country control or countryCode
   * @returns {(ValidationErrors | null)} Uses 'cxPostalCode' validator errors
   * @memberof CustomFormValidators
   */
  static postalCode(countryControl?: string): ValidatorFn {
    const postalCodeRegexes = {
      'ES-AF': /^(51|52)\d{3}$/,
      'ES-IB': /^07\d{3}$/,
      'ES-CN': /^(35|38)\d{3}$/,
      'ES': /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/,
      'AT': /^[1-9]{1}[0-9]{3}$/,
      'BE':  /^\d{4}$/,
      'BG': /^\d{4}$/,
      'CH': /^[1-9]\d{3}$/,
      'CZ': /^[1-7][0-9]{2} [0-9]{2}$/,
      'DE': /^\d{5}$/,
      'DK': /^\d{4}$/,
      'EE': /^\d{5}$/,
      'FI': /^\d{5}$/,
      'FR': /^\d{5}$/,
      'GR': /^(\d{3}) \d{2}$/,
      'HR': /^[1-5]\d{4}$/,
      'HU': /^[1-9]\d{3}$/,
      'IE': /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/,
      'IT': /^\d{5}$/,
      'LU': /^\d{4}$/,
      'LV': /^LV-\d{4}$/,
      'NL': /^[1-9]\d{3} [A-Z]{2}$/,
      'PL': /^[0-9]{2}[-]([0-9]){3}$/,
      'PT': /^[1-9]\d{3}((-)\d{3})$/,
      'RO': /^\d{6}$/,
      'SE': /^[1-9]\d{2} \d{2}$/,
      'SI': /^\d{4}$/,
      'SK': /^\d{3} \d{2}$/
    };

    return (control: AbstractControl): ValidationErrors | null => {
      const postalCode = (control.value as string)?.toUpperCase();
      if (!postalCode) {
        return null;
      }
      const countrySelectorValue = control.parent?.get(countryControl)?.value;
      const regex = postalCodeRegexes[countrySelectorValue];
      return regex ? regex.test(postalCode) ? null : { cxPostalCode: true } : null;
    };
  }

  /**
   * Sums control with a second control length values to check combined max chars
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {secondControl} second control
   * @param {maxLength} max length for combined chars
   * @returns {(ValidationErrors | null)} Uses 'combinedMaxlength' validator errors
   * @memberof CustomFormValidators
   */
  static combinedMaxLength(secondControl: string, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControlValue = control.value as string ?? '';
      const secondControlValue = control.parent?.get(secondControl)?.value as string ?? '';
      const actualLength = firstControlValue?.length + secondControlValue?.length;
      return actualLength <= maxLength
        ? null
        : { combinedMaxlength: { maxLength, actualLength } };
    };
  }

  /**
   * Checks control's value with predefined password regexp
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'cxInvalidPassword' validator error
   * @memberof CustomFormValidators
   */
  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;

    return password && (!password.length || password.match(CUSTOM_PASSWORD_PATTERN))
      ? null
      : { cxInvalidPassword: true };
  }

  /**
   * Checks control's value valid phone. Is valid if it has 9 - 20 characters
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'minlength' or 'maxlengthWithNumber' validator error
   * @memberof CustomFormValidators
   */
  static phoneValidator(control: AbstractControl): ValidationErrors | null {
    const phone = control.value as string;
    return matchLengthRange(9, 20, phone);
  }

  /**
   * Checks control's value valid. Is valid if it has number value
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'numberValidator' validator error
   * @memberof CustomFormValidators
   */
  static numberValidator(control: AbstractControl): ValidationErrors | null {
    return /^[0-9]*$/.test(control.value) ? null : { numberValidator : true };
  }

  /**
   * Checks control's value of fields is between minLength and maxLength
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'minlength' or 'maxlengthWithNumber' validator error
   * @memberof CustomFormValidators
   */
  static lengthRangeValidator(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => matchLengthRange(minLength, maxLength, control.value as string);
  }

  static dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control?.value) {
        const today = new Date();
        const dateToCheck = new Date(control.value);
        if (dateToCheck > today) {
            return {'date.invalid': true}
        }
    }
    return null;
}
}



function matchLengthRange(minLength: number, maxLength: number, text: string): ValidationErrors | null {
  if (text) {
    if (text.length < minLength ) {
      return { minlength: { requiredLength: minLength } };
    }
    if (text.length > maxLength ) {
      return { maxlengthWithNumber: { requiredLength: maxLength } };
    }
  }
  return null;
}
