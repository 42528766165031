import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomUnitDisplayComponent } from './custom-unit-display.component';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [CustomUnitDisplayComponent],
  imports: [
    CommonModule,
    I18nModule,
  ],
  exports: [CustomUnitDisplayComponent],
})
export class CustomUnitDisplayModule { }
