export const commonEnglish = {
  en: { // lang
    common: {
      carousel: {
        product: {
          btn: 'See more',
        },
        previousSlide: 'Previous slide',
        nextSlide: 'Next slide',
      },
      common: {
        invalidEmailErrorMessage: 'Email is not a valid e-mail address!',
        validateDocIdentifierAndTotalAmount: 'For orders of more than €3,000 it is mandatory to add a NIF.',
        cancel: 'Cancel',
        delete: 'Delete',
        remove: 'Remove',
        edit: 'Edit',
        back: 'Back',
        submit: 'Submit',
        continue: 'Continue',
        save: 'Save',
        done: 'Done',
        home: 'Home',
        noData: 'No data',
        confirm: 'Confirm',
        more: 'More',
        close: 'Close',
        accept: 'Ok',
        readMore: 'Read more',
        tiendaOnline: 'shop-online',
        menu: 'Menu',
        reset: 'Reset',
        search: 'Search',
        loaded: 'Loaded',
      },
      pageMetaResolver: {
        category: {
          title: '{{count}} result for {{query}}',
          title_plural: '{{count}} results for {{query}}',
        },
        checkout: {
          title: 'Checkout {{count}} item',
          title_plural: 'Checkout {{count}} items',
        },
        search: {
          title: '{{count}} items',
          title_plural: '{{count}} results for "{{query}}"',
          findProductTitle: '{{count}} result for coupon "{{coupon}}"',
          findProductTitle_plural: '{{count}} results for coupon "{{coupon}}"',
          empty: 'No results found for {{ freeTextSearch }}.',
        },
        product: {
          description: '{{description}}',
          heading: '{{heading}}',
          title: '{{title}}',
        },
      },
      spinner: {
        loading: 'Loading...',
      },
      navigation: {
        shopAll: 'Shop all {{ navNode}} >',
      },
      searchBox: {
        placeholder: 'Search',
        help: {
          insufficientChars: 'Please type more characters',
          noMatch: 'We could not find any results',
          exactMatch: '{{ term }}',
          empty: 'Ask us anything',
        },
        ariaLabelSuggestions: 'typing suggestions',
        productSearch: 'Find a product',
        initialDescription:
          'When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.',
      },
      sorting: {
        date: 'Date',
        orderNumber: 'Order Number',
        rma: 'Return Number',
        replenishmentNumber: 'Replenishment Number',
        nextOrderDate: 'Next Order Date',
        billDate: 'Invoice date',
        billDueDate: 'Due date',
        byCodeAsc: 'Invoice numbers ascending',
        byCodeDesc: 'Invoice numbers descending',
        byDateAsc: 'Invoice dates ascending',
        byDateDesc: 'Invoice dates descending',
        byDueDateAsc: 'Due dates ascending',
        byDueDateDesc: 'Due dates descending',
        newsDate: 'News Date',
        newsTitle: 'News Title',
      },
      httpHandlers: {
        badRequest: {
          bad_credentials: 'Incorrect credentials. Please login again.',
          el_usuario_está_deshabilitado: 'Blocked user, to unblock your account, recover your password and once changed, log in again. We can also help you at sac_web@osborne.es',
          user_is_disabled: 'Blocked user, to unblock your account, recover your password and once changed, log in again. We can also help you at sac_web@osborne.es',
          le_compte_utilisateur_est_désactivé: 'Blocked user, to unblock your account, recover your password and once changed, log in again. We can also help you at sac_web@osborne.es',
          usuário_desabilitado: 'Blocked user, to unblock your account, recover your password and once changed, log in again. We can also help you at sac_web@osborne.es'
        },
        badGateway: 'A server error occurred. Please try again later.',
        badRequestPleaseLoginAgain: 'Incorrect credentials. Please login again.',
        badRequestOldPasswordIncorrect: 'Old password incorrect.',
        badRequestGuestDuplicateEmail:
          'If you already have an account with us, please log in or reset your password to log in. We can also help you at sac_web@osborne.es',
        conflict: 'Email already exists.',
        forbidden:
          'You are not authorized to perform this action. Please contact your administrator if you think this is a mistake.',
        gatewayTimeout: 'The server did not respond, please try again later.',
        internalServerError:
          'An Internal Server Error occurred. Please try again later.',
        sessionExpired: 'Your session has expired. Please login again.',
        unknownError: 'An unknown error occurred',
        unauthorized: {
          common: 'An unknown authorization error occured',
          invalid_client: 'Incorrect client credentials',
        },
        forbiddenConsentTemplates: 'Please log out to register successfully.',
        validationErrors: {
          missing: {
            bankAccountNumber: 'IBAN field is required',
            card_cardType:
              'The selected credit card is not supported. Please select another.',
            card_accountNumber: 'The credit card number entered is not valid.',
            card_cvNumber: 'The security code entered is not valid.',
            card_expirationMonth:
              'The credit card expiration date entered is not valid.',
            card_expirationYear:
              'The credit card expiration date entered is not valid.',
            billTo_firstName: 'The name entered is not valid.',
            billTo_lastName: 'The last name entered is not valid.',
            billTo_street1: 'The address entered is not valid.',
            billTo_street2: 'The address entered is not valid.',
            billTo_city: 'The city entered is not valid for this credit card.',
            billTo_state:
              'The state/province entered is not valid for this credit card.',
            billTo_country:
              'The country entered is not valid for this credit card.',
            billTo_postalCode:
              'The zip/postal code is not valid for this credit card.',
            country: {
              isocode: 'Missing country',
            },
            firstName: 'Name is required',
            lastName: 'Last name is required',
            password: 'Password is required',
            firstNameLastName: 'Name and last name is required',
            uid: 'Email is required',
            code: 'Code is required',
            email: 'Email is required',
            iban: 'IBAN is required',
            documentIdentifier: 'Tax reg nº is required',
            postalCode: 'Postal code is required',
            defaultPaymentAddress: {
              phone: 'Phone field is required',
              firstName: 'Name field is required',
              lastName: 'Last name field is required',
              password: 'Password field is required',
              line1: 'Address field is required',
              town: 'Town field is required',
              postalCode: 'Postal code field is required',
              country: {
                isocode: 'Country field is required',
              },
              toCustomersUid: 'Customers email adddress not found',
            },
            newUid: 'Verify user information',
            message: 'Message is required',
            subject: 'Subject is required',
            orderCode: 'Order code is required',
            productsAffected: 'Products and quantities affected field is required',
            qualityProblem: 'Quality problem field is required',
            requiredAction: 'Required action field is required',
          },
          invalid: {
            subject: 'Subject is not valid.',
            message: 'Message is not valid.',
            bankAccountNumber: 'IBAN field is not valid',
            card_expirationMonth:
              'The credit card expiration date entered is not valid.',
            firstName: 'Name entered is not valid.',
            lastName: 'Last Name entered is not valid.',
            firstNameLastName: 'Name and last name is not valid',
            password: 'Password entered is not valid.',
            uid: 'UID is not valid.',
            code: 'Code is not valid.',
            email: 'Email is not valid.',
            sessionCart: 'Payment info is not set.',
            documentIdentifier: 'ID is not valid.',
            iban: 'IBAN field is not valid',
            postalCode: 'Postal code field is not valid',
            defaultPaymentAddress: {
              phone: 'Phone field is not valid',
              firstName: 'Name field is not valid',
              lastName: 'Last name field is not valid',
              password: 'Password field is not valid',
              line1: 'Address field is not valid',
              town: 'Town field is not valid',
              postalCode: 'Postal code field is not valid',
              country: {
                isocode: 'Iso code field is not valid',
              },
            },
            newUid: 'Check user information',
          },
        },
        CartError: {
          CartError: 'Error simulating order',
        },
        cartModification: {
          giftBoxError: {
            label: 'The minimum number of products to complete the gift box has not been reached.',
            entries: '',
          },
          lowStock: {
            label: '',
            entries: 'The following product has been adjusted to {{quantity}} units due to lack of available stock: {{product}}.',
          },
          noStock: {
            label: 'The following product has been removed from the cart due to lack of available stock: ',
            entries: '{{product}}.',
          },
          unavailable: {
            label: 'Items that are not available for this destination have been removed: ',
            entries: '{{product}}.',
          },
        },
        cartNotFound: 'Cart not found.',
        invalidCodeProvided: 'Invalid code provided.',
        notActiveCodeProvided: 'Code provided is currently invalid.',
        voucherExceeded: '{{voucherCode}}'
      },
      miniCart: {
        item: '{{count}} item currently in your cart',
        item_plural: '{{count}} items currently in your cart',
        total: '{{total}}',
        count: '{{count}}',
        preview: {
          empty: 'YOU HAVE NO ITEMS IN YOUR SHOPPING CART.',
          proceedToCheckout: 'Proceed to checkout',
          viewCart: 'View and edit cart',
        },
      },
      // deprecated since 3.2, moved to @spartacus/user/account/assets
      miniLogin: {
        userGreeting: 'Hi, {{name}}',
        signInRegister: 'Sign In / Register',
      },
      skipLink: {
        skipTo: 'Skip to',
        labels: {
          header: 'Header',
          main: 'Main Content',
          footer: 'Footer',
          productFacets: 'Product Attributes',
          productList: 'Product List',
        },
      },
      formErrors: {
        nifError: 'Name and Surname do not match your NIF. Modify them in the “My information” section and click continue again',
        globalMessage: 'The form you are trying to submit contains errors.',
        required: 'This field is required',
        cxInvalidEmail: 'This is not a valid email format',
        cxInvalidPassword: 'Password must be six characters minimum, with one uppercase letter, one lowercase letter, one number and one symbol',
        cxPasswordsMustMatch: 'Password fields must match',
        cxEmailsMustMatch: 'Email fields must match',
        cxStarRatingEmpty: 'Rating field is required',
        cxNoSelectedItemToCancel: 'Select at least one item',
        cxNegativeAmount: 'Amount must be equal or greater than zero',
        cxContainsSpecialCharacters: 'Field cannot contain special characters',
        cxIban: 'This is not a valid IBAN',
        cxNif: 'This is not a valid tax reg nº format for selected country',
        cxCif: 'This is not a valid tax reg nº for selected country. Personal tax reg nºs only.',
        cxPostalCode: 'Postal code is invalid for selected zone - country',
        email: 'This is not a valid email format',
        maxlength: 'You have entered more characters than allowed',
        maxlengthWithNumber: 'The maximum length for this field is {{maxLength}} characters',
        combinedMaxlength: 'Maximum length for {{ firstControl }} and {{ secondControl }} combined is {{maxLength}} characters (now {{actualLength}})',
        date: {
          required: 'This field is required',
          min: 'Date cannot be before {{min}}',
          max: 'Date cannot be after {{max}}',
          pattern: 'Use dateformat yyyy-mm-dd',
          invalid: 'Use a valid date',
        },
        minlength: 'The minimum length for this field is {{minLength}} characters',
        min: 'Below minimum required',
      },
      errorHandlers: {
        scriptFailedToLoad: 'Failed to load the script.',
        refreshThePage: 'Please refresh the page.',
      },
    },
    shipping: {
      shipping: {
        headerText: {
          destiny: 'destination',
        },
      },
    },
    user: {
      anonymousConsents: {
        preferences: 'Consent Preferences',
        dialog: {
          title: 'Consent Management',
          legalDescription:
            'We use cookies/browser\'s storage to personalize the content and improve user experience. We also might share the data about your site usage with our social media. For more, please review our privacy policy.',
          selectAll: 'Select all',
          clearAll: 'Clear all',
        },
        banner: {
          title: '',
          description:
            'We use cookies/browser\'s storage to personalize the content and improve user experience. We also might share the data about your site usage with our social media. For more, please review our privacy policy.',
          allowAll: 'Accept',
          viewDetails: 'View Details',
        },
      },
      forgottenPassword: {
        resetPassword: 'Reset password',
        resetButton: 'Reset',
        enterEmailAddressAssociatedWithYourAccount:
          'Enter the email address associated with your account',
        emailAddress: {
          label: 'Email address',
          placeholder: 'Enter email',
        },
        enterValidEmail: 'Please enter a valid email.',
        passwordResetEmailSent:
          'If there is an account with the email provided, you will receive an email to reset your password.',
        passwordResetSuccess: 'Success! You can now login using your new password.',
      },
      // deprecated
      loginForm: {
        forgotPassword: 'Forgot password?',
        forgotPasswordLong: 'Forgot your password?',
        signIn: 'Sign In',
        signInLong: 'Sign In',
        register: 'Register',
        registerLong: 'Create an account',
        registerTitle: 'New customers',
        registerInfo: 'Creating an account has many benefits: check out faster, keep more than one address, track orders and more.',
        dontHaveAccount: 'Don’t have an account?',
        guestCheckout: 'Guest Checkout',
        access: 'Access',
        accessLong: 'Registered Customers',
        accessInfo: 'If you have an account, sign in with your email address.',
        accept: 'Accept',
        chooseShipping: 'Select shipping',
        formShipping: {
          homeDelivery: {
            label: 'Home delivery',
            placeholder: '',
          },
          pickUpWarehouse: {
            label: 'Pickup at warehouse',
            placeholder: 'Select warehouse',
          },
        },
        cartModifications: 'When changing destination, some products may not be available and disappear from the cart',
        emailAddress: {
          label: 'User',
          labelLong: 'Email',
          placeholder: 'Enter email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        wrongEmailFormat: 'This is not a valid email format.',
      },
      // deprecated
      register: {
        confirmPassword: {
          action: 'Confirm password',
          label: 'Confirm password',
          placeholder: 'Confirm Password',
        },
        managementInMyAccount: 'Management in My Account.',
        termsAndConditions: 'Terms & Conditions',
        signIn: 'I already have an account. Sign In',
        register: 'Register',
        confirmNewPassword: 'Confirm New Password',
        resetPassword: 'Reset Password',
        createAccount: 'Create an account',
        title: 'Title',
        firstName: {
          label: 'Name',
          placeholder: 'Name',
        },
        lastName: {
          label: 'Last name',
          placeholder: 'Last name',
        },
        emailAddress: {
          label: 'Email address',
          placeholder: 'Email address',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        newPassword: 'New Password',
        emailMarketing:
          'Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.',
        confirmThatRead: 'I am confirming that I have read and agreed with the',
        selectTitle: 'Select Title',
        passwordMinRequirements:
          'Password must be six characters minimum, with one uppercase letter, one lowercase letter, one number and one symbol',
        bothPasswordMustMatch: 'Both password must match',
        titleRequired: 'Title is required.',
        postRegisterMessage: 'Please log in with provided credentials.',
      },
      checkoutLogin: {
        title: 'Guest checkout',
        info: 'If you wish, you can create an account later.',
        emailAddress: {
          label: 'Email address',
          placeholder: 'Enter a valid email',
        },
        confirmEmail: {
          label: 'Confirm email',
          placeholder: 'Confirm email',
        },
        continue: 'Continue',
        emailIsRequired: 'Invalid email format',
        emailsMustMatch: 'Email does not match',
      },
    },
    userAccount: {
      anonymousConsents: {
        preferences: 'Consent Preferences',
        dialog: {
          title: 'Consent Management',
          legalDescription:
            'We use cookies/browser\'s storage to personalize the content and improve user experience. We also might share the data about your site usage with our social media. For more, please review our privacy policy.',
          selectAll: 'Select all',
          clearAll: 'Clear all',
        },
        banner: {
          title: '',
          description:
            'We use cookies/browser\'s storage to personalize the content and improve user experience. We also might share the data about your site usage with our social media. For more, please review our privacy policy.',
          allowAll: 'Accept',
          viewDetails: 'View Details',
        },
      },
      forgottenPassword: {
        resetPassword: 'Reset password',
        resetButton: 'Reset',
        enterEmailAddressAssociatedWithYourAccount:
          'Enter the email address associated with your account',
        emailAddress: {
          label: 'Email address',
          placeholder: 'Enter email',
        },
        enterValidEmail: 'Please enter a valid email.',
        passwordResetEmailSent:
          'If there is an account with the email provided, you will receive an email to reset your password.',
        passwordResetSuccess: 'Success! You can now login using your new password.',
      },
      // deprecated
      loginForm: {
        forgotPassword: 'Forgot password?',
        forgotPasswordLong: 'Forgot your password?',
        signIn: 'Sign In',
        signInLong: 'Sign In',
        register: 'Register',
        registerLong: 'Create an account',
        registerTitle: 'New customers',
        registerInfo: 'Creating an account has many benefits: check out faster, keep more than one address, track orders and more.',
        dontHaveAccount: 'Don’t have an account?',
        guestCheckout: 'Guest Checkout',
        access: 'Log in',
        accessLong: 'Registered Customers',
        accessInfo: 'If you have an account, sign in with your email address.',
        accept: 'Accept',
        chooseShipping: 'Select shipping',
        formShipping: {
          homeDelivery: {
            label: 'Home delivery',
            placeholder: '',
          },
          pickUpWarehouse: {
            label: 'Pickup and Collect',
            placeholder: 'Select warehouse',
          },
        },
        cartModifications: 'Your cart could be modified. Proceed?',
        emailAddress: {
          label: 'User',
          labelLong: 'Email',
          placeholder: 'Enter email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        wrongEmailFormat: 'This is not a valid email format.',
        ageStatus: {
          text: 'WE WANT TO ENSURE THAT OUR BRANDS ARE CONSUMED RESPONSIBLY. PLEASE CONFIRM YOU ARE OF LEGAL DRINKING AGE BEFORE ENTERING OUR WEBSITE.',
          invalidAge: 'WE ARE SORRY, BUT...<br/> YOU MUST BE OF LEGAL DRINKING AGE TO CONTINUE.',
          overEighteen: 'Over 18',
          underEighteen: 'Under 18'
        }
      },
      // deprecated
      register: {
        confirmPassword: {
          action: 'Confirm password',
          label: 'Confirm password',
          placeholder: 'Confirm Password',
        },
        managementInMyAccount: 'Management in My Account.',
        termsAndConditions: 'Terms & Conditions',
        signIn: 'I already have an account. Sign In',
        register: 'Register',
        confirmNewPassword: 'Confirm New Password',
        resetPassword: 'Reset Password',
        createAccount: 'Create an account',
        title: 'Title',
        firstName: {
          label: 'Name',
          placeholder: 'Name',
        },
        lastName: {
          label: 'Last name',
          placeholder: 'Last name',
        },
        emailAddress: {
          label: 'Email address',
          placeholder: 'Email address',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        newPassword: 'New Password',
        emailMarketing:
          'Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.',
        confirmThatRead: 'I am confirming that I have read and agreed with the',
        selectTitle: 'Select Title',
        passwordMinRequirements:
          'Password must be six characters minimum, with one uppercase letter, one lowercase letter, one number and one symbol',
        bothPasswordMustMatch: 'Both passwords must match',
        titleRequired: 'Title is required.',
        postRegisterMessage: 'Please log in with provided credentials.',
      },
      checkoutLogin: {
        title: 'Guest checkout',
        info: 'If you wish, you can create an account later.',
        emailAddress: {
          label: 'Email address',
          placeholder: 'Enter a valid email',
        },
        confirmEmail: {
          label: 'Confirm email',
          placeholder: 'Confirm email',
        },
        continue: 'Continue',
        emailIsRequired: 'Invalid email format',
        emailsMustMatch: 'Email does not match',
      },
      miniLogin: {
        userGreeting: 'Hi, {{name}}',
        signInRegister: 'Sign In',
      },
      authMessages: {
        signedOutSuccessfully: 'You have successfully signed out.',
      }
    },
    pwa: {
      pwa: {
        addToHomeScreenDescription:
          'Add Osborne to your device homescreen for a faster return visit',
        noInstallationNeeded: 'No installation needed',
        fastAccessToApplication: 'Fast access to application',
        addToHomeScreen: 'Add to home screen',
        addedToHomeScreen: 'Osborne was added to your home screen',
      },
    },
    address: {
      addressForm: {
        title: 'Title',
        firstName: {
          label: 'Name',
          placeholder: 'Name',
        },
        observations: 'Shipping observations',
        alias: 'Alias',
        lastName: {
          label: 'Last name',
          placeholder: 'Last Name',
        },
        address1: 'Address',
        address2: 'Address 2 (optional)',
        country: 'Zone - Country',
        province: 'Province',
        city: {
          label: 'City',
          placeholder: 'Select an option',
        },
        state: 'State',
        zipCode: {
          label: 'Zip code',
          placeholder: 'Postal Code/Zip',
        },
        phoneNumber: {
          label: 'Phone number (optional)',
          placeholder: '(555) 555 - 0123',
          note: '()',
        },
        email: {
          label: 'Email',
          note: '(shipment notification)',
          placeholder: 'user@email.com',
        },
        saveAsDefault: 'Save as default',
        chooseAddress: 'Choose address',
        streetAddress: 'Street Address',
        aptSuite: 'Apt, Suite',
        selectOne: 'Select One...',
        pleaseSelectOne: 'Please select one...',
        setAsDefault: 'Set as default',
        titleRequired: 'Title is required.',
        userAddressAddSuccess: 'New address was added successfully!',
        userAddressUpdateSuccess: 'Address updated successfully!',
        userAddressDeleteSuccess: 'Address deleted successfully!',
        invalidAddress: 'Invalid Address. {{message}}',
        back: 'Back',
        deliveryNoteObservations: 'Shipping observations',
      },
      addressBook: {
        titleAddressBook: 'My addresses',
        defaultAddressBook: 'Default shipping address',
        addressBooklList: 'Address book',
        addressBookAlias: 'Alias',
        addressBookName: 'Name and last name',
        addressBookDirection: 'Address',
        addressBookPhone: 'Telephone',
        addressBookObservations: 'Delivery observations',
        addNewShippingAddress: 'Add a new shipping address',
        editShippingAddress: 'Modify shipping address',
        areYouSureToDeleteAddress: 'Are you sure that you want to delete this address?',
        addNewAddress: 'Add address',
        addAddress: 'Add address',
        updateAddress: 'Update address',
        backToAddressList: 'Back to my addresses',
        filter: 'Filter',
        filterRemove: 'Remove filter',
        filterNoAddresses: 'No addresses found',
      },
      addressCard: {
        default: 'DEFAULT',
        selected: 'Selected',
        setAsDefault: 'Set as default',
        shipTo: 'Shipping address',
        billTo: 'Billing address',
      },
    },
    asm: {
      asm: {
        mainLogoLabel: 'SAP',
        mainTitle: 'Assisted Service Mode',
        logout: 'Sign Out',
        hideUi: 'Close ASM',
        toggleUi: {
          collapse: 'Hide ASM',
          expand: 'Show ASM',
        },
        loginForm: {
          submit: 'Sign In',
          userId: {
            label: 'Agent ID',
            required: 'Agent ID is required',
          },
          password: {
            label: 'Password',
            required: 'Password is required',
          },
        },
        customerSearch: {
          searchTerm: {
            label: 'Customer Name/Email Address',
          },
          submit: 'Start Session',
          noMatch: 'No customer found.',
        },
        csagentTokenExpired: 'Your customer support agent session is expired.',
        endSession: 'End Session',
        agentSessionTimer: {
          label: 'Session Timeout',
          minutes: 'min',
          reset: 'Reset',
        },
        standardSessionInProgress: 'Standard customer session in progress.',
        auth: {
          agentLoggedInError:
            'Cannot login as user when there is an active CS agent session. Please either emulate user or logout CS agent.',
        },
        error: {
          noCustomerId:
            'No customerId found for selected user. Session cannot be started.',
        },
      },
    },
    giftbox: {
      giftbox: {
        itemSelect: 'SELECT',
        itemSelectNotAvailable: 'NOT AVAILABLE',
        itemConfigure: 'Configure your',
        popupAddGiftboxToCart: {
          titleCartNotEmpty: 'You have products in the cart.',
          titleOtherGiftbox: 'You have another gift box in the cart.',
          messageCartNotEmpty: 'If you continue all products will be removed from the cart.',
          messageOtherGiftbox: 'If you continue the gift box from cart will be removed from the cart.',
          confirmation: 'Do you want to continue?',
          cancel: 'Cancel',
          continue: 'Continue',
        },
        popupAddProductToCartWithGiftbox: {
          title: 'You have a gift box in the cart.',
          message: 'What do you want to do?',
          cancel: 'Go to gift box',
          continue: 'Remove gift box',
        },
        header: {
          btnDeleteCart: 'Cancel gift box',
        },
        cartTooltip: {
          price: 'Price',
        },
      },
    },
    cart: {
      cartDetails: {
        id: 'ID',
        proceedToCheckout: 'Proceed to Checkout',
        cartName: 'Your Shopping Cart',
        continueShopping: 'Continue Shopping',
        emptyCart: 'Empty Cart',
        missingInformation: 'An error has occurred while processing your order. Please try again in a few minutes.',
        checkoutShippingAddress: 'A valid shipping address has not been selected.',
        checkoutDeliveryAddress: 'A valid shipping address has not been selected.',
        checkoutPaymentType: 'A valid payment method has not been selected.',
        checkoutDeliveryMode: 'A valid shipping method has not been selected.',
        remainingSlots: '{{count}} slots left to complete your gift box.',
        remainingSlots_plural: '{{count}} slot left to complete your gift box.',
        remainingSlotsToOrder: '{{count}} slots left to be able to order your gift box.',
        remainingSlotsToOrder_plural: '{{count}} slot left to be able to order your gift box.',
        checkout: 'Payment cancelled',
        deliveryMessage: 'This order contains products from different warehouses and the delivery will be made in different shipments.',
      },
      cartItems: {
        id: 'ID',
        description: 'Description',
        item: 'Item',
        itemPrice: 'Item price',
        quantity: 'Qty',
        quantityTitle:
          'The quantity represents the total number of this item in your cart.',
        total: 'Total',
        cartTotal: 'Cart total ({{count}} item)',
        cartTotal_plural: 'Cart total ({{count}} items)',
        deliveryNumber: 'Delivery {{count}}',
      },
      orderCost: {
        orderSummary: 'Order Summary',
        subtotal: 'Subtotal after discounts:',
        shipping: 'Shipping:',
        giftCard: 'Custom gift card:',
        estimatedShipping: 'Estimated shipping:',
        discount: 'You saved:',
        salesTax: 'Sales Tax:',
        grossTax: 'The order total does not include tax of',
        grossIncludeTax: 'The order total includes tax of',
        total: 'Total:',
        toBeDetermined: 'TBD',
      },
      voucher: {
        coupon: 'Have a coupon?',
        coupon_plural: 'Coupon codes',
        apply: 'Apply',
        placeholder: 'Promo code',
        applyVoucherSuccess: '{{voucherCode}} has been applied.',
        removeVoucherSuccess: '{{voucherCode}} has been removed.',
        anchorLabel: 'Enter or remove your coupon code',
        vouchersApplied: 'Applied coupons',
        availableCoupons: 'Available coupons',
        availableCouponsLabel: 'You can add these coupons to this order.',
      },
      saveForLaterItems: {
        itemTotal: 'Saved for later ({{count}} item)',
        itemTotal_plural: 'Saved for later ({{count}} items)',
        cartTitle: 'Cart',
        saveForLater: 'Save For Later',
        moveToCart: 'Move To Cart',
        stock: 'Stock',
        forceInStock: 'In Stock',
      },
    },
    checkoutShipping: {
      checkoutShipping: {
        shippingMethod: 'Shipping Method',
        chooseShippingMethod: 'Choose a shipping method',
        deliveryNote: {
          name: 'Delivery note showing value',
          yes: 'Yes',
          no: 'No',
        },
        deliveryNoteObservations: 'Shipping observations',
        observationPayment: 'Invoice observations',
        observationsDeliveryPlaceholder: 'You can add instructions for the delivery person or a message for the recipient',
        presentCard: 'Gift card',
        presentCardPlaceholder: 'Message',
        standardDelivery: 'Standard Delivery',
        premiumDelivery: 'Premium Delivery',
      }
    },
    checkout: {
      checkoutAddress: {
        shippingAddress: 'Shipping Address',
        selectYourShippingAddress: 'Select your Shipping Address',
        defaultShippingAddress: 'Default Shipping Address',
        verifyYourAddress: 'Verify your address',
        ensureAccuracySuggestChange:
          'To ensure delivery accuracy, we suggest the change selected below.',
        chooseAddressToUse: 'Please choose which address you would like to use:',
        suggestedAddress: 'Suggested address',
        enteredAddress: 'Entered address',
        addNewAddress: 'Add New Address',
        shipToThisAddress: 'Ship to this address',
        editAddress: 'Edit address',
        saveAddress: 'Save address',
        addressList: 'My Address Book',
        showAddressList: 'Show Address Book',
        hideAddressList: 'Hide Address Book',
        filter: 'Filter',
        filterRemove: 'Remove filter',
        filterNoAddresses: 'No addresses found',
        billing: {
          title: 'BILLING ADDRESS',
          label: 'Request full invoice?',
        },
        onBehalfOf: {
          label: 'Invoice to you or a friend',
          me: 'Me',
          friend: 'Friend',
          placeholder: 'Select a friend',
          deliveryAddress: 'Selected shipping address',
          addressListTitle: 'Default shipping address for {{friendName}}',
          table: {
            alias: 'Alias',
            name: 'Name',
            phone: 'Phone',
            address: 'Address',
            deliveryNote: 'Shipping observations',
          },
        },
      },
      checkoutOrderConfirmation: {
        confirmationOfOrder: 'Confirmation of Order:',
        thankYou: 'Thank you for your order!',
        thankYou2: 'Our logistics team is already at work so that it arrives as soon as possible and in the perfect condition.',
        thankYou31: 'You can see this order and download the invoice, from',
        thankYou32: 'inside MY PROFILE icon.',
        bills: 'MY INVOICES',
        orderDetails: 'Track your order',
        orderDetailsLink: 'here',
        follow: 'Follow us on:',
        invoiceHasBeenSentByEmail:
          'An invoice has been sent by email. You should receive it soon.',
        orderItems: 'Order Items',
        orderPlacedSuccessfully: 'Order placed successfully',
        createAccount: 'Create an account?',
        createAccountForNext:
          'Create an account for <{{email}}> for a faster checkout on your next visit.',
        createAccountSuccess: 'Your account has been successfully created.',
        replenishmentNumber: 'Replenishment #',
        placedOn: 'Date',
        status: 'Status',
        active: 'Active',
        cancelled: 'Cancelled',
        frequency: 'Frequency',
        nextOrderDate: 'Next order date',
        orderNumber: 'Order Number',
      },
      checkoutReview: {
        review: 'Review',
        orderItems: 'Order Items',
        autoReplenishOrder: 'Auto Replenish Order',
        confirmThatRead: 'I agree with the',
        placeOrder: 'Place Order',
        scheduleReplenishmentOrder: 'Schedule Replenishment Order',
        orderType_PLACE_ORDER: 'None (one time order only)',
        orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Replenish Order',
        termsAndConditions: 'Terms & Conditions',
        editShippingAddress: 'Edit shipping address',
        poNumber: 'Purchase Order Number',
        every: 'Every',
        startOn: 'Start on',
        dayOfMonth: 'On day',
        repeatOnDays: 'Repeat on the following days',
        recurrencePeriodType_DAILY: 'Day(s)',
        recurrencePeriodType_WEEKLY: 'Week(s)',
        recurrencePeriodType_MONTHLY: 'Month',
        products: 'Products',
        group: 'Shipping {{current}} of {{total}}',
      },
      checkout: {
        backToCart: 'Back to cart',
        invalid: {
          accountType: 'Your account does not allow you to checkout here.',
        },
      },
      checkoutPO: {
        noPoNumber: 'None',
        poNumber: 'Purchase order number (optional)',
        costCenter: 'Cost Center',
        placeholder: 'Enter P.O',
        availableLabel:
          'Shipping addresses available to you depend on the cost center\'s unit',
      },
      checkoutProgress: {
        missingPaymentMethod: 'Missing payment method',
        missingPaymentMethodOptions: 'Missing payment method options. Please contact your administrator',
        methodOfPayment: '03. Method of Payment',
        shippingAddress: '01. Shipping Address',
        deliveryMode: '02. Delivery Mode',
        paymentDetails: '03. Payment Details',
        reviewOrder: '04. Review Order',
      },
      simulate: {
        CartError: 'Error simulating cart',
      },
    },
    paypal: {
      paypal: {
        error: {
          default: 'There has been an error with PayPal. Please try again later.',
          errorDetail: 'Sorry, your transaction could not be processed. {{msg}}',
        },
      },
    },
    bills: {
      bills: {
        title: 'My invoices',
        filterRemove: 'Remove filter',
        filterInvoiceNumber: 'Filter by invoice number',
        filterDateType: 'Filter',
        filterDateFrom: 'from',
        filterDateTo: 'to',
        filterInvoicesFriends: 'Show my friends invoices',
        headerInvoiceCode: 'Invoice number',
        headerInvoiceUser: 'Invoiced to',
        headerInvoiceDate: 'Invoice date',
        headerInvoiceDueDate: 'Due date',
        headerInvoicePayment: 'Payment method',
        headerInvoiceAmount: 'Amount',
        headerInvoicePending: 'Pending',
        notFound: 'No invoices found',
        sortByTitle: 'Sort by',
      },
    },
    contactForm: {
      contactForm: {
        labels: {
          email: 'Email',
          message: 'Comments',
          subject: 'Subject',
          orderCode: 'Order number (SC...)',
          accept: 'I accept the processing of my personal details for the activities outlined below.',
          requiredAction: 'Required Action',
          productsAffected: {
            label: 'Products and quantities affected',
            productDescription: 'Product description',
            quantity: 'Quantity',
            add: 'PRODUCT',
            remove: 'Remove product',
          },
          qualityProblem: 'Quality Problem',
          qualityProblems: {
            excessFat: 'Excess fat',
            smellFlavorColorProblems: 'Smell/flavor/color problems',
            vacuumLoss: 'Vacuum loss',
            excessLackCuring: 'Excess/lack of curing',
            other: 'Other reasons',
          },
        },
        placeholders: {
          ticketCauses: 'Select a reason',
          ticketTemplates: 'Select an option',
          qualityProblems: 'Select a problem',
        },
        formTemplateTitle: 'Contact form for {{formTitle}}',
        submit: 'Send a request',
        updateSuccess: 'Request sent successfully',
        updateError: 'There has been an error trying to send your request. Please try again later.',
        modelNotFoundError: 'Order number does not relate to this email. Please correct it and try again.',
      },
    },
    userProfile: {
      updateEmailForm: {
        newEmailAddress: {
          label: 'New email address',
          placeholder: 'Enter email',
        },
        confirmNewEmailAddress: {
          label: 'Confirm new email address',
          placeholder: 'Enter email',
        },
        enterValidEmail: 'Please enter a valid email.',
        bothEmailMustMatch: 'Both emails must match',
        password: {
          label: 'Password',
          placeholder: 'Enter password',
        },
        pleaseInputPassword: 'Please input password',
        emailUpdateSuccess: 'Success. Please sign in with {{ newUid }}',
      },
      register: {
        contactInfo: 'PERSONAL INFORMATION',
        addressInfo: 'SIGN-IN INFORMATION',
        confirmPassword: {
          action: 'Confirm password',
          label: 'Confirm password',
          placeholder: 'Confirm Password',
        },
        managementInMyAccount: 'Management in My Account.',
        termsAndConditions: 'Terms & Conditions',
        signIn: 'I already have an account. Sign In',
        register: 'Create an account',
        confirmNewPassword: 'Confirm New Password',
        resetPassword: 'Reset Password',
        createAccount: 'Create an account',
        title: 'Title',
        name: {
          label: 'Name and last name',
          placeholder: 'Name and last name',
        },
        firstName: {
          label: 'Name',
          placeholder: 'Name',
        },
        lastName: {
          label: 'Last name',
          placeholder: 'Last name',
        },
        emailAddress: {
          label: 'Email',
          placeholder: 'Enter a valid email',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        newPassword: 'New Password',
        emailMarketing:
          'Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.',
        confirmThatRead: 'I ACCEPT THE PROCESSING OF MY PERSONAL DETAILS FOR THE ACTIVITIES OUTLINED BELOW',
        selectTitle: 'Select Title',
        passwordMinRequirements:
          'Password must be six characters minimum, with one uppercase letter, one lowercase letter, one number and one symbol',
        bothPasswordMustMatch: 'Both password must match',
        titleRequired: 'Title is required.',
        postRegisterMessage: 'Please log in with provided credentials.',
        newsletter: "I AGREE TO SUBSCRIBE TO THE NEWSLETTER",
      },
      forgottenPassword: {
        resetPassword: 'Reset Password',
        resetButton: 'Reset Password',
        sendResetPassword: 'Change passwotd',
        enterEmailAddressAssociatedWithYourAccount: 'Password',
        emailAddress: {
          label: 'Email',
          placeholder: 'Email',
        },
        enterValidEmail: 'Please, introduce a valid email.',
        passwordResetEmailSent: 'If there is an account with the email provided, you will receive an email to reset your password.',
        passwordResetSuccess: 'Success! You can log in with your new password.',
        TokenInvalidatedError: 'The link used is not correct. If you have made several attempts be sure to use the link from the last email received. If not, please request it again.',
        IllegalArgumentError: 'Token Expired',
      },
    },
    myFriends: {
      myFriends: {
        goBack: 'Back to my friends',
        noSufficientPermissions: 'You do not have sufficient permission',
        friendCreated: 'Friend created successfully',
        friendCreatedInfo: 'Your friend will be registered in the system. In a few minutes you will be able to select them at the checkout',
        friendUpdate: 'Friend updated successfully',
        friendsNoLoaded: 'Friends could not be loaded',
        friendsNoCreated: 'Friend could not be created',
        friendsNoUpdate: 'Friend could not be updated',
        invalidToken: 'The link has expired or has already been used',
        friendRemoved: 'Friend deleted successfully',
        inviteCreated: 'Friend invited',
        inviteNoCreated: 'The friend could not be invited',
        search: 'Search by name or email',
        label: 'My friends',
        add: 'Add friend',
        edit: 'Edit friend',
        deleteFriend: 'Delete Friend',
        return: 'Return',
        saveData: 'Save',
        contactInfo: 'Contact info',
        guest: 'Guest',
        registered: 'Registered Guest',
        notInvited: 'Uninvited',
        expired: 'Expired',
        unknownEmail: 'Unknown email',
        allStatus: 'All status',
        invited: 'Invited',
        inviteFriend: 'Re-invite friend',
        filterTextSearch: 'Filter',
        filterInviteStatus: 'Filter invite status',
        filterRemove: 'Remove filter',
        filterInviteStatusPlaceholder: 'Not selected',
        notFound: 'No friends found',
        myFriendsForm: {
          name: 'Name and last name',
          email: 'Email',
          password: 'Password',
          confirmPassword: 'Confirm New Password',
          sendInvitation: 'Send invite',
          invitationStatus: 'Invitation status',
          expired: 'Expired',
          invited: 'Invited',
          billingData: 'Billing data',
          iban: 'IBAN',
          nif: 'Tax ID nº',
          yes: 'Yes',
          no: 'No',
          phoneNumber: 'Phone',
          personalMessage: 'Personal comment to include in the invitation email',
          personalMessagePlaceholder:
            'I attach a personal invitation so that you can access our friends website',
        },
        missingFriend: 'Friend not found',
        sendEmail: 'Send Email',
        sendEmailTooltip: 'Select a friend in order to access this feature',
        emails: {
          form: {
            header: 'Email sending',
            cc: 'CC',
            subject: 'Subject',
            submit: 'Send Email',
            productSearch: 'Search Products and Categories',
            productSearchPlaceholder: 'Product or Category Name...',
            placeholder: 'Enter Text',
          },
          success: 'Email sent',
          errors: {
            toCustomersUid: '{{emailList}} Customers email address not found.',
          },
        },
        ReferrerUnchangedError: 'The friend already exists in your friends list',
        ReferrerAlreadySetError: 'Your friend already exists in another user\'s friends list.',
        FriendNotOrphanError: 'The friend is not in your friends list but is already registered.',
        ModelSavingError: 'User already exists',
      },
    },
    CustomVideoInfoComponent: {
      CustomVideoInfoComponent: {
        buttonText: 'WATCH VIDEO',
      },
    },
    myAccount: {
      orderDetails: {
        orderId: 'Order #',
        replenishmentId: 'Replenishment #',
        purchaseOrderId: 'Purchase Order #',
        emptyPurchaseOrderId: 'None',
        none: 'None {{value}}',
        placed: 'Placed',
        placedBy: 'Placed By',
        unit: 'Unit',
        costCenter: 'Cost Center',
        costCenterAndUnit: 'Cost Center / Unit',
        costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
        payByAccount: 'Pay by Account',
        paidByCreditCard: '(paid by credit card)',
        status: 'Status',
        shippedOn: 'Shipped on',
        startOn: 'Start On',
        nextOrderDate: 'Next Order Date',
        frequency: 'Frequency',
        cancelled: ' Cancelled',
        shipping: 'Shipping',
        deliveryStatus_CREATED: 'Created',
        deliveryStatus_IN_TRANSIT: 'In Transit',
        deliveryStatus_READY_FOR_PICKUP: 'Ready for Pickup',
        deliveryStatus_READY_FOR_SHIPPING: 'Ready for Shipping',
        deliveryStatus_WAITING: 'Waiting',
        deliveryStatus_DELIVERING: 'Delivering',
        deliveryStatus_PICKPACK: 'Preparing for Shipment',
        deliveryStatus_PICKUP_COMPLETE: 'Pickup Complete',
        deliveryStatus_DELIVERY_COMPLETED: 'Delivery Complete',
        deliveryStatus_PAYMENT_NOT_CAPTURED: 'Payment Issue',
        deliveryStatus_READY: 'In Process',
        deliveryStatus_DELIVERY_REJECTED: 'Delivery Rejected',
        deliveryStatus_SHIPPED: 'Shipped',
        deliveryStatus_TAX_NOT_COMMITTED: 'Tax Issue',
        deliveryStatus_CANCELLED: 'Cancelled',
        statusDisplay_: '',
        statusDisplay_cancelled: 'Cancelled',
        statusDisplay_cancelling: 'Cancellation Pending',
        statusDisplay_completed: 'Delivered',
        statusDisplay_created: 'Created',
        statusDisplay_error: 'Pending',
        statusDisplay_Error: 'Pending',
        statusDisplay_processing: 'Pending',
        statusDisplay_open: 'Open',
        statusDisplay_pending: {
          approval: 'Pending Approval',
          merchant: {
            approval: 'Pending Merchant Approval',
          },
        },
        statusDisplay_approved: 'Approved',
        statusDisplay_rejected: 'Rejected',
        statusDisplay_merchant: {
          approved: 'Merchant Approved',
          rejected: 'Merchant Rejected',
        },
        statusDisplay_assigned: {
          admin: 'Assigned To Administrator',
        },
        consignmentTracking: {
          action: 'Track package',
          dialog: {
            header: 'Tracking Information',
            shipped: 'Shipped',
            estimate: 'Estimated Delivery',
            carrier: 'Delivery Service',
            trackingId: 'Tracking Number',
            noTracking:
              'The package has not been dispatched from the warehouse. The tracking information will be available after the package is shipped.',
            loadingHeader: 'Consignment Tracking',
          },
          button: {
            label: 'Tracking ',
          },
          deliveryDate: 'Delivery Date',
          estimatedDeliveryDate: 'Estimated Delivery Date',
          consignment: 'Consignment',
          reference: 'Internal Reference',
          failure: 'Incident',
        },
        cancellationAndReturn: {
          returnAction: 'Request a Return',
          cancelAction: 'Cancel Items',
          item: 'Item',
          itemPrice: 'Item Price',
          quantity: 'Quantity',
          returnQty: 'Quantity to Return',
          cancelQty: 'Quantity to Cancel',
          setAll: 'Return all units',
          totalPrice: 'Total',
          submit: 'Submit Request',
          returnSuccess: 'Your return request ({{rma}}) was submitted',
          returnError: 'There has been an error while processing your request. Please try again later.',
          returnPackError: 'There has been an error while processing your request. Pack quantities are incorrect.',
          cancelSuccess:
            'Your cancellation request was submitted (original order {{orderCode}} will be updated)',
          hasPackGroup: 'Products that are part of a pack cannot be returned separately',
        },
        cancelReplenishment: {
          title: 'Cancel Replenishment',
          description: 'Cancel any future replenishment order?',
          accept: 'Yes',
          reject: 'No',
          cancelSuccess:
            'Replenishment order #{{replenishmentOrderCode}} has been successfully cancelled',
        },
        actions: {
          contentCancellation: 'Are you sure you want to cancel the order? Your account will be credited within the following days if you have paid with a bank card',
          contentRestoration: 'In order to modify the order the current one will be cancelled. Your account will be credited within a few days if you have paid with bank card. The entire previous order will automatically be loaded so that you can modify it. Do you agree to this?',
          contentRestoreOnly: 'Are you sure you want to place this order again?',
          cancellable: 'Cancel order',
          restoration: 'Modify order',
          restoreOnly: 'Replace order',
          accept: 'Accept',
          cancel: 'Cancel',
          back: 'Back',
          orderCancellationSuccess: 'Order {{orderCode}} cancelled successfully',
          orderCancellationFail: 'There has been an error trying to cancel the order {{orderCode}}',
          orderRestorationSuccess: 'Order {{orderCode}} restored successfully',
          orderRestorationFail: 'There has been an error trying to restore the order {{orderCode}}',
          orderRestoreOnlySuccess: 'Products from order {{orderCode}} added to cart successfully',
          orderRestoreOnlyFail: 'There has been an error trying to add to cart the order {{orderCode}}',
          ticket: 'Ticket',
        },
        orderNumber: 'Order Number',
        shippingMethod: 'Shipping Method'
      },
      orderHistory: {
        orderHistory: 'My orders',
        orderId: 'Order #',
        emptyPurchaseOrderId: 'None',
        date: 'Date',
        status: 'Status',
        total: 'Total',
        noOrders: 'Order records not found',
        noReplenishmentOrders:
          'Replenishment order records not found',
        startShopping: 'Continue Shopping',
        sortBy: 'Sort by',
        sortByTitle: 'Sort by',
        sortByMostRecent: 'Sort by Most recent',
        replenishmentOrderHistory: 'Replenishment Order History',
        replenishmentOrderId: 'Replenishment #',
        purchaseOrderNumber: 'PO #',
        startOn: 'Start On',
        frequency: 'Frequency',
        nextOrderDate: 'Next Order Date',
        cancel: 'Cancel',
        cancelled: 'Cancelled',
        replenishmentHistory: 'Replenishment History',
        notFound: 'No Orders Found',
        cancelOrder: 'Cancel',
        editOrder: 'Modify',
        restoreOnlyOrder: 'Order again',
        orderDetails: 'Order detais',
        invoicedTo: 'Invoiced to',
        sendTo: 'Sent to',
        filterRemove: 'Remove filter',
        filterOrderNumber: 'Filter by order number',
        filterDateFrom: 'Filter date from',
        filterDateTo: 'to',
        filterOrdersInvoiced: 'Show invoiced orders',
        filterOrdersFriends: 'Show my friends orders',
        actions: {
          contentCancellation: 'Are you sure you want to cancel the order? Your account will be credited within the following days if you have paid with a bank card',
          contentRestoration: 'In order to modify the order the current one will be cancelled. Your account will be credited within a few days if you have paid with bank card. The entire previous order will automatically be loaded so that you can modify it. Do you agree to this?',
          cancellable: 'Cancel order',
          restoration: 'Modify order',
          accept: 'Accept',
          cancel: 'Cancel',
          back: 'Back',
        },
      },
      closeAccount: {
        confirmAccountClosure: 'Confirm Account Closure',
        confirmAccountClosureMessage:
          'Are you sure you want to close your account?',
        closeMyAccount: 'CLOSE MY ACCOUNT',
        accountClosedSuccessfully: 'Account closed successfully',
        accountClosedFailure: 'Failed to close account',
      },

      // @deprecated the updateEmailForm labels are moved to the user lib and will be dropped with the next major release
      updateEmailForm: {
        newEmailAddress: {
          label: 'New email address',
          placeholder: 'Enter email',
        },
        confirmNewEmailAddress: {
          label: 'Confirm new email address',
          placeholder: 'Enter email',
        },
        enterValidEmail: 'Please enter a valid email.',
        bothEmailMustMatch: 'Both emails must match',
        password: {
          label: 'Password',
          placeholder: 'Enter password',
        },
        pleaseInputPassword: 'Please input password',
        emailUpdateSuccess: 'Success. Please sign in with {{ newUid }}',
      },

      updatePasswordForm: {
        changePassword: 'Change password',
        oldPassword: {
          label: 'Old Password',
          placeholder: 'Old Password',
        },
        oldPasswordIsRequired: 'Old password is required.',
        newPassword: {
          label: 'New Password',
          placeholder: 'New Password',
        },
        passwordMinRequirements:
          'Password must be six characters minimum, with one uppercase letter, one lowercase letter, one number and one symbol',
        confirmPassword: {
          label: 'Confirm New Password',
          placeholder: 'Confirm Password',
        },
        bothPasswordMustMatch: 'Both password must match',
        passwordUpdateSuccess: 'Password updated successfully',
        sendForm: 'Change password',
      },
      updateProfileForm: {
        pageTitle: 'My information',
        contactInfo: 'CONTACT INFORMATION',
        addressInfo: 'INVOICE ADDRESS',
        title: '',
        none: '',
        firstName: {
          label: 'Full name',
          placeholder: 'Full name',
        },
        firstNameIsRequired: 'Full name is required.',
        lastName: {
          label: 'Last name',
          placeholder: 'Last name',
        },
        lastNameIsRequired: 'Last name is required.',
        profileUpdateSuccess: 'Personal details successfully updated',
        customerId: 'Customer #',
        documentIdentifier: 'ID',
        bankAccountNumber: 'IBAN',
        phone: {
          label: 'Phone number',
          info: '(preferably mobile to receive order notifications)',
        },
        uid: 'Email',
        address: 'Address',
        town: 'City',
        postalCode: 'Postal code',
        state: 'State',
        country: 'Zone - Country',
        region: 'Zone - Region',
        save: 'Save',
        selectOne: 'Select One...',
      },
      consentManagementForm: {
        clearAll: 'Clear all',
        selectAll: 'Select all',
        message: {
          success: {
            given: 'Consent successfully given.',
            withdrawn: 'Consent successfully withdrawn.',
          },
        },
      },
      myCoupons: {
        noCouponsMessage: 'You have no coupons available.',
        effectiveTitle: 'Effective:',
        Effective: 'EFFECTIVE',
        PreSession: 'EFFECTIVE SOON',
        ExpireSoon: 'EXPIRING SOON',
        readMore: 'Read more',
        notification: 'Notification',
        findProducts: 'Find Products',
        status: 'Status:',
        dialogTitle: 'Coupon',
        claimCustomerCoupon: 'You have successfully claimed this coupon.',
        myCoupons: 'My coupons',
        startDateAsc: 'Start Date (ascending)',
        startDateDesc: 'Start Date (descending)',
        endDateAsc: 'End Date (ascending)',
        endDateDesc: 'End Date (descending)',
        sortByMostRecent: 'Sort by Most recent',
        notesPreffix:
          'You can set your preferred channels for receiving coupon notifications on the ',
        notesLink: 'Notification Channels',
        notesSuffix: ' page.',
      },
      notificationPreference: {
        title: 'Notifications',
        message: 'Select your preferred notification channels:',
        note: 'Note: ',
        noteMessage:
          'If you deactivate all channels you will not be able to receive any further notifications.',
        EMAIL: 'Email:',
        SMS: 'SMS:',
        SITE_MESSAGE: 'Site message',
      },
      myInterests: {
        header: 'MY INTERESTS',
        item: 'ITEM',
        price: 'PRICE',
        notifications: 'NOTIFICATIONS',
        noInterests: 'You have no registered interests yet.',
        inStock: 'In Stock',
        lowStock: 'Low Stock',
        outOfStock: 'NOT AVAILABLE',
        BACK_IN_STOCK: 'Back In Stock',
        sortByMostRecent: 'Sort by Most recent',
        expirationDate: ' - Till {{ expirationDate }}',
        productId: 'ID {{ code }}',
        remove: 'REMOVE',
        sorting: {
          byNameAsc: 'Name (ascending)',
          byNameDesc: 'Name (descending)',
        },
      },
      AccountOrderHistoryTabContainer: {
        tabs: {
          AccountOrderHistoryComponent: 'ALL ORDERS ({{param}})',
          OrderReturnRequestListComponent: 'RETURNS ({{param}})',
        },
      },
      returnRequestList: {
        pageTitle: 'My returns',
        returnRequestId: 'Return #',
        orderId: 'Order #',
        orderReturnId: 'Return order #',
        date: 'Date',
        status: 'Status',
        total: 'Total',
        sortByTitle: 'Sort by',
        sortByMostRecent: 'Sort by Most recent',
        sortOrders: 'Sort orders',
        statusDisplay: '-',
        statusDisplay_APPROVAL_PENDING: 'Approval Pending',
        statusDisplay_CANCELED: 'Cancelled',
        statusDisplay_CANCELLING: 'Cancelling',
        statusDisplay_WAIT: 'Waiting',
        statusDisplay_RECEIVED: 'Received',
        statusDisplay_RECEIVING: 'Receiving',
        statusDisplay_APPROVING: 'Approved',
        statusDisplay_REVERSING_PAYMENT: 'Reversing Payment',
        statusDisplay_PAYMENT_REVERSED: 'Payment Reversed',
        statusDisplay_PAYMENT_REVERSAL_FAILED: 'Payment Reversal Failed',
        statusDisplay_REVERSING_TAX: 'Reversing Tax',
        statusDisplay_TAX_REVERSED: 'Tax Reversed',
        statusDisplay_TAX_REVERSAL_FAILED: 'Tax Reversal Failed',
        statusDisplay_COMPLETED: 'Completed',
        noReturns: 'You do not have any returns',
        startShopping: 'Continue Shopping',
      },
      returnRequest: {
        pageTitle: 'Return request details',
        returnRequestId: 'Request #',
        orderCode: 'Order #',
        orderReturnId: 'Return order #',
        status: 'Status',
        cancel: 'Cancel Return Request',
        item: 'Description',
        itemPrice: 'Item Price',
        returnQty: 'Return quantity',
        total: 'Total',
        summary: 'Summary',
        subtotal: 'Subtotal',
        deliveryCode: 'Delivery cost',
        estimatedRefund: 'Refund',
        note:
          'The totals are estimated and may not include applicable taxes or other charges.',
        cancelSuccess: 'Your return request ({{rma}}) was cancelled',
      },
    },
    wishlist: {
      wishlist: {
        empty: 'No products in your wish list yet',
        title: 'wish list',
        remove: 'Remove from wish list',
      }
    },
    payment: {
      paymentForm: {
        payment: 'Payment',
        choosePaymentMethod: 'Choose a payment method',
        paymentType: 'Payment Type',
        paymentObservations: 'Invoice observations',
        accountHolderName: {
          label: 'Account Holder Name',
          placeholder: 'Account Holder Name',
        },
        cardNumber: 'Card Number',
        expirationDate: 'Expiration Date',
        securityCode: 'Security code (CVV)',
        securityCodeTitle: 'Card Verification Value',
        saveAsDefault: 'Save as default',
        setAsDefault: 'Set as default',
        billingAddress: 'Billing address',
        sameAsShippingAddress: 'Same as shipping address',
        selectOne: 'Select One...',
        monthMask: 'MM',
        yearMask: 'YYYY',
        useThisPayment: 'Use this payment',
        addNewPayment: 'Add New Payment',
        changePayment: 'Change Payment',
        documentIdentifier: 'ID',
      },
      paymentMethods: {
        paymentMethods: 'Payment methods',
        newPaymentMethodsAreAddedDuringCheckout:
          'New payment methods are added during checkout.',
        invalidField: 'InvalidField: {{ field }}',
      },
      paymentCard: {
        deleteConfirmation: 'Are you sure you want to delete this payment method?',
        setAsDefault: 'Set as default',
        expires: 'Expires: {{ month }}/{{ year }}',
        defaultPaymentMethod: '✓ DEFAULT',
        selected: 'Selected',
      },
      paymentTypes: {
        title: 'Payment method',
        paymentType: '{{ display }}',
        paymentType_BankAccount: '{{ name }}\'s {{ display }}',
        saveCreditCard: 'Save credit card',
        creditCard: 'Credit card {{cardNumber}}',
        creditCardDeletePopup: {
          buttonDelete: 'Delete',
          buttonCancel: 'Back',
          message: 'Are you sure that you want to delete credit card number {{cartId}}?',
          accountHolderName: 'Name: {{accountHolderName}}',
          cardNumber: 'Number: {{cardNumber}}',
        },
        missingAddress: 'Missing delivery address. Please select a new delivery address to continue.',
      },
    },
    product: {
      productDetails: {
        id: 'ID',
        quantity: 'Qty',
        productDetails: 'Product Details',
        specification: 'Specs',
        reviews: 'Reviews',
        shipping: 'Shipping',
        share: 'Share',
        showReviews: 'Show reviews',
        noReviews: 'No reviews yet',
        slots: '{{ count }} slot',
        slots_plural: '{{ count }} slots',
        discount: 'Dis.',
      },
      productList: {
        filterBy: {
          label: 'Filter by',
          action: 'Filter by',
        },
        appliedFilter: 'Applied filter:',
        showLess: 'Show less...',
        showMore: 'Show more...',
        sortByRelevance: 'Sort by Relevance',
        backToTopBtn: 'BACK TO TOP',
        showMoreBtn: 'SHOW MORE',
        sortResults: 'Sort results',
      },
      productFacetNavigation: {
        filterBy: {
          label: 'Filter by',
          action: 'Filter by',
        },
        appliedFilter: 'Applied filter:',
        showLess: 'Show less...',
        showMore: 'Show more...',
        sortByRelevance: 'Sort by Relevance',
      },
      productSummary: {
        id: 'ID',
        showReviews: 'Show reviews',
        share: 'Share',
      },
      productReview: {
        overallRating: 'Overall Rating',
        reviewTitle: 'Review Title',
        writeYourComments: 'Write your comments',
        rating: 'Rating',
        reviewerName: 'Reviewer name (optional)',
        writeReview: 'Write a Review',
        more: 'More',
        less: 'Less',
        thankYouForReview:
          'Thank you for the review! Note that reviews may require review before appearing here.',
      },
      addToCart: {
        itemsNotAddedToYourCart: 'Insufficient stock to add this item to your cart',
        itemsAddedToYourCart: 'Item(s) added to your cart',
        itemsIncrementedInYourCart:
          'This item was already in your cart. The quantity was updated.',
        items: 'items',
        updatingCart: 'Updating cart...',
        addToCart: 'Add to cart',
        viewCart: 'view cart',
        proceedToCheckout: 'proceed to checkout',
        quantity: 'Qty',
        outOfStock: 'Not available',
        inStock: 'In stock',
        selectStyleAndSize: 'Select style and size to check stock',
        eachUnit: '/each',
        quantityUnit: 'Quantity: {{qty}} ({{name}}{{measurementToSalesUnit}})',
        quantityUnit_less: 'Quantity: {{qty}}',
        continueShopping: 'Continue Shopping',
        outOfStockUnit: 'Not available',
        unitSelect: 'SELECT',
      },
      TabPanelContainer: {
        tabs: {
          ProductDetailsTabComponent: 'Product Details',
          ProductSpecsTabComponent: 'Specs',
          ProductReviewsTabComponent: 'Reviews',
          deliveryTab: 'Shipping',
        },
      },
      /**
       * @deprecated since 3.2
       * Use feature-library @spartacus/product/variants/assets instead
       */
      variant: {
        style: 'Style',
        selectedStyle: 'Selected style',
        size: 'Size',
        color: 'Color',
        sizeGuideLabel: 'Style guide',
      },
      addToWishList: {
        add: 'Add to Wish List',
        remove: 'Remove from Wish List',
        anonymous: 'Sign in to add to wish list',
      },
      stockNotification: {
        notifyMe: 'NOTIFY ME',
        stopNotify: 'STOP NOTIFICATION',
        getNotify: 'Get notified when this product is available.',
        getNotifySuffix: 'to get notified when this product is available.',
        activateChannelsPrefix: 'To be notified you need to activate the ',
        channelsLink: 'Notification Channels',
        activateChannelsSuffix: '.',
        notified: 'You will be notified when this product is back in stock.',
        getNotified: 'Get notified when this product is back in stock.',
        unsubscribeSuccess:
          'You will not receive back-in-stock notification for this product.',
        subscriptionDialog: {
          header: 'Out of stock subscription',
          notifiedPrefix: 'You will be notified on:',
          notifiedSuffix: 'as soons as this product is back in stock.',
          manageChannelsPrefix: '',
          manageChannelsLink: '',
          manageChannelsSuffix: '',
          manageSubscriptionsPrefix: '',
          manageSubscriptionsLink: '',
          manageSubscriptionsSuffix: '',
          okBtn: 'OK',
          subscribing:
            'Subscribing you to Out of Stock notifications for this product',
        },
      },
      itemCounter: {
        removeOne: 'Remove one',
        addOneMore: 'Add one more',
        quantity: 'Quantity',
      },
    },
    productImageZoomTrigger: {
      productImageZoomTrigger: {
        expand: 'Zoom in',
        close: 'Close',
      },
    },
    productImageZoomDialog: {
      productImageZoomDialog: {
        expand: 'Zoom in',
        close: 'Close',
      },
    },
    statistics: {
      statistics: {
        compare: {
          label: 'Compare with last year',
          yes: 'Yes',
          no: 'No',
        },
        label: 'Statistics',
        month: 'Months',
        years: 'Year',
        euros: 'Euros',
        table: {
          article: 'Product',
          january: 'Jan.',
          february: 'Feb.',
          march: 'Mar.',
          april: 'Apr.',
          may: 'May',
          june: 'Jun.',
          july: 'Jul.',
          august: 'Aug.',
          september: 'Sep.',
          october: 'Oct.',
          november: 'Nov.',
          december: 'Dec.',
        },
      },
    },
    newsletter: {
      newsletter: {
        email: {
          submit: 'SUBSCRIBE',
          submitSuccess: 'SUBSCRIBED!',
          placeholder: 'Enter your email address',
        },
        accept: {
          label: 'I accept the processing of my personal details for the activities outlined below',
        },
        popup: {
          close: 'Close',
        },
        updateSuccess: 'Email subscribed to newsletter successfully',
        updateError: 'There has been an error trying to subscribe email to newsletter. Please try again later.',
      },
    },
    news: {
      news: {
        readMore: 'Read More',
        filterNewsTitle: 'News Title',
        filterRemove: 'Borrar filtro',
        filterDate: 'Filter',
        numberToShow: 'Show',
        search: 'Apply Filter',
        categories: 'Categories',
        allCategories: 'All',
        notFound: 'No news found',
        filtersNotFound: 'No news found with applied filters',
        filters: {
          category: {
            loading: 'Loading...',
            ALL: 'All News',
            BRANDS_AND_NEWS: 'Brands and news',
            LIFESTYLE: 'Lifestyle',
            GASTRONOMY: 'Gastronomy',
            WINE_TOURISM_AND_VISITS: 'Tourism and visits',
            TRAINING_AND_INNOVATION: 'Training and innovation',
            MEDIA: 'Media'
          }
        }
      },
    },
    recaptcha: {
      recaptcha: {
        scriptFailedToLoad: 'Failed to load reCaptcha script.',
        recaptchaTokenV2Failed: 'Verify reCaptcha information.',
        invalidVersion: 'reCaptcha version {{version}} is invalid. Available versions: {{available}}',
        expired: 'Recaptcha validation expired',
        fail: 'There has been an error with reCaptcha',
      }
    },
    login: {
      login: {
        btnTitle: 'Login',
      },
    },
    productCategories: {
      productCategories: {
        title: 'Categories',
      },
    },
    preferenceCenter: {
      preferenceCenter: {
        form: {
          title: 'MY PERSONAL INFORMATION',
          name: {
            label: 'First Name',
            placeholder: 'First Name',
          },
          surnames: {
            label: 'Last Name',
            placeholder: 'Last Name',
          },
          email: {
            label: 'Email address',
            placeholder: 'Email address',
            contact: 'For email modifications, please contact sac_web@osborne.es',
          },
          birthDate: {
            label: 'Date of Birth',
            placeholder: 'dd/mm/yyyy',
          },
          province: {
            label: 'Province',
            placeholder: 'Province',
          },
          phoneNumber: {
            label: 'Mobile Phone',
            placeholder: 'Mobile Phone',
          },
          language: {
            label: 'Language',
            placeholder: 'Language',
          },
          unsubscribe: {
            title: 'UNSUBSCRIBE',
            placeholder: 'do not wish to receive further commercial communications.',
          },
          update: 'UPDATE',
          preferenceUpdateSuccess: 'Personal data updated successfully',
          preferenceUpdateError: 'Personal data could not be updated',
        },
        languages: {
          en: 'English',
          fr: 'French',
          gt: 'Galician',
          de: 'German',
          it: 'Italian',
          pt: 'Portuguese',
          es: 'Spanish',
        },
      }
    }
  },
};
