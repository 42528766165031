import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPageTopTitleComponent } from './custom-page-top-title.component';
import { CmsConfig, PageMetaModule, ConfigModule } from '@spartacus/core';

@NgModule({
  declarations: [CustomPageTopTitleComponent],
  imports: [
    CommonModule,
    PageMetaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomPageTopTitleComponent: {
            component: CustomPageTopTitleComponent,
        }
      }
    } as CmsConfig)
  ],
  exports: [CustomPageTopTitleComponent],
})
export class CustomPageTopTitleModule { }
