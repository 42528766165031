<section
  class="container"
  *ngIf="!(isLoading$ | async); else loading"
>
  <div class="row justify-content-center">
    <div class="cx-section">
      <form (ngSubmit)="submitForm()" [formGroup]="registerForm">
        <h3>
          {{ 'register.contactInfo' | cxTranslate }}
        </h3>
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.firstName.label' | cxTranslate
              }}</span>
            <input
              class="form-control"
              type="text"
              name="firstname"
              placeholder="{{
                'register.firstName.placeholder' | cxTranslate
              }}"
              formControlName="firstName"
              (input)="onChangeCompleteName()"
            />
          </label>
          <cx-form-errors
            [control]="registerForm.get('firstName')"
            [translationParams]="{
              firstControl: 'register.firstName.label' | cxTranslate,
              secondControl: 'register.lastName.label' | cxTranslate,
              maxLength: registerForm.get('firstName')?.errors?.combinedMaxlength?.maxLength,
              actualLength: registerForm.get('lastName')?.errors?.combinedMaxlength?.actualLength
            }">
          </cx-form-errors>
        </div>
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.lastName.label' | cxTranslate
              }}</span>
            <input
              class="form-control"
              type="text"
              name="lastname"
              placeholder="{{
                'register.lastName.placeholder' | cxTranslate
              }}"
              formControlName="lastName"
              (input)="onChangeCompleteName()"
            />
          </label>
          <cx-form-errors
            [control]="registerForm.get('lastName')"
            [translationParams]="{
              firstControl: 'register.firstName.label' | cxTranslate,
              secondControl: 'register.lastName.label' | cxTranslate,
              maxLength: registerForm.get('lastName')?.errors?.combinedMaxlength?.maxLength,
              actualLength: registerForm.get('lastName')?.errors?.combinedMaxlength?.actualLength
            }">
          </cx-form-errors>
        </div>
        <h3>
          {{ 'register.addressInfo' | cxTranslate }}
        </h3>
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.emailAddress.label' | cxTranslate
              }}</span>
            <input
              class="form-control"
              type="email"
              name="email"
              placeholder="{{
                'register.emailAddress.placeholder' | cxTranslate
              }}"
              formControlName="email"
            />
          </label>
          <cx-form-errors
            [control]="registerForm.get('email')"
          ></cx-form-errors>
        </div>
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.password.label' | cxTranslate
              }}</span>
            <input
              class="form-control"
              type="password"
              name="password"
              placeholder="{{
                'register.password.placeholder' | cxTranslate
              }}"
              formControlName="password"
            />
          </label>
          <cx-form-errors
            [control]="registerForm.get('password')"
          ></cx-form-errors>
        </div>
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.confirmPassword.label' | cxTranslate
              }}</span>
            <input
              class="form-control"
              type="password"
              name="confirmpassword"
              placeholder="{{
                'register.confirmPassword.placeholder' | cxTranslate
              }}"
              formControlName="passwordconf"
            />
          </label>
          <cx-form-errors
            [control]="registerForm.get('passwordconf')"
          ></cx-form-errors>
        </div>
        <div class="form-group">
          <div class="form-check">
            <label>
              <input
                type="checkbox"
                name="termsandconditions"
                formControlName="termsandconditions"
              />
              <span class="form-check-label">
                {{ 'register.confirmThatRead' | cxTranslate }}
              </span>
            </label>
            <cx-form-errors
              [control]="registerForm.get('termsandconditions')"
            ></cx-form-errors>
          </div>
        </div>
        <div
          class="form-group"
          [hidden]="!isMobile"
        >
          <div class="form-check">
            <label>
              <input
                type="checkbox"
                name="useFingerprint"
                formControlName="useFingerprint"
              />
              <span class="form-check-label"> <i class="fa-solid fa-fingerprint"></i> {{ 'webauthn.form' | cxTranslate }} </span>
            </label>
          </div>
        </div>
        <cx-page-slot position="BodyContent"></cx-page-slot>
        <div class="form-group">
          <button type="submit" class="btn btn-send">
            {{ 'register.register' | cxTranslate }}
          </button>
          <a [routerLink]="{ cxRoute: 'login' } | cxUrl">
            {{ 'register.signIn' | cxTranslate }}
          </a>
        </div>
        <div class="form-group">
          <osborne-captcha-disclaimer></osborne-captcha-disclaimer>
        </div>
      </form>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
