import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  CmsPageGuard,
  PageLayoutComponent,
} from '@spartacus/storefront';
import { CustomAmendOrderItemsModule } from '../../../custom-amend-order-items/custom-amend-order-items.module';
import { CustomOrderReturnGuard } from '../../custom-order-return.guard';
import { CustomOrderReturnService } from '../../custom-order-return.service';
import { CustomReturnOrderConfirmationComponent } from './custom-return-order-confirmation.component';
import { AmendOrderActionsModule, OrderAmendService } from '@spartacus/order/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturnConfirmation',
        },
      },
    ]),
    CustomAmendOrderItemsModule,
    I18nModule,
    ReactiveFormsModule,
    AmendOrderActionsModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        ReturnOrderConfirmationComponent: {
          component: CustomReturnOrderConfirmationComponent,
          guards: [AuthGuard, CustomOrderReturnGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: CustomOrderReturnService,
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomReturnOrderConfirmationComponent],
  exports: [CustomReturnOrderConfirmationComponent],
})
export class CustomReturnOrderConfirmationModule {}
