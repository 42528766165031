import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomParagraphComponent } from './custom-paragraph.component';
import { PageComponentModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { HtmlSanitizeModule } from '../../../../../custom/util/html-sanitize/html-sanitize.module';

@NgModule({
  declarations: [CustomParagraphComponent],
  imports: [
    CommonModule,
    PageComponentModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSParagraphComponent: {
          component: CustomParagraphComponent,
        },
      },
    } as CmsConfig),
    HtmlSanitizeModule,
  ],
  exports: [CustomParagraphComponent],
})
export class CustomParagraphModule {}
