<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
<form id="loginForm" (ngSubmit)="onSubmit()" [formGroup]="form">
  <h2>
    {{ (showExtendedLoginInfo ? 'loginForm.accessLong' : 'loginForm.access') | cxTranslate }}
  </h2>
  <div *ngIf="showExtendedLoginInfo">
    <p>
      {{ 'loginForm.accessInfo' | cxTranslate }}
    </p>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ (showExtendedLoginInfo ? 'loginForm.emailAddress.labelLong' : 'loginForm.emailAddress.label') | cxTranslate }}
      </span>
      <input
        id="loginFormEmail"
        type="email"
        class="form-control"
        formControlName="userId"
      />
    </label>
    <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ 'loginForm.password.label' | cxTranslate }}
      </span>
      <input
        id="loginFormPassword"
        type="password"
        class="form-control"
        formControlName="password"
      />
    </label>
    <cx-form-errors [control]="form.get('password')"></cx-form-errors>
  </div>

  <div class="buttons-container">
    <recaptcha
      (currentWidgetId)="getWidgetItemId($event)"
    ></recaptcha>
  </div>

  <div class="buttons-container">
    <div class="register-reset-password">
      <a
        [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
        aria-controls="reset-password"
        class="btn-link"
      >
        {{ (showExtendedLoginInfo ? 'loginForm.forgotPasswordLong' : 'loginForm.forgotPassword') | cxTranslate }}
      </a>
    </div>
  
    <button
      id="loginFormSubmit"
      type="submit"
      class="btn btn-login"
    >
      {{ (showExtendedLoginInfo ? 'loginForm.signInLong' : 'loginForm.signIn') | cxTranslate }}
    </button>
  </div>
</form>
