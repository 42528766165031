import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomHoverAwareImagesContainerComponent } from './custom-hover-aware-images-container.component';
import { CustomHoverAwareImageModule } from '../custom-hover-aware-image/custom-hover-aware-image.module';


@NgModule({
  declarations: [CustomHoverAwareImagesContainerComponent],
  imports: [
    CommonModule,
    CustomHoverAwareImageModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsHoverAwareImagesContainerComponent: {
          component: CustomHoverAwareImagesContainerComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomHoverAwareImagesContainerComponent],
})
export class CustomHoverAwareImagesContainerModule {}
