import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  PageMetaModule,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import { CustomBreadcrumbComponent } from './custom-breadcrumb.component';

@NgModule({
  imports: [CommonModule, RouterModule, PageMetaModule],
  providers: [
    provideConfig({
      cmsComponents: {
        BreadcrumbComponent: {
          component: CustomBreadcrumbComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomBreadcrumbComponent],
  exports: [CustomBreadcrumbComponent],
})
export class CustomBreadcrumbModule {}
