import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CountryType, LoggerService, tryNormalizeHttpError, UserActions } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomSiteConnector } from '../../../cart/site-context/connectors/custom-site.connector';
import { CustomUserActions } from '../actions';

@Injectable()
export class CustomDeliveryCountriesEffects {

  loadDeliveryCountriesAll$: Observable<UserActions.DeliveryCountriesAction> = createEffect(() => this.actions$.pipe(
    ofType(CustomUserActions.CUSTOM_LOAD_DELIVERY_COUNTRIES_ALL),
    switchMap(() => {
      return this.siteConnector.getCountriesAll(CountryType.SHIPPING).pipe(
        map(
          (countries) => new UserActions.LoadDeliveryCountriesSuccess(countries)
        ),
        catchError((error) =>
          of(
            new UserActions.LoadDeliveryCountriesFail(tryNormalizeHttpError(error, this.logger))
          )
        )
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private siteConnector: CustomSiteConnector,
    private logger: LoggerService
  ) {}
}

