import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomFriendsConnector } from '../../connectors/friends.connector';
import { Friend, MyFriendsList } from '../../facade/friends.model';
import {
  GlobalMessageActions,
  GlobalMessageType,
  HttpErrorModel,
  LoggerService,
  tryNormalizeHttpError,
  UserIdService
} from '@spartacus/core';
import {
  CreateFriend,
  CreateFriendFail,
  CreateFriendSuccess,
  FriendsActionTypes,
  InvitedFriend,
  InvitedFriendFail,
  InvitedFriendSuccess,
  LoadFriend,
  LoadFriends,
  LoadFriendsFail,
  LoadFriendsSuccess,
  LoadFriendSuccess,
  RemoveFriend,
  RemoveFriendFail,
  RemoveFriendSuccess,
  UpdateFriend,
  UpdateFriendFail,
  UpdateFriendSuccess,
} from '../actions/custom-friends-users.action';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';

@Injectable()
export class CustomFriendsEffects {
  constructor(
    private actions$: Actions,
    private friendsConnector: CustomFriendsConnector,
    protected userIdService: UserIdService,
    private logger: LoggerService
  ) {}

  loadFriends$: Observable<LoadFriendsSuccess | LoadFriendsFail | AddMessage> = createEffect(() => this.actions$.pipe(
    ofType(FriendsActionTypes.LOAD_FRIENDS),
    map((action: LoadFriends) => action.payload),
    mergeMap((payload) => {
      return this.friendsConnector.getAll(
        payload.userId,
        payload.pageSize,
        payload.currentPage,
        payload.friendsFilter,
      ).pipe(
        map((friends: MyFriendsList) => {
          return new LoadFriendsSuccess(friends);
        }),
        catchError((error) => {
            return from(
              [
                new LoadFriendsFail(tryNormalizeHttpError(error, this.logger)),
                new GlobalMessageActions.AddMessage({
                  text: { key: 'myFriends.friendsNoLoaded' },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              ]);
          },
        ),
      );
    }),
  ));

  loadFriend$: Observable<LoadFriendsFail | AddMessage | LoadFriendSuccess> = createEffect(() => this.actions$.pipe(
    ofType(FriendsActionTypes.LOAD_FRIEND),
    map((action: LoadFriend) => action.payload),
    mergeMap((payload: { userId: string; friendId: string }) => {
      return this.friendsConnector.get(payload.userId, payload.friendId).pipe(
        map((response: any) => {
          const friend: Friend = {
            ...response,
            defaultPaymentAddress: response.defaultAddress ? response.defaultAddress : '',
            iban: response.bankAccountNumber,
            firstNameLastName: response.firstName + (response.lastName ? ' ' + response.lastName : ''),
          };
          return new LoadFriendSuccess(friend);
        }),
        catchError((errorData) => {
          return from(
            [
              new LoadFriendsFail(tryNormalizeHttpError(errorData, this.logger)),
              new GlobalMessageActions.AddMessage({
                text: {
                  key: 'myFriends.missingFriend',
                },
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]);
          },
        ),
      );
    }),
  ));

  createFriend$: Observable<CreateFriendFail | CreateFriendSuccess | GlobalMessageActions.AddMessage | LoadFriends> =
  createEffect(() => this.actions$.pipe(
      ofType(FriendsActionTypes.CREATE_FRIEND),
      map((action: CreateFriend) => action.payload),
      mergeMap((payload: { userId: string; friend: Friend }) => {
        return this.friendsConnector
          .add(payload.userId, { ...payload.friend, firstName: payload.friend.firstNameLastName })
          .pipe(
            switchMap((response: Friend) => {
              const actions = [
                new CreateFriendSuccess({ friend: response, status: true }),
                new GlobalMessageActions.AddMessage({
                  text: { key: 'myFriends.friendCreated' },
                  type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
                }),
                new LoadFriends({ userId: payload.userId }),
              ];
              if (payload?.friend?.sendInvitation === false) {
                actions.push(
                  new GlobalMessageActions.AddMessage({
                    text: { key: 'myFriends.friendCreatedInfo' },
                    type: GlobalMessageType.MSG_TYPE_INFO,
                    timeout: 15000,
                  })
                );
              }
              return actions;
            }),
            catchError((response) => {
                const normalizedError = tryNormalizeHttpError(response, this.logger) as HttpErrorModel || {};
                const actionsArray = [];
                normalizedError.details.map(error =>
                  actionsArray.push(new GlobalMessageActions.AddMessage({
                      text: { key: `myFriends.${ error.type }` },
                      type: GlobalMessageType.MSG_TYPE_ERROR,
                    }),
                  ),
                );
                return from([
                    new CreateFriendFail(normalizedError),
                    ...actionsArray,
                  ],
                );
              },
            ),
          );
      }),
    ));

  removeFriend$: Observable<RemoveFriendFail | RemoveFriendSuccess | GlobalMessageActions.AddMessage | LoadFriends> =
  createEffect(() => this.actions$.pipe(
      ofType<RemoveFriend>(FriendsActionTypes.REMOVE_FRIEND),
      map((action: RemoveFriend) => action.payload),
      mergeMap((payload: { userId: string; friendId: string }) => {
        return this.friendsConnector.delete(payload.userId, payload.friendId).pipe(
          switchMap((response) => {
            return [
              new RemoveFriendSuccess(response),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.friendRemoved' },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new LoadFriends({ userId: payload.userId }),
            ];
          }),
          catchError((error) =>
            of(new RemoveFriendFail(tryNormalizeHttpError(error, this.logger))),
          ),
        );
      }),
    ));

  updateFriend$: Observable<UpdateFriendFail | UpdateFriendSuccess | GlobalMessageActions.AddMessage | LoadFriends> =
  createEffect(() => this.actions$.pipe(
      ofType<UpdateFriend>(FriendsActionTypes.UPDATE_FRIEND),
      map((action: UpdateFriend) => action.payload),
      mergeMap((payload: { userId: string; friend: Friend }) => {
        const value: Friend = {
          ...payload.friend,
          firstName: payload.friend.firstNameLastName,
        };
        return this.friendsConnector.update(payload.userId, value).pipe(
          switchMap((response) => {
            return [
              new UpdateFriendSuccess({ friend: response, status: true }),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.friendUpdate' },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new LoadFriends({ userId: payload.userId }),
            ];
          }),
          catchError((error) =>
            from([
              new UpdateFriendFail(tryNormalizeHttpError(error, this.logger)),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.friendsNoUpdate' },
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]),
          ),
        );
      }),
    ));

  invitedFriend$: Observable<InvitedFriendFail | InvitedFriendSuccess | GlobalMessageActions.AddMessage | LoadFriends> =
  createEffect(() => this.actions$.pipe(
      ofType<InvitedFriend>(FriendsActionTypes.INVITED_FRIEND),
      map((action: InvitedFriend) => action.payload),
      mergeMap((payload: { userId: string; friend: Friend }) => {
        return this.friendsConnector.invitedFriend(payload.userId, payload.friend).pipe(
          switchMap((response) => {
            return [
              new InvitedFriendSuccess(response),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.inviteCreated' },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new LoadFriends({ userId: payload.userId }),
            ];
          }),
          catchError((error) =>
            from([
              new InvitedFriendFail(tryNormalizeHttpError(error, this.logger)),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.inviteNoCreated' },
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]),
          ),
        );
      }),
    ));
}

export const FriendsEffects: any[] = [CustomFriendsEffects];
