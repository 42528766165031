import { NgModule } from '@angular/core';
import { AsmComponentsModule } from '@spartacus/asm/components';
import { AsmOccModule } from '@spartacus/asm/occ';
import { CartBaseCoreModule, CartPageEventModule } from '@spartacus/cart/base/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import { PaymentType } from '@spartacus/cart/base/root';
import { WishListModule } from '@spartacus/cart/wish-list';
import { B2BPaymentTypeEnum } from '@spartacus/checkout/b2b/root';
import { CheckoutModule } from '@spartacus/checkout/base';
import { CheckoutComponentsModule, CheckoutLoginModule } from '@spartacus/checkout/base/components';
import { CheckoutOccModule } from '@spartacus/checkout/base/occ';
import {
  AnonymousConsentsModule, AuthModule,
  CmsBannerComponentMedia, CmsResponsiveBannerComponentMedia,
  CostCenterModule, CostCenterOccModule,
  ExternalRoutesModule, OccEndpoint, PageType, Principal,

  ProductModule, ProductOccModule,
  provideFeatureToggles,
  UserModule, UserOccModule
} from '@spartacus/core';
import {
  OrderCancellationModule, OrderDetailsModule,
  OrderHistoryModule, OrderReturnModule,


  ReplenishmentOrderDetailsModule, ReplenishmentOrderHistoryModule, ReturnRequestDetailModule, ReturnRequestListModule
} from '@spartacus/order/components';
import { ProductVariantsModule } from '@spartacus/product/variants';
import { SmartEditModule } from '@spartacus/smartedit';
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule, BannerCarouselModule,
  BannerModule,
  CategoryNavigationModule, CmsParagraphModule,
  ConsentManagementModule, FooterNavigationModule,
  HamburgerMenuModule, HomePageEventModule,
  LinkModule, LoginRouteModule,
  LogoutModule, Media,
  MyCouponsModule, MyInterestsModule,
  NavigationEventModule, NavigationModule,
  NotificationPreferenceModule, PaymentMethodsModule,
  ProductCarouselModule, ProductDetailsPageModule,
  ProductFacetNavigationModule, ProductImagesModule,
  ProductIntroModule, ProductListingPageModule,
  ProductListModule, ProductPageEventModule,
  ProductReferencesModule, ProductSummaryModule,
  ProductTabsModule, SearchBoxModule,
  SiteContextSelectorModule, StockNotificationModule,
  TabParagraphContainerModule, UserComponentModule
} from '@spartacus/storefront';
import { PersonalizationModule } from '@spartacus/tracking/personalization';
import {
  AddressBookModule, CloseAccountModule, ForgotPasswordModule,
  ResetPasswordModule, UpdateEmailModule,
  UpdatePasswordModule, UpdateProfileModule
} from '@spartacus/user/profile/components';
import { EmarsysModule } from '../custom/emarsys-web-extend/emarsys.module';
import { EntryGroup } from '../model/entry-groups.model';
import { ProductLabel } from '../model/product.model';
import { Unit } from '../model/unit';
import { UnitPrice } from '../model/unit-price';
import { CustomBreadcrumbModule } from './custom/cms-components/navigation/custom-breadcrumb/custom-breadcrumb.module';
import { CustomJsonLdBuilderModule } from './custom/cms-structure/seo/structured-data/builders/custom-json-ld-builder.module';
import { HrefLangMeta } from './custom/core/cms/page/model/custom-page.model';
import { ProductImageZoomModule } from './custom/feature-libs/product/image-zoom';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { CustomPreferenceCenterFeatureModule } from './features/preference-center/custom-preference-center-feature.module';
import { ProductConfiguratorFeatureModule } from './features/product-configurator/product-configurator-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';

export * from './custom/feature-libs/cart/root/models';
export * from './custom/feature-libs/user/root/models';

@NgModule({
  declarations: [],
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule, // will become part of auth package
    LoginRouteModule, // will become part of auth package

    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    CustomBreadcrumbModule,

    // User Core
    UserOccModule,
    // User UI
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,

    // Anonymous Consents Core
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,

    // Product Core
    ProductModule.forRoot(),
    ProductOccModule,

    // Product UI
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImageZoomModule,
    ProductSummaryModule,
    ProductIntroModule,

    // Cart Core
    CartBaseCoreModule,
    CartBaseOccModule,
    WishListModule,

    // Checkout Core
    CheckoutModule,
    CheckoutOccModule,
    CostCenterOccModule,
    // Checkout UI
    CheckoutLoginModule,
    CheckoutComponentsModule,

    // Order
    OrderHistoryModule,
    OrderCancellationModule,
    OrderReturnModule,
    ReturnRequestListModule,
    ReturnRequestDetailModule,
    ReplenishmentOrderHistoryModule,
    ReplenishmentOrderDetailsModule,

    // Page Events
    NavigationEventModule,
    HomePageEventModule,
    CartPageEventModule,
    ProductPageEventModule,
    UserFeatureModule,
    SmartEditFeatureModule,
    ProductConfiguratorFeatureModule,
    AsmFeatureModule,

    // SEO
    CustomJsonLdBuilderModule,

    // FROM UPDATE 3.4
    ExternalRoutesModule.forRoot(),

    CostCenterModule.forRoot(),

    AsmComponentsModule,
    SmartEditModule,
    PersonalizationModule,
    OrderDetailsModule,
    ProductImagesModule,
    ProductVariantsModule,
    UserComponentModule,
    CloseAccountModule,
    UpdateEmailModule,
    UpdatePasswordModule,
    UpdateProfileModule,
    ForgotPasswordModule,
    ResetPasswordModule,

    UserModule.forRoot(),

    AsmOccModule,
    CartBaseFeatureModule,
    CartSavedCartFeatureModule,
    CheckoutFeatureModule,
    OrderFeatureModule,
    // Tracking Emarsys Web Extend
    EmarsysModule,
    CustomPreferenceCenterFeatureModule,
  ],
  providers: [
    provideFeatureToggles({
      a11yCheckoutDeliveryFocus: false,
      a11yOrganizationsBanner: false,
      a11yRequiredAsterisks: false,
    }),
  ]
})
export class SpartacusFeaturesModule { }

declare module '@spartacus/core' {
  interface OccEndpoints {
    preferenceCenter?: string | OccEndpoint;
    updatePreference?: string | OccEndpoint;
  }

  interface CMSTabParagraphContainer {
    showAsAccordion?: string | boolean;
  }

  interface Product {
    unit?: Unit;
    unitPrices?: Array<UnitPrice>;
    brands?: any;
    specifications?: any;
    itemtype?: string;
    numberOfSlots?: number;
    metaTitle?: string;
    metaDescription?: string;
    names?: { entry: [{ key: string, value: string }] };
    labelProduct?: ProductLabel[];
    variantCode?: string;
    principalVariant?: boolean;
    ean?: string;
  }

  interface CmsLinkComponent {
    media?: Media;
    icon?: CmsBannerComponentMedia | CmsResponsiveBannerComponentMedia;
  }

  const enum ProductScope {
    SPECIFICATIONS = 'specifications',
    PRICES = 'prices',
  }

  interface PaymentTypeList {
    paymentModes?: Array<PaymentType>;
  }

  interface Address {
    companyName?: string;
    email?: string;
    deliveryNoteObservations?: string;
    validCheckoutAddress?: boolean;
    visibleInAddressBook?: boolean;
  }

  interface ProductSearchPage {
    categoryDescription?: string;
    categoryMetaDescription?: string;
    categoryMetaTitle?: string;
  }

  enum ICON_TYPE {
    LOCATION = 'icon-location',
  }

  interface Facet {
    code?: string;
  }

  interface SearchResults {
    facets?: any[];
  }

  interface CmsNavigationNode {
    addWithPrevious?: boolean;
  }
  interface CmsParagraphComponent {
    fullPageWidth?: boolean | string;
    backgroundColorCode: string;
  }

  interface PageMeta {
    hrefLangs?: HrefLangMeta[];
  }

  interface UrlCommandRoute {
    language?: string;
  }

  interface CmsProductCarouselComponent {
    link?: CmsLinkComponent;
    fullPageWidth?: boolean | string;
    backgroundColorCode?: string;
  }

  interface VariantOption {
    unitPrices?: UnitPrice[]
  }
}

declare module '@spartacus/storefront' {
  interface NavigationNode {
    addWithPrevious?: boolean;
    group?: NavigationNode[];
    maxRows?: number;
  }

  interface Media {
    downloadUrl?: string;
    mime?: string;
  }
}

declare module '@spartacus/order/root' {
  interface Order {
    sendTo?: string;
    invoicedTo?: Principal;
    paymentMode?: PaymentType;
    deliveryNoteObservations?: string;
    entryGroups?: EntryGroup[];
    restorable?: boolean;
    printTicket?: boolean;
    restoreOnly?: boolean;
  }
  interface ReturnRequest {
    returnOrder?: Order;
  }
  interface ReturnRequestEntry {
    receivedQuantity?: number;
  }

  interface OrderHistory {
    invoicedTo?: Principal;
    endTo?: string;
    reorderable?: boolean;
    cancellable?: boolean;
    restoreOnly?: boolean;
  }
}

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    HEADER_SHIPPING = 'HEADER_SHIPPING', // CustomPopupShippingComponent
    BOX_LINK_IMAGE_ITEM = 'BOX_LINK_IMAGE_ITEM', // CustomModalComponent
    NEWSLETTERS = 'NEWSLETTERS', // CustomNewsletterDialogComponent
    CUSTOM_ORDER_CANCELLATION = 'CUSTOM_ORDER_CANCELLATION', // CustomOrderCancellationComponent
    CUSTOM_ORDER_RESTORATION = 'CUSTOM_ORDER_RESTORATION', // CustomOrderRestorationComponent
    CUSTOM_ORDER_RESTORE_ONLY = 'CUSTOM_ORDER_RESTORE_ONLY', // CustomOrderRestoreOnlyComponent
    CUSTOM_ADDED_TO_CART = 'CUSTOM_ADDED_TO_CART', // CustomAddedToCartDialogComponent
    CUSTOM_ADDED_TO_CART_GIFT_BOX = 'CUSTOM_ADDED_TO_CART_GIFT_BOX', // CustomAddProductToCartWithGiftboxDialogComponent
    PAYMENT_TYPE = 'PAYMENT_TYPE', // CustomPaymentTypeModalComponent
    FRIENDLY = 'FRIENDLY'
  }
}

declare module '@spartacus/checkout/b2b/root' {
  enum B2BPaymentTypeEnum {
    CREDIT_CARD_PAYMENT = 'CreditCard',
  }
} (B2BPaymentTypeEnum as any)['CREDIT_CARD_PAYMENT'] = 'CreditCard';

declare module '@spartacus/checkout/b2b/root' {
  enum B2BPaymentTypeEnum {
    BIZUM_PAYMENT = 'Bizum',
  }
} (B2BPaymentTypeEnum as any)['BIZUM_PAYMENT'] = 'Bizum';

declare module '@spartacus/checkout/b2b/root' {
  enum B2BPaymentTypeEnum {
    PAYPAL_PAYMENT = 'PayPal',
  }
} (B2BPaymentTypeEnum as any)['PAYPAL_PAYMENT'] = 'PayPal';

declare module '@spartacus/checkout/b2b/root' {
  enum B2BPaymentTypeEnum {
    BANK_ACCOUNT_PAYMENT = 'BankAccount',
  }
} (B2BPaymentTypeEnum as any)['BANK_ACCOUNT_PAYMENT'] = 'BankAccount';

declare module '@spartacus/core' {
  enum PageType {
    NEWS_PAGE = 'NewsPage',
  }
} (PageType as any)['NEWS_PAGE'] = 'NewsPage';
