import { Component, HostBinding } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CMSBoxLinkImage } from './custom-box-link-image.model';

@Component({
  selector: 'app-custom-box-link-image',
  templateUrl: './custom-box-link-image.component.html',
  styleUrls: ['./custom-box-link-image.component.scss'],
})
export class CustomBoxLinkImageComponent {
  @HostBinding('class') styleClasses = '';
  @HostBinding('style.background') backgroundColor: string;
  @HostBinding('class.alternative') alternative: boolean;
  addContainer = false;

  data$: Observable<CMSBoxLinkImage> = this.componentData.data$.pipe(
    tap((component: CMSBoxLinkImage) => {
        this.initComponent(component);
      },
    ),
  );

  items$: Observable<any[]> = this.data$.pipe(
    tap(data => {
      this.alternative = (data?.boxLinkImageItem ?? '').split(' ')?.length > 2;
    }),
    switchMap((data: CMSBoxLinkImage) =>
      combineLatest(
        (data?.boxLinkImageItem ?? '').split(' ').map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            distinctUntilChanged(),
          )
        )
      )
    )
  );

  constructor(
    private componentData: CmsComponentData<CMSBoxLinkImage>,
    private cmsService: CmsService,
  ) {
  }

  initComponent(item: CMSBoxLinkImage): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.fullImageWidth === true || item?.fullImageWidth === 'true') {
      this.styleClasses += ' full-page-width';
      this.addContainer = false;
    } else if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
      this.addContainer = true;
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }
}
