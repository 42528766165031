import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageActions, GlobalMessageType, LoggerService, tryNormalizeHttpError, SiteContextActions } from '@spartacus/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CsTicketCauseList } from 'src/app/model/contact-form.model';
import { CustomContactFormConnector } from '../../connectors/custom-contact-form.connector';
import { ContactFormActions } from '../actions';

@Injectable()
export class CustomContactFormEffect {
  constructor(
    private actions$: Actions,
    private contactFormConnector: CustomContactFormConnector,
    private logger: LoggerService
  ) { }

  loadContactForm$: Observable<ContactFormActions.ContactFormAction> = createEffect(() => this.actions$.pipe(
    ofType(ContactFormActions.LOAD_CUSTOM_CONTACT_FORM),
    map((action: any) => action.payload),
    switchMap((payload) => {
      return this.contactFormConnector.loadCsTicketCauses(payload)
        .pipe(
          map((csTicketCauses: CsTicketCauseList) => {
            return new ContactFormActions.LoadCustomContactFormSuccess(csTicketCauses);
          }),
          catchError((error) =>
            of(new ContactFormActions.LoadCustomContactFormFail(tryNormalizeHttpError(error, this.logger)))
          )
        );
    })
  ));

  resetContactForm$: Observable<ContactFormActions.ClearCustomContactForm> = createEffect(() => this.actions$.pipe(
    ofType(SiteContextActions.LANGUAGE_CHANGE),
    map(() => {
      return new ContactFormActions.ClearCustomContactForm();
    })
  ));

  sendContactForm$: Observable<
    | ContactFormActions.CustomSendContactFormSuccess
    | GlobalMessageActions.AddMessage
    | ContactFormActions.CustomSendContactFormFail> = createEffect(() => this.actions$.pipe(
    ofType(ContactFormActions.CUSTOM_SEND_CONTACT_FORM),
    map((action: any) => action.payload),
    mergeMap((payload) => {
      return this.contactFormConnector
        .sendContactForm(payload.form, payload.templateConfiguration)
        .pipe(
          switchMap((data) => [
            new ContactFormActions.CustomSendContactFormSuccess(data),
            new GlobalMessageActions.AddMessage({
              text: { key: 'contactForm.updateSuccess' },
              type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              timeout: 7000,
            }),
          ]),
          catchError((error) =>
            {
              const actions: Array<
              | ContactFormActions.CustomSendContactFormFail
              | GlobalMessageActions.AddMessage
              > = [
                new ContactFormActions.CustomSendContactFormFail(
                  tryNormalizeHttpError(error, this.logger)
                ),
              ];
              if (this.hasModelNotFoundError(error)) {
                actions.push(
                  new GlobalMessageActions.AddMessage({
                    text: { key: 'contactForm.modelNotFoundError'},
                    type: GlobalMessageType.MSG_TYPE_ERROR,
                  })
                );
              } else if (!this.hasErrors) {
                actions.push(
                  new GlobalMessageActions.AddMessage({
                    text: { key: 'contactForm.updateError'},
                    type: GlobalMessageType.MSG_TYPE_ERROR,
                  })
                );
              }
              return from(actions);
            }
          ),
        );
    }),
  ));

  private hasModelNotFoundError(errorData: any): string {
    return errorData?.error?.errors?.some((errorResponse: any) =>
      errorResponse.type === 'ModelNotFoundError'
    );
  }

  private hasErrors(errorData: any): boolean {
    return Boolean(errorData?.error?.errors?.length);
  }
}
