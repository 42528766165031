import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule
} from '@spartacus/core';
import { CustomPaypalButtonModule } from 'src/app/custom/cms-components/checkout/custom-paypal-button/custom-paypal-button.module';
import { CustomPlaceOrderComponent } from './custom-place-order.component';
import { CartNotEmptyGuard, CheckoutAuthGuard, CheckoutPlaceOrderModule } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    CustomPaypalButtonModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: CustomPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomPlaceOrderComponent],
  exports: [CustomPlaceOrderComponent],
})
export class CustomPlaceOrderModule extends CheckoutPlaceOrderModule { }
