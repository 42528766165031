import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tryNormalizeHttpError, UserActions, Address, LoggerService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomUserAddressConnector } from '../../connectors/address/custom-user-address.connector';
import { CustomUserActions } from '../actions';

@Injectable()
export class CustomUserAddressesEffects {

  loadUserAddressesBasestore$: Observable<UserActions.UserAddressesAction> = createEffect(() => this.actions$.pipe(
    ofType(CustomUserActions.CUSTOM_LOAD_USER_ADDRESSES_BASESTORE),
    map((action: CustomUserActions.CustomLoadUserAddressBasestore) => action.payload),
    mergeMap((payload) => {
      return this.userAddressConnector.getAllFromBasestore(payload).pipe(
        map((addresses: Address[]) => {
          return new UserActions.LoadUserAddressesSuccess(addresses);
        }),
        catchError((error) =>
          of(new UserActions.LoadUserAddressesFail(tryNormalizeHttpError(error, this.logger)))
        )
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private userAddressConnector: CustomUserAddressConnector,
    private logger: LoggerService
  ) {}
}

