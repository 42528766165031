import { Router, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CustomEnabledCustomerRegisterGuard  {
  constructor(
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected semanticPathService: SemanticPathService,
    protected router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.customExtraAppConfigService.isEnabledCustomerRegister() ?  true : this.router.parseUrl(this.semanticPathService.get('home')));
  }
}
