import { NgModule, Injector  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSiteContextSelectorComponent } from './custom-site-context-selector.component';
import { CmsConfig, I18nModule, ContextServiceMap, provideConfig, SiteContextModule } from '@spartacus/core';
import { CustomLanguageCurrencyComponent } from './custom-language-currency.component';
import { IconModule, SiteContextComponentService, CmsComponentData } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';





@NgModule({
  declarations: [CustomSiteContextSelectorComponent, CustomLanguageCurrencyComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule, I18nModule,
    SiteContextModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSSiteContextComponent: {
          component: CustomSiteContextSelectorComponent,
          providers: [
            {
              provide: SiteContextComponentService,
              useClass: SiteContextComponentService,
              deps: [CmsComponentData, ContextServiceMap, Injector],
            },
          ],
        },
        LanguageCurrencyComponent: {
          component: CustomLanguageCurrencyComponent,
        },
      },
    }),
    SiteContextComponentService,
  ],
  exports: [CustomSiteContextSelectorComponent, CustomLanguageCurrencyComponent],
})
export class CustomSiteContextSelectorModule { }
