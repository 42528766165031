import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventService, ImageGroup, Product, ProductReferenceService, RoutingService, UserIdService, WindowRef } from '@spartacus/core';
import { CartEvent } from '@spartacus/cart/base/root';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';
import { CustomDeleteCartSuccessEvent } from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart.events';
import { ProductUnitPrice } from "../../../../../../model/unit-price";
import { CustomCurrentProductService } from "../../../../core/product/services/custom-current-product.service";
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-custom-add-to-cart-unit',
  templateUrl: './custom-add-to-cart-unit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAddToCartUnitComponent implements OnDestroy, OnChanges {
  @Input() showQuantity = true;
  @Input() showNostockText = false;
  @Input() displayDefaultUnitOnly = false;
  @Input() showAddToCartText = false;
  @Output() unitChanged: EventEmitter<ProductUnitPrice> = new EventEmitter<ProductUnitPrice>();
  /**
   * As long as we do not support #5026, we require product input, as we need
   *  a reference to the product model to fetch the stock data.
   */
  @Input() product: Product;
  @Input() isPDP: false;

  maxQuantity: number;
  modalRef;

  quantity = 1;
  protected numberOfEntriesBeforeAdd = 0;

  subscriptions: Subscription = new Subscription();

  addToCartForm = new FormGroup({
    quantity: new FormControl(1),
  });

  selectedProductUnit?: ProductUnitPrice;
  productUnitPrices: ProductUnitPrice[] = [];

  outOfStock: boolean = false;

  @ViewChild('open') element: ElementRef;

  constructor(

    protected modalService: LaunchDialogService,
    protected currentProductService: CustomCurrentProductService,
    protected productReferenceService: ProductReferenceService,
    private cd: ChangeDetectorRef,
    protected activeCartService: CustomActiveCartService,
    private routingService: RoutingService,
    protected multiCartService: CustomMultiCartService,
    protected userIdService: UserIdService,
    protected eventService: EventService,
    protected route: ActivatedRoute,
    protected location: Location,
    protected vcr: ViewContainerRef,
    protected windowRef?: WindowRef
  ) {
  }

  ngOnChanges(): void {
    if (this.product) {
      this.setUnitPrices(this.product);
      this.setDefaultSelectedUnitPrice();
    }
  }

  private setUnitPrices(product: Product): void {
    this.productUnitPrices = [];
    const addVariantUnitPrices = this.isCombinedProduct(product);

    if (product?.unitPrices?.length) {
      product.unitPrices
        .filter(unitPrice => (!this.displayDefaultUnitOnly || unitPrice.unit.defaultUnit))
        .forEach(unitPrice => {
          const unitProduct = this.isCombinedProduct(this.product) ? {...product, code: this.product.variantCode} : product;
          this.productUnitPrices.push(<ProductUnitPrice>{product: unitProduct, unitPrice: unitPrice})
        })
    }

    if (this.isPDP || addVariantUnitPrices) {
      product.variantOptions?.forEach(variant => {
        const images:ImageGroup = {}
        variant.variantOptionQualifiers?.forEach(item => {
          if(item.qualifier){images[item.qualifier]=item.image}
        });
        const variantProduct:Product = {...variant, images:{'PRIMARY':images}, name: product.name};
        variant.unitPrices
          .filter(unitPrice => (!this.displayDefaultUnitOnly || unitPrice.unit.defaultUnit))
          .forEach(unitPrice => {
              this.productUnitPrices.push(<ProductUnitPrice>{product: variantProduct, unitPrice: unitPrice})
          })
        });
    }
  }

  private setDefaultSelectedUnitPrice(): void {
    if (this.productUnitPrices.length < 2) {
      this.subscriptions.add(
        this.routingService
          .getRouterState()
          .pipe(
            distinctUntilChanged((prev, curr) => prev.state.queryParams['url'] === curr.state.queryParams['url']),
            withLatestFrom(this.route.paramMap.pipe(
              map((): string | undefined => this.windowRef?.nativeWindow?.history.state)
            )),
            tap(([router, historyState]) => {
              if (this.isMatchingRouterParams(router)) {
                this.selectedProductUnit = this.findSelectedProductUnit(router, historyState);
              } else {
                this.selectedProductUnit = this.productUnitPrices[0];
                for (let productUnitPrice of this.productUnitPrices) {
                  if (this.isMatchingUnitCode(productUnitPrice, historyState) ||
                      productUnitPrice.product.stock.stockLevelStatus !== 'outOfStock')
                  {
                    this.selectedProductUnit = productUnitPrice;
                    this.unitChanged.emit(productUnitPrice);
                    break;
                  }
                }
              }
              this.setStockInfo(this.selectedProductUnit?.product);
              this.cd.markForCheck();
            })
          ).subscribe()
      );
    }
  }

  private isMatchingRouterParams = (router) => {
    return 'item' in router.state.queryParams || 'productCode' in router.state.params
  }

  private isMatchingProductCode = (unitPrice, router, historyState) => {
    const productCode = router.state.queryParams['item'] || router.state.params['productCode'] || historyState['productCode'];
    return unitPrice.product.code === productCode;
  }

  private isMatchingUnitCode = (unitPrice, historyState) => {
    return unitPrice.unitPrice.unit.code === historyState['unit'];
  }

  private findSelectedProductUnit = (router, historyState) => {
    return this.productUnitPrices.find(unitPrice => 
      this.isMatchingProductCode(unitPrice, router, historyState) && this.isMatchingUnitCode(unitPrice, historyState)
    ) || this.productUnitPrices[0];
  }

  selectUnitPrice(unitPrice: ProductUnitPrice): void {
    if(this.isPDP){
      const currentUrl = this.location.path(true).split('?')[0];
      const queryParams = `?item=${unitPrice.product.code}`;
      if(unitPrice.product.principalVariant){
      this.routingService.goByUrl(currentUrl, {onSameUrlNavigation:'reload'});
      } else if(unitPrice.product.principalVariant === false){
        this.routingService.goByUrl(currentUrl+queryParams, {onSameUrlNavigation:'reload'});
      } else {
        this.currentProductService.setProduct(unitPrice.product.code);
      }
    }
    this.selectedProductUnit = unitPrice;
    this.setStockInfo(this.selectedProductUnit.product);
    this.unitChanged.emit(unitPrice);
    this.cd.markForCheck();
  }

  private setStockInfo(product: Product): void {
    this.quantity = 1;
    if (!product?.stock || product.stock.stockLevel === 0 || product.stock.stockLevelStatus === 'outOfStock') {
      this.maxQuantity = 0;
    } else if (product.stock.stockLevel) {
      let conversion = 1;
      if (this.selectedProductUnit?.unitPrice?.unit?.conversion > 1) {
        conversion = this.selectedProductUnit.unitPrice.unit.conversion;
      } else if (this.selectedProductUnit?.unitPrice?.unit?.conversion > 1) {
        conversion = this.selectedProductUnit.unitPrice.unit.conversion;
      }
      this.maxQuantity = Math.floor(product.stock.stockLevel / conversion);
    } else if (product.stock.stockLevelStatus === 'inStock') {
      this.maxQuantity = undefined;
    }
    this.updateOutOfStock();
  }

  updateOutOfStock(): void {
    this.outOfStock = this.quantity <= 0 || this.quantity > this.maxQuantity;
  }

  addToCart(checkGiftBoxInCart: boolean): void {
    const productCode = this.getCombinedProductProductCode(this.selectedProductUnit.product);
    const quantity = this.addToCartForm.get('quantity').value;
    if (!productCode || quantity <= 0) {
      return;
    }
    if (checkGiftBoxInCart) {
      combineLatest([
        this.activeCartService.requireLoadedCart(),
        this.activeCartService.getActive(),
        this.activeCartService.isStable(),
      ]).pipe(
        filter(([, _, loaded]) => loaded),
        take(1)
      )
        .subscribe(([, activeCart, _]) => {
          if (activeCart.giftBoxProduct) { // Cart is giftbox
            this.openGiftBoxModal(quantity);
          } else { // Cart is not empty and is not a giftbox
            this.addEntryToCart(quantity);
          }
        });
    } else {
      this.addEntryToCart(quantity);
    }
  }

  private addEntryToCart(quantity: number): void {
    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((entries) => {
        const productCode = this.getCombinedProductProductCode(this.selectedProductUnit.product);
        this.numberOfEntriesBeforeAdd = entries.length;
        this.activeCartService.addEntryUnit(
          productCode,
          quantity,
          this.selectedProductUnit.unitPrice.unit.code
        );
        this.openAddedToCartModal();
      });
  }

  private openAddedToCartModal(): void {
    const productCode = this.getCombinedProductProductCode(this.selectedProductUnit.product);

    //CustomAddedToCartDialogComponent
    const dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ADDED_TO_CART,
      undefined,
      this.vcr,
      {
        productCode: productCode,
        selectedProductUnit: this.selectedProductUnit,
        quantity: this.quantity,
        numberOfEntriesBeforeAdd: this.numberOfEntriesBeforeAdd
      });

    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }

    this.activeCartService.isStable()
      .pipe(
        tap((loaded) => {
          if (this.displayDefaultUnitOnly && loaded) {
            this.modalService.closeDialog("displayDefaultUnitOnly");
          }
        })
      );
  }

  private openGiftBoxModal(quantity: number): void {
    //CustomAddedToCartDialogComponent
    const dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ADDED_TO_CART_GIFT_BOX,
      undefined,
      this.vcr);
      if (dialog) {
        this.subscriptions.add(dialog.subscribe());
      }

    this.modalRef.result
      .then((isAccepted: boolean) => {// Remove gift box cart
        if (isAccepted) {
          let userId;
          this.userIdService
            .getUserId()
            .subscribe((occUserId) => (userId = occUserId))
            .unsubscribe();
          let cartId;
          this.activeCartService
            .getActiveCartId()
            .subscribe((activeCartId) => (cartId = activeCartId))
            .unsubscribe();
          this.eventService.get(CartEvent)
            .pipe(take(1))
            .subscribe((event) => {
              if (event instanceof CustomDeleteCartSuccessEvent) {
                combineLatest([
                  this.activeCartService.getActive(),
                  this.activeCartService.isStable(),
                ]).pipe(
                  filter(([_, loaded]) => loaded),
                  take(1)
                )
                  .subscribe(() => this.addEntryToCart(quantity));
              }
            });
          this.multiCartService.deleteCart(cartId, userId);
        } else { // Redirect to gift box wizard
          this.routingService.go({cxRoute: 'giftboxWizard'});
        }
      })
      .catch(() => {
      });
  }


  isCombinedProduct(product: Product): boolean {
    return product.variantCode && product.variantCode !== product.code;
  }

  getCombinedProductProductCode(product: Product): string {
    if(this.isCombinedProduct(product)) {
      return product.variantCode;
    }
    return product.code;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
