import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNavigationUiComponent } from './custom-navigation-ui.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { IconModule, NavigationService } from '@spartacus/storefront';
import { CustomNavigationComponent } from './custom-navigation.component';
import { CustomNavigationService } from './custom-navigation.service';
import { CustomGenericLinkModule } from '../../../shared/components/custom-generic-link/custom-generic-link.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    CustomGenericLinkModule,
    I18nModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NavigationComponent: {
          component: CustomNavigationComponent,
        },
        NavigationUIComponent: {
          component: CustomNavigationUiComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomNavigationComponent, CustomNavigationUiComponent],
  exports: [CustomNavigationComponent, CustomNavigationUiComponent],
  providers: [
    {
      provide: NavigationService,
      useClass: CustomNavigationService,
    },
  ],
})
export class CustomNavigationModule {}
