export const commonFrench = {
    fr: { // lang
      common: {
        carousel: {
          product: {
            btn: 'Voir plus',
          },
          previousSlide: 'Diapositive précédente',
          nextSlide: 'Diapositive suivante',
        },
        common: {
          invalidEmailErrorMessage: "L'email n'est pas valide!",
          validateDocIdentifierAndTotalAmount: "Pour les commandes supérieures à 3 000 € il est obligatoire d'ajouter un NIF.",
          cancel: 'Annuler',
          delete: 'Effacer',
          remove: 'Supprimer',
          edit: 'Modifier',
          back: 'Retour',
          submit: 'Envoyer',
          continue: 'Continuer',
          save: 'Enregistrer',
          done: 'Réalisé',
          home: 'Accueil',
          noData: 'Sans informations',
          confirm: 'Confirmer',
          more: 'Plus',
          close: 'Fermer',
          accept: 'Accepter',
          readMore: 'Lire plus',
          tiendaOnline: 'boutique-online',
          checkout: 'Paiement Annulé',
          menu: 'Menu',
          reset: 'Remise à zéro',
          search: 'Recherche',
          loaded: 'Chargé',
        },
        pageMetaResolver: {
          category: {
            title: '{{count}} résultat pour {{query}}',
            title_plural: '{{count}} résultat pour {{query}}',
          },
          checkout: {
            title: 'Vérifier {{count}} article',
            title_plural: 'Vérifier {{count}} articles',
          },
          search: {
            title: '{{count}} articles',
            title_plural: '{{count}} résultats pour "{{query}}"',
            findProductTitle: '{{count}} résultat pour le coupon "{{coupon}}"',
            findProductTitle_plural: '{{count}} résultats pour le coupon "{{coupon}}"',
            empty: 'La recherche n’a donné aucun résultat pour {{ freeTextSearch }}.',
          },
          product: {
            description: '{{description}}',
            heading: '{{heading}}',
            title: '{{title}}',
          },
        },
        spinner: {
          loading: 'Chargement en cours...',
        },
        navigation: {
          shopAll: 'Tout voir {{ navNode}} >',
        },
        searchBox: {
          placeholder: 'Rechercher',
          help: {
            insufficientChars: 'Veuillez écrire plus de caractères',
            noMatch: 'Aucun résultat n’a été trouvé',
            exactMatch: '{{ term }}',
            empty: 'Demandez-nous',
          },
          ariaLabelSuggestions: 'suggestions d´écriture',
          productSearch: 'Trouver un produit',
          initialDescription:
      'Lorsque des résultats autocomplets sont disponibles, utilisez les flèches vers le haut et vers le bas pour passer en revue et entrez pour sélectionner. Pour les utilisateurs d´appareils tactiles, l´exploration se fait par le toucher ou par des gestes de balayage.',
        },
        sorting: {
          date: 'Date',
          orderNumber: 'Numéro de commande',
          rma: 'Numéro de retour',
          replenishmentNumber: 'Numéro de réapprovisionnement',
          nextOrderDate: 'Date de la prochaine commande',
          billDate: 'Date de facture',
          billDueDate: 'Date d’échéance',
          byCodeAsc: 'N° facture ascendante',
          byCodeDesc: 'N° facture descendante',
          byDateAsc: 'Date de facture ascendante',
          byDateDesc: 'Date de facture descendante',
          byDueDateAsc: 'Date d’échéance ascendante',
          byDueDateDesc: 'Date d’échéance descendante',
          newsDate: 'Date de l’information*',
          newsTitle: 'Titre de l’information*',
        },
        httpHandlers: {
          badRequest: {
            bad_credentials: 'Données incorrectes. Veuillez démarrer une nouvelle session.',
            el_usuario_está_deshabilitado: 'Utilisateur bloqué, pour réinitialiser votre compte, récupérer votre mot de passe et une fois modifié, reconnectez-vous. On peut vous aider également ici: sac_web@osborne.es',
            user_is_disabled: 'Utilisateur bloqué, pour réinitialiser votre compte, récupérer votre mot de passe et une fois modifié, reconnectez-vous. On peut vous aider également ici: sac_web@osborne.es',
            le_compte_utilisateur_est_désactivé: 'Utilisateur bloqué, pour réinitialiser votre compte, récupérer votre mot de passe et une fois modifié, reconnectez-vous. On peut vous aider également ici: sac_web@osborne.es',
            usuário_desabilitado: 'Utilisateur bloqué, pour réinitialiser votre compte, récupérer votre mot de passe et une fois modifié, reconnectez-vous. On peut vous aider également ici: sac_web@osborne.es.'
          },
          badGateway: 'Une erreur s’est produite sur le serveur. Merci de réessayer plus tard.',
          badRequestPleaseLoginAgain: 'Données incorrectes. Veuillez démarrer une nouvelle session.',
          badRequestOldPasswordIncorrect: 'Ancien mot de passe incorrect.',
          badRequestGuestDuplicateEmail:
            'Si vous avez déjà un compte chez nous, veuillez vous connecter ou récupérer votre mot de passe pour participer. Nous pouvons également vous aider à sac_web@osborne.es',
          conflict: 'Existe déjà.',
          forbidden:
            'No está autorizado para realizar esta acción. Por favor, póngase en contacto con su administrador si cree que esto es un error.',
          gatewayTimeout: 'Le serveur ne répond pas. Veuillez réessayer plus tard.',
          internalServerError:
            'Se ha producido un error interno del servidor. Por favor, inténtelo de nuevo más tarde.',
          sessionExpired: 'Votre session a expiré. Veuillez entrer de nouveau sur le compte.',
          unknownError: 'Une erreur inconnue s’est produite',
          unauthorized: {
            common: 'Une erreur inconnue d’autorisation s’est produite',
            invalid_client: 'Données incorrectes',
          },
          forbiddenConsentTemplates: 'Veuillez vous déconnecter pour vous inscrire correctement.',
          validationErrors: {
            missing: {
              bankAccountNumber: 'Le champ IBAN est obligatoire',
              card_cardType:
                'La tarjeta de crédito seleccionada no es compatible. Por favor, seleccione otra.',
              card_accountNumber: 'Le numéro de carte de crédit indiqué est incorrect.',
              card_cvNumber: 'Le code de sécurité indiqué est incorrect.',
              card_expirationMonth:
                'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
              card_expirationYear: 'La date d’expiration de la carte de crédit indiquée est incorrecte.',
              billTo_firstName: 'Le prénom indiqué est incorrect.',
              billTo_lastName: 'Le nom de famille indiqué est incorrect.',
              billTo_street1: 'L’adresse indiquée est incorrecte.',
              billTo_street2: 'L’adresse indiquée est incorrecte.',
              billTo_city: 'La ville indiquée est incorrecte pour cette carte de crédit.',
              billTo_state: 'L’état/province indiqué est incorrect pour cette carte de crédit.',
              billTo_country: 'Le pays indiqué est incorrect pour cette carte de crédit.',
              billTo_postalCode: 'Le code postal est incorrect pour cette carte de crédit.',
              country: {
                isocode: 'Pays manquant',
              },
              firstName: 'Le prénom est obligatoire',
              lastName: 'Le nom de famille est obligatoire',
              password: 'Le mot de passe est obligatoire',
              firstNameLastName: 'Le prénom et le nom de famille sont obligatoires',
              uid: 'L’e-mail est obligatoire',
              code: 'Le code est obligatoire',
              email: 'L’e-mail est obligatoire',
              iban: 'Le champ IBAN est obligatoire',
              documentIdentifier: 'Le champ NIF est obligatoire',
              postalCode: 'Le champ code postal est obligatoire',
              defaultPaymentAddress: {
                phone: 'Le champ code postal est obligatoire',
                firstName: 'Le champ Prénom est obligatoire',
                lastName: 'Le champ Nom de famille est obligatoire',
                password: 'Le champ Mot de passe est obligatoire',
                line1: 'Le champ Adresse est obligatoire',
                town: 'Le champ Ville est obligatoire',
                postalCode: 'Le champ Code postal est obligatoire',
                country: {
                  isocode: 'Le champ pays est obligatoire',
                },
                toCustomersUid: 'Adresse e-mail du client manquante',
              },
              newUid: 'Vérifier les informations de l’utilisateur',
              message: 'Le message est obligatoire',
              subject: 'L’objet est obligatoire',
              orderCode: 'Le numéro de commande est obligatoire',
              productsAffected: 'Le champ Produits et Quantités affectées est obligatoire',
              qualityProblem: 'Le champ Problème de qualité est obligatoire',
              requiredAction: 'Le champ Action requise est obligatoire',
            },
            invalid: {
              subject: 'L’objet indiqué est incorrect.',
              message: 'Le message indiqué est incorrect.',
              bankAccountNumber: 'Le champ IBAN est incorrect',
              card_expirationMonth:
                'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
              firstName: 'Le prénom indiqué est incorrect.',
              lastName: 'Le nom de famille indiqué est incorrect.',
              firstNameLastName: 'Le prénom et nom de famille indiqué sont incorrects',
              password: 'Le mot de passe indiqué est incorrect. Il doit contenir au minimum six caractères, une lettre majuscule, un chiffre et un symbole.',
              uid: 'L’e-mail est incorrect.',
              code: 'Le code est incorrect.',
              email: 'L’e-mail est incorrect.',
              iban: 'Le champ IBAN est incorrect',
              sessionCart: 'Les informations de paiement n’ont pas été configurées.',
              documentIdentifier: 'Le NIF est incorrect.',
              postalCode: 'Le code postal est incorrect',
              defaultPaymentAddress: {
                phone: 'Le champ Code postal est incorrect',
                firstName: 'Le champ Prénom est incorrect',
                lastName: 'Le champ Nom de famille est incorrect',
                password: 'Le champ Mot de passe est incorrect',
                line1: 'Le champ Adresse est incorrect',
                town: 'Le champ Ville est incorrect',
                postalCode: 'Le champ Code est incorrect',
                country: {
                  isocode: 'Le champ Pays est incorrect',
                },
              },
            },
          },
          CartError: {
            CartError: 'Erreur lors de la simulation de l’ordre',
          },
          cartModification: {
            giftBoxError: {
              label: 'Le minimum de produits requis pour la boîte cadeau n’a pas été atteint.',
              entries: '',
            },
            lowStock: {
              label: '',
              entries: 'Le produit suivant a été ajusté à {{quantity}} unités en raison du manque de stock disponible: {{product}}.',
            },
            noStock: {
              label: 'Le produit suivant a été supprimé du panier faute de stock disponible:',
              entries: '{{product}}.',
            },
            unavailable: {
              label: 'Les articles non disponibles pour cette destination ont été supprimés:',
              entries: '{{product}}.',
            },
          },
          cartNotFound: 'Panier non trouvé.',
          invalidCodeProvided: 'Code non valable.',
          notActiveCodeProvided: 'Code non valable actuellement.',
          voucherExceeded: '{{voucherCode}}'
        },
        miniCart: {
          item: '{{count}} article dans votre panier',
          item_plural: '{{count}} articles dans votre panier',
          total: '{{total}}',
          count: '{{count}}',
          preview: {
            empty: 'VOUS N’AVEZ AUCUN ARTICLE DANS VOTRE PANIER.',
            proceedToCheckout: 'Gérer commande',
            viewCart: 'Voir et modifier panier',
          },
        },
        // deprecated since 3.2, moved to @spartacus/user/account/assets
        miniLogin: {
          userGreeting: 'Bonjour {{name}}',
          signInRegister: 'Démarrer session / Identification',
        },
        skipLink: {
          skipTo: 'Aller à',
          labels: {
            header: 'En-tête',
            main: 'Contenu principal',
            footer: 'Pied de page',
            productFacets: 'Facettes du produit',
            productList: 'Liste de produits',
          },
        },
        formErrors: {
          nifError :'Le nombre et les appels ne coïncident pas avec votre NIF. Modifíquelos en la sección “Mes informations” et cliquez pour continuer nouveau.',
          globalMessage: 'Le formulaire que vous essayez d’envoyer contient des erreurs.',
          required: 'Ce champ est obligatoire',
          cxInvalidEmail: 'Le format de l’e-mail est incorrect',
          cxInvalidPassword: 'Le mot de passe doit contenir au minimum six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole.',
          cxPasswordsMustMatch: 'Les champs du mot de passe doivent être identiques',
          cxEmailsMustMatch: 'Les champs de l’e-mail doivent être identiques',
          cxStarRatingEmpty: 'Le champ de qualification est obligatoire',
          cxNoSelectedItemToCancel: 'Sélectionnez au moins un élément',
          cxNegativeAmount: 'Le montant doit être égal ou supérieur à zéro',
          cxContainsSpecialCharacters: 'Le champ ne peut pas contenir de caractères spéciaux',
          cxIban: 'Le champ IBAN est incorrect',
          cxNif: 'Le champ NIF est incorrect pour le pays sélectionné',
          cxCif: 'Le champ NIF est incorrect pour le pays sélectionné. Vous pouvez uniquement indiquer un NIF d’une personne physique',
          cxPostalCode: 'Le code postal est incorrect pour la zone – le pays sélectionné',
          email: 'Le format de l’e-mail est incorrect',
          maxlength: 'Vous avez inséré plus de caractères que ceux autorisés',
          maxlengthWithNumber: 'Ce champ doit contenir {{maxLength}} caractères maximum',
          combinedMaxlength: '{{ firstControl }} et {{ secondControl }} combinés doivent avoir au maximum {{maxLength}} caractères (actuellement {{actualLength}})',
          date: {
            required: 'Ce champ est obligatoire',
            min: 'La date ne peut pas être antérieure à {{min}}',
            max: 'La date ne peut pas être postérieure à {{max}}',
            pattern: 'Utilisez le format de date aaaa-mm-jj',
            invalid: 'Utilisez une date valide',
          },
          minlength: 'Ce champ doit contenir au moins {{minLength}} caractères',
          min: 'Minimum non atteint',
        },
        errorHandlers: {
          scriptFailedToLoad: 'Erreur lors du chargement du script.',
          refreshThePage: 'Veuillez rafraîchir la page.',
        },
      },
      shipping: {
        shipping: {
          headerText: {
            destiny: 'destination',
          },
        },
      },
      userProfile: {
        updateEmailForm: {
          newEmailAddress: {
            label: 'Nouvel e-mail',
            placeholder: 'Indiquer l’e-mail',
          },
          confirmNewEmailAddress: {
            label: 'Confirmer le nouvel e-mail',
            placeholder: 'Indiquer l’e-mail',
          },
          enterValidEmail: 'Veuillez indiquer un e-mail valide.',
          bothEmailMustMatch: 'Les deux e-mails doivent être identiques',
          password: {
            label: 'Mot de passe',
            placeholder: 'Indiquer le mot de passe',
          },
          pleaseInputPassword: 'Veuillez indiquer le mot de passe',
          emailUpdateSuccess: 'Opération réalisée avec succès. Démarrez la session avec {{ newUid }}',
        },
        register: {
          contactInfo: 'INFORMATIONS PERSONNELLES',
          addressInfo: 'INFORMATIONS POUR DÉMARRER VOTRE SESSION',
          confirmPassword: {
            action: 'Confirmer le mot de passe',
            label: 'Confirmer le mot de passe',
            placeholder: 'Confirmer le mot de passe',
          },
          managementInMyAccount: 'Gestion sur Mon compte.',
          termsAndConditions: 'Termes et conditions',
          signIn: 'J’ai déjà un compte. Démarrer la session',
          register: 'Créer un compte',
          confirmNewPassword: 'Confirmer le mot de passe',
          resetPassword: 'Modifier le mot de passe',
          createAccount: 'Créer un compte',
          title: 'Titre',
          name: {
            label: 'Prénom et nom de famille',
            placeholder: 'Prénom et nom de famille',
          },
          firstName: {
            label: 'Prénom',
            placeholder: 'Prénom',
          },
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
          },
          emailAddress: {
            label: 'Adresse e-mail',
            placeholder: 'Indiquez une adresse e-mail valide',
          },
          password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
          },
          newPassword: 'Nouveau mot de passe',
          emailMarketing:
            'Utiliser mes données personnelles pour recevoir des informations par e-mail pour des campagnes de marketing. Pour modifier la configuration, aller à Gestion du Consentement dans Mon Compte.',
          confirmThatRead: 'J’ACCEPTE LE TRAITEMENT DE MES DONNÉES PERSONNELLES POUR LES ACTIVITÉS INDIQUÉES CI-APRÈS',
          selectTitle: 'Sélectionner titre',
          passwordMinRequirements:
            'Le mot de passe doit contenir un minimum de six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole',
          bothPasswordMustMatch: 'Les deux mots de passes doivent être identiques',
          titleRequired: 'Un titre est requis.',
          postRegisterMessage: 'Veuillez démarrer la session avec les données fournies.',
          newsletter: "J'ACCEPTE DE M'ABONNER À LA NEWSLETTER",
        },
        forgottenPassword: {
          resetPassword: 'Récupérer le mot de passe',
          resetButton: 'Récupérer',
          sendResetPassword: 'Modifier le mot de passe',
          enterEmailAddressAssociatedWithYourAccount: 'Indiquer le mot de passe associé à votre compte',
          emailAddress: {
            label: 'E-mail',
            placeholder: 'E-mail',
          },
          enterValidEmail: 'Veuillez indiquer un e-mail valide.',
          passwordResetEmailSent: 'Si un compte existe avec l\'e-mail fourni, vous recevrez un e-mail pour réinitialiser votre mot de passe.',
          passwordResetSuccess: 'Opération réalisée avec succès ! Vous pouvez démarrer la session avec votre nouveau mot de passe.',
          TokenInvalidatedError: 'Le lien utilisé est incorrect. Si vous avez fait plusieurs tentatives, vérifiez que vous utilisez bien le lien du denier e-mail reçu. Dans le cas contraire, faites une nouvelle demande.',
          IllegalArgumentError: 'Le lien a expiré',
        },
      },
      myFriends: {
        myFriends: {
          goBack: 'Retour à mes amis',
          noSufficientPermissions: 'Vous n’avez pas les autorisations suffisantes',
          friendCreated: 'Ami créé avec succès',
          friendCreatedInfo: 'L’ami va être enregistré dans le système dans le système, vous pourrez le sélectionner dans Vérifier',
          friendUpdate: 'Ami mis à jour avec succès',
          friendsNoLoaded: 'Les amis n’ont pas pu être enregistrés',
          friendsNoCreated: 'L’ami n’a pas pu être créé',
          friendsNoUpdate: 'L’ami n’a pas pu être mis à jour',
          invalidToken: 'Le lien a expiré ou a déjà été utilisé',
          friendRemoved: 'Ami supprimé avec succès',
          inviteCreated: 'Ami invité',
          inviteNoCreated: 'L’ami n’a pas pu être invité',
          search: 'Rechercher par nom ou e-mail',
          label: 'Mes amis',
          add: 'Ajouter ami',
          edit: 'Modifier ami',
          deleteFriend: 'Supprimer ami',
          return: 'Retour',
          saveData: 'Enregistrer données',
          contactInfo: 'Informations de contact',
          guest: 'Invité',
          registered: 'Invité enregistré',
          notInvited: 'Non invité',
          expired: 'Expiré',
          unknownEmail: 'Sans e-mail',
          allStatus: 'Tous les états',
          invited: 'Invité',
          inviteFriend: 'Retourner à inviter ami',
          filterTextSearch: 'Filtrer',
          filterInviteStatus: 'Filtrer état invitation',
          filterRemove: 'Effacer filtre',
          filterInviteStatusPlaceholder: 'Sans filtrer',
          notFound: 'Aucun ami n’a été trouvé',
          myFriendsForm: {
            name: 'Prénom et nom de famille',
            email: 'Adresse e-mail',
            sendInvitation: 'Envoyer invitation',
            confirmPassword: 'Confirmer Nouveau mot de passe',
            password: 'Mot de passe',
            invitationStatus: 'État invitation',
            expired: 'Expirée',
            invited: 'Invité',
            billingData: 'Informations de facturation',
            iban: 'IBAN',
            nif: 'NIF',
            yes: 'Oui',
            no: 'Non',
            phoneNumber: 'Numéro de téléphone',
            personalMessage: 'Message personnel à inclure dans le courrier d’invitation',
            personalMessagePlaceholder:
              'Te adjunto invitación personal para que puedas acceder a nuestra web de amigos',
          },
          missingFriend: 'Ami non trouvé',
          sendEmail: 'Envoyer courrier',
          sendEmailTooltip: 'Sélectionnez un ami pour accéder à cette fonction',
          emails: {
            form: {
              header: 'Envoyer courriers',
              cc: 'CC',
              subject: 'Objet',
              subjectPlaceholder: 'Objet',
              submit: 'Envoyer courrier',
              productSearch: 'Rechercher Produits et Catégories',
              productSearchPlaceholder: 'Nom du Produit ou de la Catégorie...',
              placeholder: 'Rédiger message',
            },
            success: 'E-mail envoyé avec succès',
            errors: {
              toCustomersUid: 'Les adresses e-mail suivantes n’ont pas été trouvées',
            },
          },
          ReferrerUnchangedError: 'L’ami existe déjà dans votre liste d’amis',
          ReferrerAlreadySetError: 'L’ami existe déjà dans la liste d’amis d’un autre utilisateur',
          FriendNotOrphanError: 'L’ami n’est pas sur votre liste d’amis mais est déjà enregistré avec un autre utilisateur',
          ModelSavingError: 'L’ami que vous essayez d’inscrire est déjà inscrit',
        },
      },
      CustomVideoInfoComponent: {
        CustomVideoInfoComponent: {
          buttonText: 'REGARDER VIDÉO',
        },
      },
      user: {
        anonymousConsents: {
          preferences: 'Préférences de consentement',
          dialog: {
            title: 'Gestion de consentement',
            legalDescription:
              'Usamos las cookies almacenadas en el navegador para personalizar el contenido y mejorar la experiencia de usuario. También podemos compartir los datos sobre el uso de su sitio con nuestras redes sociales. Para obtener más información, consulte nuestra política de privacidad.',
            selectAll: 'Tout sélectionner',
            clearAll: 'Tout effacer',
          },
          banner: {
            title: '',
            description:
              'Utilizamos cookies propias y de terceros para mejorar nuestros servicios, realizar análisis estadísticos sobre los hábitos de navegación de nuestros usuarios y facilitar la interacción con redes sociales. Si continúas navegando, consideramos que aceptas su uso.',
            allowAll: 'Tout autoriser',
            viewDetails: 'Voir détails',
          },
        },
        forgottenPassword: {
          resetPassword: 'Récupérer mot de passe',
          resetButton: 'Récupérer',
          enterEmailAddressAssociatedWithYourAccount: 'Indiquer le mot de passe associé à votre compte',
          emailAddress: {
            label: 'Utilisateur',
            placeholder: 'Utilisateur',
          },
          enterValidEmail: 'Veuillez indiquer un e-mail valide.',
          passwordResetEmailSent: 'Si un compte existe avec l\'e-mail fourni, vous recevrez un e-mail pour réinitialiser votre mot de passe.',
          passwordResetSuccess: 'Opération réalisée avec succès ! Vous pouvez démarrer la session avec votre nouveau mot de passe.',
        },
// deprecated
        loginForm: {
          forgotPassword: 'Récupérer mot de passe',
          signIn: 'Accéder',
          register: 'S’identifier',
          dontHaveAccount: 'Vous n’avez pas de compte ?',
          guestCheckout: 'Vérifier invité',
          access: 'Accéder',
          accept: 'Accepter',
          chooseShipping: 'Sélectionnez la livraison',
          formShipping: {
            homeDelivery: {
              label: 'Envoi à domicile',
              placeholder: '',
            },
            pickUpWarehouse: {
              label: 'Récupérer en magasin',
              placeholder: 'Sélectionnez un magasin',
            },
          },
          cartModifications: 'En changeant de destination, il est possible que certains produits ne soient pas disponibles et disparaissent du panier',
          emailAddress: {
            label: 'Utilisateur',
            placeholder: 'Utilisateur',
          },
          password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
          },
          wrongEmailFormat: 'Format d’e-mail non valide',
        },
// deprecated
        register: {
          confirmPassword: {
            action: 'Confirmez le mot de passe',
            label: 'Confirmez le mot de passe',
            placeholder: 'Confirmez le mot de passe',
          },
          managementInMyAccount: 'Gestion sur Mon compte.',
          termsAndConditions: 'Termes et conditions',
          signIn: 'J’ai déjà un compte. Démarrer la session',
          register: 'Se connecter',
          confirmNewPassword: 'Confirmer Nouveau mot de passe',
          resetPassword: 'Réinitialiser Mot de passe',
          createAccount: 'Créer un compte',
          title: 'Titre',
          firstName: {
            label: 'Prénom',
            placeholder: 'Prénom',
          },
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
          },
          emailAddress: {
            label: 'E-mail',
            placeholder: 'E-mail',
          },
          password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
          },
          newPassword: 'Nouveau mot de passe',
          emailMarketing:
            'Utiliser mes données personnelles pour recevoir des informations par e-mail pour des campagnes de marketing. Pour modifier la configuration, aller à Gestion du Consentement dans Mon Compte.',
          confirmThatRead: 'Je confirme avoir lu et être d’accord avec le',
          selectTitle: 'Sélectionner Titre',
          passwordMinRequirements:
            'Le mot de passe doit contenir un minimum de six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole',
          bothPasswordMustMatch: 'Les deux mots de passes doivent être identiques',
          titleRequired: 'Un titre est requis.',
          postRegisterMessage: 'Veuillez démarrer la session avec les données fournies.',
        },
        checkoutLogin: {
          title: 'Achat en tant qu’invité',
          info: 'Si vous le souhaitez, vous pourrez créer un compte plus tard.',
          emailAddress: {
            label: 'Adresse e-mail',
            placeholder: 'Indiquez une adresse e-mail valide',
          },
          confirmEmail: {
            label: 'Confirmez l’adresse e-mail',
            placeholder: 'Confirmez l’adresse e-mail',
          },
          continue: 'Continuer',
          emailIsRequired: 'Format d’adresse e-mail non valide',
          emailsMustMatch: 'L’adresse e-mail n’est pas la même',
        },
        authMessages: {
          signedOutSuccessfully: 'Vous vous êtes déconnecté avec succès.',
        }
      },
      userAccount: {
        anonymousConsents: {
          preferences: 'Préférences de consentement',
          dialog: {
            title: 'Gestion de consentement',
            legalDescription:
              'Usamos las cookies almacenadas en el navegador para personalizar el contenido y mejorar la experiencia de usuario. También podemos compartir los datos sobre el uso de su sitio con nuestras redes sociales. Para obtener más información, consulte nuestra política de privacidad.',
            selectAll: 'Tout sélectionner',
            clearAll: 'Tout effacer',
          },
          banner: {
            title: '',
            description:
              'Utilizamos cookies propias y de terceros para mejorar nuestros servicios, realizar análisis estadísticos sobre los hábitos de navegación de nuestros usuarios y facilitar la interacción con redes sociales. Si continúas navegando, consideramos que aceptas su uso.',
            allowAll: 'Tout autoriser',
            viewDetails: 'Voir détails',
          },
        },
        forgottenPassword: {
          resetPassword: 'Récupérer mot de passe',
          resetButton: 'Récupérer',
          enterEmailAddressAssociatedWithYourAccount: 'Indiquez le mot de passe associé à votre compte',
          emailAddress: {
            label: 'Utilisateur',
            placeholder: 'Utilisateur',
          },
          enterValidEmail: 'Veuillez indiquer un e-mail valide.',
          passwordResetEmailSent: 'Si un compte existe avec l’e-mail fourni, vous recevrez un e-mail pour réinitialiser votre mot de passe.',
          passwordResetSuccess: 'Opération réalisée avec succès! Vous pouvez démarrer la session avec votre nouveau mot de passe.',
        },
// deprecated
        loginForm: {
          forgotPassword: 'Récupérer mot de passe',
          forgotPasswordLong: 'Vous avez oublié votre mot de passe ?',
          signIn: 'Accéder',
          signInLong: 'Démarrer la session',
          register: 'Se connecter',
          registerLong: 'Créer un compte',
          registerTitle: 'Nouveaux clients',
          registerInfo: 'Créer un compte offre de nombreux avantages : paiement plus rapide, enregistrement de plus d’une adresse, suivi de commandes et bien plus encore.',
          dontHaveAccount: 'Vous n’avez pas de compte ?',
          guestCheckout: 'Vérifier invité',
          access: 'Accéder',
          accessLong: 'Clients enregistrés',
          accessInfo: 'Si vous avez un compte, démarrez une session avec votre adresse e-mail.',
          accept: 'Accepter',
          chooseShipping: 'Sélectionnez la livraison',
          formShipping: {
            homeDelivery: {
              label: 'Envoi à domicile',
              placeholder: '',
            },
            pickUpWarehouse: {
              label: 'Récupérer en magasin',
              placeholder: 'Sélectionnez un magasin',
            },
          },
          cartModifications: 'En changeant de destination, il est possible que certains produits ne soient pas disponibles et disparaissent du panier',
          emailAddress: {
            label: 'Utilisateur',
            labelLong: 'Adresse e-mail',
            placeholder: 'Utilisateur',
          },
          password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
          },
          wrongEmailFormat: 'Format d’e-mail non valide',
          ageStatus: {
            text: 'NOUS VOULONS NOUS ASSURER QUE NOS MARQUES SONT CONSOMMÉES DE FAÇON RESPONSABLE. VEUILLEZ CONFIRMER QUE VOUS ÊTRES MAJEUR AVANT D’ENTRER SUR NOTRE SITE.',
            invalidAge: 'NOUS SOMMES DÉSOLÉS MAIS...<br/> VOUS DEVEZ ÊTRE MAJEUR POUR CONTINUER.',
            overEighteen: 'Plus de 18 ans',
            underEighteen: 'Moins de 18 ans'
          }
        },
// deprecated
        register: {
          confirmPassword: {
            action: 'Confirmez le mot de passe',
            label: 'Confirmez le mot de passe',
            placeholder: 'Confirmez le mot de passe',
          },
          managementInMyAccount: 'Gestion sur Mon compte.',
          termsAndConditions: 'Termes et conditions',
          signIn: 'J’ai déjà un compte. Démarrer la session',
          register: 'Se connecter',
          confirmNewPassword: 'Confirmer le nouveau mot de passe',
          resetPassword: 'Réinitialiser le mot de passe',
          createAccount: 'Créer un compte',
          title: 'Titre',
          firstName: {
            label: 'Prénom',
            placeholder: 'Prénom',
          },
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
          },
          emailAddress: {
            label: 'E-mail',
            placeholder: 'E-mail',
          },
          password: {
            label: 'Mot de passe',
            placeholder: 'Mot de passe',
          },
          newPassword: 'Nouveau mot de passe',
          emailMarketing:
            'Utiliser mes données personnelles pour recevoir des informations par e-mail pour des campagnes de marketing. Pour modifier la configuration, aller à Gestion du Consentement dans Mon Compte.',
          confirmThatRead: 'Je confirme avoir lu et être d’accord avec le',
          selectTitle: 'Sélectionner Titre',
          passwordMinRequirements:
            'Le mot de passe doit contenir un minimum de six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole',
          bothPasswordMustMatch: 'Les deux mots de passes doivent être identiques',
          titleRequired: 'Un titre est requis.',
          postRegisterMessage: 'Veuillez démarrer la session avec les données fournies.',
        },
        checkoutLogin: {
          title: 'Achats en tant qu’invité',
          info: 'Si vous le souhaitez, vous pourrez créer un compte plus tard.',
          emailAddress: {
            label: 'Adresse e-mail',
            placeholder: 'Indiquez une adresse e-mail valide',
          },
          confirmEmail: {
            label: 'Confirmez l’adresse e-mail',
            placeholder: 'Confirmez l’adresse e-mail',
          },
          continue: 'Continuer',
          emailIsRequired: 'Format d’adresse e-mail non valide',
          emailsMustMatch: 'L’adresse e-mail n’est pas la même',
        },
        miniLogin: {
          userGreeting: 'Bonjour {{name}}',
          signInRegister: 'Démarrer la session',
        },
      },
      pwa: {
        pwa: {
          addToHomeScreenDescription:
            'Añada Osborne a la pantalla de inicio de su dispositivo para volver a visitarlo más rápidamente',
          noInstallationNeeded: 'Aucune installation n’est requise',
          fastAccessToApplication: 'Accès rapide à l’application',
          addToHomeScreen: 'Ajouter à la page d’accueil',
          addedToHomeScreen: 'Osborne a été ajouté à votre page d’accueil',
        },
      },
      address: {
        addressForm: {
          title: 'Titre',
          firstName: {
            label: 'Prénom',
            placeholder: 'Prénom',
          },
          observations: 'Observations d’envoi',
          alias: 'Alias',
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
          },
          address1: 'Adresse',
          address2: 'Adresse ligne 2 (optionnel)',
          country: 'Zone - pays',
          province: 'Province',
          city: {
            label: 'Ville',
            placeholder: 'Ville',
          },
          state: 'Province',
          zipCode: {
            label: 'Code postal',
            placeholder: 'Code postal',
          },
          phoneNumber: {
            label: 'Numéro de téléphone',
            placeholder: '#ERREUR !',
            note: '(de préférence le portable pour recevoir les notifications de commandes)',
          },
          email: {
            label: 'Adresse e-mail',
            note: '(notification de expédition)',
            placeholder: 'utilisateur@email.com',
          },
          saveAsDefault: 'Enregistrer en tant que prédéterminé',
          chooseAddress: 'Choisir adresse',
          streetAddress: 'Rue',
          aptSuite: 'Apt Suite',
          selectOne: 'Sélectionner...',
          pleaseSelectOne: 'Veuillez choisir une option',
          setAsDefault: 'Enregistrer en tant que prédéterminé',
          titleRequired: 'Le titre est obligatoire.',
          userAddressAddSuccess: 'Nouvelle adresse ajoutée avec succès !',
          userAddressUpdateSuccess: 'Adresse mise à jour avec succès !',
          userAddressDeleteSuccess: 'Adresse supprimée avec succès !',
          invalidAddress: 'Adresse non valide. {{message}}',
          back: 'Retour',
          deliveryNoteObservations: 'Observations d’envoi',
        },
        addressBook: {
          titleAddressBook: 'Mes adresses',
          defaultAddressBook: 'Adresse d’envoi prédéterminée',
          addressBooklList: 'Carnet d’adresses',
          addressBookAlias: 'Alias',
          addressBookName: 'Prénom et nom de famille',
          addressBookDirection: 'Adresse',
          addressBookPhone: 'Téléphone',
          addressBookObservations: 'Observations d’envoi',
          addNewShippingAddress: 'Ajoutez une nouvelle adresse d’envoi',
          editShippingAddress: 'Modifier l’adresse d’envoi',
          areYouSureToDeleteAddress: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
          addNewAddress: 'Ajouter adresse',
          addAddress: 'Ajouter adresse',
          updateAddress: 'Mettre à jour adresse',
          backToAddressList: 'Retourner à Mes adresses',
          filter: 'Filtrer',
          filterRemove: 'Effacer filtre',
          filterNoAddresses: 'Aucune adresse n’a été trouvée',
        },
        addressCard: {
          default: 'PAR DÉFAUT',
          selected: 'Sélectionné',
          setAsDefault: 'Établir en tant que prédéterminé',
          shipTo: 'Adresse d’envoi',
          billTo: 'Adresse de facturation',
        },
      },
      asm: {
        asm: {
          mainLogoLabel: 'SAP',
          mainTitle: 'Mode de Service Assisté',
          logout: 'Déconnecter',
          hideUi: 'Fermer ASM',
          toggleUi: {
            collapse: 'Cacher ASM',
            expand: 'Afficher ASM',
          },
          loginForm: {
            submit: 'Démarrer la session',
            userId: {
              label: 'ID d’agent',
              required: 'Un ID d’agent est requis',
            },
            password: {
              label: 'Mot de passe',
              required: 'Le mot de passe est obligatoire',
            },
          },
          customerSearch: {
            searchTerm: {
              label: 'Nom du client / e-mail',
            },
            submit: 'Démarrage de session',
            noMatch: 'Aucun client n’a été trouvé.',
          },
          csagentTokenExpired: 'Votre session d’agent du service client a expirée.',
          endSession: 'Terminer session',
          agentSessionTimer: {
            label: 'Temps d’attente de la session',
            minutes: 'min',
            reset: 'Redémarrer',
          },
          standardSessionInProgress: 'Session standard de client en cours.',
          auth: {
            agentLoggedInError:
              'No se puede iniciar la sesión como usuario cuando hay una sesión activa del agente CS. Por favor, emule al usuario o cierre la sesión del agente CS.',
          },
          error: {
            noCustomerId:
              'No se encontró ningún ID de cliente para el usuario seleccionado. No se puede iniciar la sesión.',
          },
        },
      },
      giftbox: {
        giftbox: {
          itemSelect: 'SÉLECTIONNER',
          itemSelectNotAvailable: 'NON DISPONIBLE',
          itemConfigure: 'Configurez votre',
          popupAddGiftboxToCart: {
            titleCartNotEmpty: 'Vous avez des produits dans le panier.',
            titleOtherGiftbox: 'Vous avez une autre boîte cadeau dans le panier.',
            messageCartNotEmpty: 'Si vous continuez, le panier actuel sera vidé.',
            messageOtherGiftbox: 'Si vous continuez, la boîte cadeau actuelle sera supprimée.',
            confirmation: 'Souhaitez-vous continuer ?',
            cancel: 'Annuler',
            continue: 'Continuer',
          },
          popupAddProductToCartWithGiftbox: {
            title: 'Vous avez une boîte cadeau dans le panier.',
            message: 'Que souhaitez-vous faire ?',
            cancel: 'Voir la boîte cadeau',
            continue: 'Supprimer la boîte cadeau',
          },
          header: {
            btnDeleteCart: 'Annuler la boîte cadeau',
          },
          cartTooltip: {
            price: 'Prix',
          },
        },
      },
      cart: {
        cartDetails: {
          id: 'ID',
          proceedToCheckout: 'Gérer commande',
          cartName: 'Panier des achats',
          continueShopping: 'Poursuivre les achats',
          emptyCart: 'Vider le panier',
          missingInformation: 'Une erreur s’est produite lors de la gestion de votre commande. Veuillez réessayer dans quelques minutes',
          checkoutShippingAddress: 'Vous n’avez pas sélectionné une adresse d’envoi valide.',
          checkoutDeliveryAddress: 'Vous n’avez pas sélectionné une adresse d’envoi valide.',
          checkoutPaymentType: 'Vous n’avez pas sélectionné un moyen de paiement valide.',
          checkoutDeliveryMode: 'Vous n’avez pas sélectionné un mode d’envoi valide.',
          remainingSlots: 'Il vous reste {{count}} espace pour compléter votre boîte cadeau.',
          remainingSlots_plural: 'Il vous reste {{count}} espaces pour compléter votre boîte cadeau.',
          remainingSlotsToOrder: 'Il vous reste {{count}} espace pour pouvoir commander votre boîte cadeau.',
          remainingSlotsToOrder_plural: 'Il vous reste {{count}} espaces pour pouvoir commander votre boîte cadeau.',
          deliveryMessage: 'Cette commande contient des produits provenant de différents entrepôts et la livraison sera effectuée en différentes expéditions.',
        },
        cartItems: {
          id: 'Code',
          description: 'Description',
          item: 'Article',
          itemPrice: 'Prix',
          quantity: 'Quantité',
          quantityTitle: 'La quantité représente le nombre total de cet article dans le panier.',
          total: 'Total',
          cartTotal: 'Total ({{count}} article)',
          cartTotal_plural: 'Total ({{count}} articles)',
          deliveryNumber: 'Expédition {{count}}',
        },
        orderCost: {
          orderSummary: 'Résumé',
          subtotal: 'Sous-total',
          shipping: 'Livraison',
          giftCard: 'Carte personnalisée',
          estimatedShipping: 'Livraison',
          discount: 'Réduction',
          salesTax: 'Taxes',
          grossTax: 'N’inclut pas les taxes de',
          grossIncludeTax: 'Taxes incluses',
          total: 'Total',
          toBeDetermined: 'À déterminer',
        },
        voucher: {
          coupon: 'Appliquer code promo',
          coupon_plural: 'Coupons',
          apply: 'Appliquer réduction',
          placeholder: 'Indiquer le code',
          applyVoucherSuccess: '{{voucherCode}} a été appliqué.',
          removeVoucherSuccess: '{{voucherCode}} a été retiré.',
          anchorLabel: 'Renseigner ou retirer un coupon',
          vouchersApplied: 'Coupons appliqués',
          availableCoupons: 'Coupons disponibles',
          availableCouponsLabel: 'Vous pouvez ajouter ces coupons à cette commande.',
        },
        saveForLaterItems: {
          itemTotal: '({{count}} article enregistré)',
          itemTotal_plural: '({{count}} articles enregistrés)',
          cartTitle: 'Panier',
          saveForLater: 'Enregistrer pour plus tard',
          moveToCart: 'Déplacer dans le panier',
          stock: 'Disponibilité',
          forceInStock: 'Disponible',
        },
      },
      checkoutShipping: {
        checkoutShipping: {
          shippingMethod: 'Mode d’envoi',
          chooseShippingMethod: 'Sélectionnez un envoi',
          deliveryNote: {
            name: 'Bon de livraison',
            yes: 'Oui',
            no: 'Non',
          },
          deliveryNoteObservations: 'Observations d’envoi',
          observationPayment: 'Observations pour la facture',
          observationsDeliveryPlaceholder: 'Vous pouvez ajouter des indications au livreur ou un mot pour le destinataire',
          presentCard: 'Carte personnalisée',
          presentCardPlaceholder: 'Message',
          standardDelivery: 'Envoi normal',
          premiumDelivery: 'Envoi urgent',
        },
      },
      checkout: {
        checkoutAddress: {
          shippingAddress: '01. Adresse de livraison',
          selectYourShippingAddress: 'Sélectionnez l’adresse de livraison',
          defaultShippingAddress: 'Adresse de livraison prédéterminée',
          verifyYourAddress: 'Vérifiez l’adresse',
          ensureAccuracySuggestChange:
            'Para asegurar una entrega precisa, sugerimos el siguiente cambio.',
          chooseAddressToUse:
            'por favor, elige la dirección que deseas usar:',
          suggestedAddress: 'Adresse suggérée',
          enteredAddress: 'Adresse indiquée',
          addNewAddress: 'Nouvelle adresse',
          shipToThisAddress: 'Envoyer à cette adresse',
          editAddress: 'Modifier l’adresse',
          saveAddress: 'Enregistrer l’adresse',
          addressList: 'Mon carnet d’adresses',
          showAddressList: 'Voir mes adresses',
          hideAddressList: 'Cacher mes adresses',
          filter: 'Filtrer',
          filterRemove: 'Effacer filtre',
          filterNoAddresses: 'Aucune adresse n’a été trouvée',
          billing: {
            title: 'ADRESSE DE FACTURATION',
            label: 'Demander une facture complète ?',
          },
          onBehalfOf: {
            label: 'Facturer à moi ou à un ami',
            me: 'À moi',
            friend: 'À un ami',
            placeholder: 'Écrivez le nom d’un ami',
            deliveryAddress: 'Adresse d’envoi sélectionnée',
            addressListTitle: 'Adresse d’envoi par défaut de {{friendName}}',
            table: {
              alias: 'Alias',
              name: 'Nom',
              phone: 'Téléphone',
              address: 'Adresse',
              deliveryNote: 'Observations',
            },
          },
        },
        checkoutOrderConfirmation: {
          confirmationOfOrder: 'Confirmation de la commande',
          thankYou: 'Merci pour votre commande !',
          thankYou2: 'Notre service de logistique met tout en œuvre pour qu’elle arrive le plus vite possible et dans de parfaites conditions.',
          thankYou31: 'Vous pouvez voir ce bon de commande et télécharger la facture depuis',
          thankYou32: 'dans l’icône MON PROFIL.',
          bills: 'MES FACTURES',
          orderDetails: 'Suivez votre commande',
          orderDetailsLink: 'ici',
          follow: 'Suivez-nous sur',
          invoiceHasBeenSentByEmail: 'Vous recevrez bientôt la facture par e-mail.',
          orderItems: 'Produits',
          orderPlacedSuccessfully: 'Commande réalisée avec succès',
          createAccount: 'Créez un compte',
          createAccountForNext:
            'Crea una cuenta con <{{email}}> para un checkout más rápido en tu próxima visita.',
          replenishmentNumber: 'Replenishment #',
          placedOn: 'Date',
          status: 'État',
          active: 'Actif',
          cancelled: 'Annulé',
          frequency: 'Fréquence',
          nextOrderDate: 'Date de la prochaine commande',
          orderNumber: 'Numéro de commande',
        },
        checkoutReview: {
          review: 'Résumé',
          orderItems: 'Produits',
          autoReplenishOrder: 'Auto Replenish Order',
          confirmThatRead: 'J’accepte les',
          placeOrder: 'Passer une commande',
          scheduleReplenishmentOrder: 'Programmer une commande de réapprovisionnement',
          orderType_PLACE_ORDER: 'Aucune (uniquement une commande)',
          orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Bon de Réapprovisionnement',
          termsAndConditions: 'Termes et conditions',
          editShippingAddress: 'Modifier l’adresse d’envoi',
          poNumber: 'Numéro de commande',
          every: 'Chaque',
          startOn: 'Commencer',
          dayOfMonth: 'Le',
          repeatOnDays: 'Recommencer les jours suivants',
          recurrencePeriodType_DAILY: 'Jour(s)',
          recurrencePeriodType_WEEKLY: 'Semaine(s)',
          recurrencePeriodType_MONTHLY: 'Mois',
          products: 'Produits',
          group: 'Envoi {{current}} sur {{total}}',
        },
        checkout: {
          backToCart: 'Retourner au panier',
          invalid: {
            accountType: 'Votre compte ne vous permet pas de faire la vérification ici.',
          },
        },
        checkoutPO: {
          noPoNumber: 'Aucun',
          poNumber: 'Numéro de commande (optionnel)',
          costCenter: 'Centre de coûts',
          placeholder: 'Indiquez le numéro du bon de commande',
          availableLabel:
            'Las direcciones de envío disponibles dependen de la unidad del centro de costes',
        },
        checkoutProgress: {
          missingPaymentMethod: 'Sélectionnez un moyen de paiement valable',
          missingPaymentMethodOptions: 'Une erreur s’est produite lors de l’obtention des modes de paiement. Veuillez contacter votre administrateur',
          methodOfPayment: '03. Moyen de paiement',
          shippingAddress: '01. Adresse d’envoi',
          deliveryMode: '02. Mode d’envoi',
          paymentDetails: '03. Détails du paiement',
          reviewOrder: '04. Résumé',
        },
        simulate: {
          CartError: 'Erreur lors de la simulation du bon de commande',
        },
      },
      paypal: {
        paypal: {
          error: {
            default: 'Une erreur s’est produite avec PayPal. Veuillez réessayer plus tard.',
            errorDetail: 'Impossible de finaliser la transaction. {{msg}}',
          },
        },
      },
      bills: {
        bills: {
          title: 'Mes factures',
          filterRemove: 'Effacer filtre',
          filterInvoiceNumber: 'Filtrer n° facture',
          filterDateType: 'Filtrer',
          filterDateFrom: 'du',
          filterDateTo: 'au',
          filterInvoicesFriends: 'Afficher les factures de mes amis',
          headerInvoiceCode: 'N° facture',
          headerInvoiceUser: 'Facturé à',
          headerInvoiceDate: 'Date de facture',
          headerInvoiceDueDate: 'Date d’échéance',
          headerInvoicePayment: 'Moyen de paiement',
          headerInvoiceAmount: 'Montant',
          headerInvoicePending: 'En attente',
          notFound: 'Aucune facture n’a été trouvée',
          sortByTitle: 'Trier par',
        },
      },
      contactForm: {
        contactForm: {
          labels: {
            email: 'E-mail',
            message: 'Commentaire sur le cas',
            subject: 'Objet',
            orderCode: 'Numéro de commande (SC...)',
            accept: 'J’accepte le traitement de mes données personnelles pour les activités indiquées ci-après.',
            requiredAction: 'Action requise',
            productsAffected: {
              label: 'Produits et quantités concernés',
              productDescription: 'Description du produit',
              quantity: 'Quantité',
              add: 'PRODUIT',
              remove: 'Supprimer produit',
            },
            qualityProblem: 'Problème de qualité',
            qualityProblems: {
              excessFat: 'Excès de graisse',
              smellFlavorColorProblems: 'Problèmes d’odeur/goût/couleur',
              vacuumLoss: 'Perte de vide',
              excessLackCuring: 'Excès/manque d’affinage',
              other: 'Autres raisons',
            },
          },
          placeholders: {
            ticketCauses: 'Sélectionnez une raison',
            ticketTemplates: 'Sélectionnez une option',
            qualityProblems: 'Sélectionnez un problème',
          },
          formTemplateTitle: 'Formulaire de contact pour {{formTitle}}',
          submit: 'Envoyer',
          updateSuccess: 'Demande envoyée avec succès',
          updateError: 'Une erreur s’est produite lors de l’envoi de la demande. Veuillez réessayer plus tard.',
          modelNotFoundError: 'L’e-mail ne correspond pas au numéro de commande. Veuillez réessayer en modifiant les données.',
        },
      },
      myAccount: {
        orderDetails: {
          orderId: 'Commande #',
          replenishmentId: 'Réapprovisionnement #',
          purchaseOrderId: 'Bon de commande #',
          emptyPurchaseOrderId: 'Aucun',
          none: 'Aucun {{value}}',
          placed: 'Placé',
          placedBy: 'Placé par',
          unit: 'Unité',
          costCenter: 'Centre de Coûts',
          costCenterAndUnit: 'Centro de Coûts / Unité',
          costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
          payByAccount: 'Payer avec compte',
          paidByCreditCard: '(payé avec carte de crédit)',
          status: 'État',
          shippedOn: 'Envoyé le',
          startOn: 'Commencer',
          nextOrderDate: 'Prochaine date de commande',
          frequency: 'Fréquence',
          cancelled: 'Annulé',
          shipping: 'Envoi',
          deliveryStatus_CREATED: 'Créé',
          deliveryStatus_IN_TRANSIT: 'En transit',
          deliveryStatus_READY_FOR_PICKUP: 'Prêt à être récupéré',
          deliveryStatus_READY_FOR_SHIPPING: 'Prêt à être envoyé',
          deliveryStatus_WAITING: 'En attente',
          deliveryStatus_DELIVERING: 'En cours de livraison',
          deliveryStatus_PICKPACK: 'Préparation pour l’envoi',
          deliveryStatus_PICKUP_COMPLETE: 'Collecte finalisée',
          deliveryStatus_DELIVERY_COMPLETED: 'Livraison finalisée',
          deliveryStatus_PAYMENT_NOT_CAPTURED: 'Problème de paiement',
          deliveryStatus_READY: 'En cours',
          deliveryStatus_DELIVERY_REJECTED: 'Livraison refusée',
          deliveryStatus_SHIPPED: 'Envoyé',
          deliveryStatus_TAX_NOT_COMMITTED: 'Problème fiscal',
          deliveryStatus_CANCELLED: 'Annulé',
          statusDisplay_: '',
          statusDisplay_cancelled: 'Annulé',
          statusDisplay_cancelling: 'Annulation en cours',
          statusDisplay_completed: 'Livré',
          statusDisplay_created: 'Créé',
          statusDisplay_error: 'En attente',
          statusDisplay_Error: 'En attente',
          statusDisplay_processing: 'En attente',
          statusDisplay_open: 'Ouvrir',
          statusDisplay_pending: {
            approval: 'En attente d’approbation',
            merchant: {
              approval: 'En attente de l’approbation du commerçant',
            },
          },
          statusDisplay_approved: 'Approuvé',
          statusDisplay_rejected: 'Refusé',
          statusDisplay_merchant: {
            approved: 'Commerçant approuvé',
            rejected: 'Commerçant refusé',
          },
          statusDisplay_assigned: {
            admin: 'Assigné à l’administrateur',
          },
          consignmentTracking: {
            action: 'Suivi',
            dialog: {
              header: 'Informations de suivi',
              shipped: 'Envoyé',
              estimate: 'Livraison estimée',
              carrier: 'Service de livraison',
              trackingId: 'Numéro de suivi',
              noTracking:
                'El paquete no ha salido del almacén. La información de seguimiento estará disponible después de que se envíe el paquete.',
              loadingHeader: 'Suivi de l’envoi',
            },
            button: {
              label: 'Suivi d’envoi',
            },
            deliveryDate: 'Date de livraison',
            estimatedDeliveryDate: 'Date de livraison estimée',
            consignment: 'N° Bon de commande',
            reference: 'Référence interne',
            failure: 'Incidence',
          },
          cancellationAndReturn: {
            returnAction: 'Demander un retour',
            cancelAction: 'Annuler des articles',
            item: 'Article',
            itemPrice: 'Prix de l’article',
            quantity: 'Quantité',
            returnQty: 'Quantité à retourner',
            cancelQty: 'Quantité à annuler',
            setAll: 'Retourner toutes les unités',
            totalPrice: 'Total',
            submit: 'Envoyer demande',
            returnSuccess: 'Votre demande de retour ({{rma}}) a été présentée',
            returnError: 'Une erreur en survenue lors de l’envoi de votre demande. Veuillez réessayer plus tard.',
            returnPackError: 'Votre demande n’a pas aboutie. Quantités du pack incorrectes.',
            cancelSuccess:
              'Su solicitud de cancelación fue enviada (el pedido original {{orderCode}} será actualizado',
            hasPackGroup: 'Les produits faisant partie d’un pack ne peuvent pas être retournés séparément',
          },
          cancelReplenishment: {
            title: 'Annuler réapprovisionnement',
            description: 'Annuler toute commande de réapprovisionnement future ?',
            accept: 'Oui',
            reject: 'Non',
            cancelSuccess:
              'Pedido de reabastecimiento #{{replenishmentOrderCode}} ha sido cancelado con éxito',
          },
          actions: {
            contentCancellation: 'Êtes-vous sûr de vouloir annuler la commande ? En cas de paiement par carte bancaire, le remboursement sera réalisé dans les prochains jours sur votre compte',
            contentRestoration: 'Pour modifier la commande, la commande actuelle devra être annulée. En cas de paiement par carte bancaire, le remboursement sera réalisé dans les prochains jours sur votre compte. Toute la commande précédente sera automatiquement chargée pour que vous puissiez la modifier. Êtes-vous d’accord ?',
            contentRestoreOnly: 'Êtes-vous sûr que vouloir réaliser de nouveau cette commande ?',
            cancellable: 'Annuler la commande',
            restoration: 'Modifier la commande',
            restoreOnly: 'Commander à nouveau',
            accept: 'Accepter',
            cancel: 'Annuler',
            back: 'Retour',
            orderCancellationSuccess: 'Commande {{orderCode}} annulée avec succès',
            orderCancellationFail: 'Une erreur s’est produite lors de l’annulation de la commande {{orderCode}}',
            orderRestorationSuccess: 'Commande {{orderCode}} restaurée avec succès',
            orderRestorationFail: 'Une erreur s’est produite lors de la restauration de la commande {{orderCode}}',
            orderRestoreOnlySuccess: 'Produits de la commande {{orderCode}} ajoutés au panier avec succès',
            orderRestoreOnlyFail: 'Une erreur s’est produite en commandant de nouveau la commande {{orderCode}}',
            ticket: 'Facture simplifiée',
          },
        },
        orderHistory: {
          orderHistory: 'Mes commandes',
          orderId: 'N° de commande',
          emptyPurchaseOrderId: 'Aucune',
          date: 'Date de création',
          status: 'État',
          total: 'Montant',
          noOrders: 'Aucune commande n’a été trouvée',
          noReplenishmentOrders:
            'No se encontraron pedidos de reabastecimiento',
          startShopping: 'Continuer les achats',
          sortBy: 'Trier par',
          sortByTitle: 'Trier par',
          sortByMostRecent: 'Trier par plus récent',
          sortOrders: 'Ordres de tri',
          replenishmentOrderHistory: 'Historique des commandes de réapprovisionnement',
          replenishmentOrderId: 'Réapprovisionnement #',
          purchaseOrderNumber: 'PO #',
          startOn: 'Commencer',
          frequency: 'Fréquence',
          nextOrderDate: 'Prochaine date de commande',
          cancel: 'Annuler',
          cancelled: 'Annulé',
          replenishmentHistory: 'Historique de réapprovisionnement',
          notFound: 'Aucune commande n’a été trouvée',
          cancelOrder: 'Annuler',
          editOrder: 'Modifier',
          restoreOnlyOrder: 'Commander à nouveau',
          orderDetails: 'Détails de la commande',
          invoicedTo: 'Facturé à',
          sendTo: 'Envoyé à',
          filterRemove: 'Effacer filtre',
          filterOrderNumber: 'Filtrer par n° de commande',
          filterDateFrom: 'Filtrer par date du',
          filterDateTo: 'au',
          filterOrdersInvoiced: 'Afficher les commandes facturées',
          filterOrdersFriends: 'Afficher les commandes de mes amis',
          actions: {
            contentCancellation: 'Êtes-vous sûr de vouloir annuler la commande ? En cas de paiement par carte bancaire, le remboursement sera réalisé dans les prochains jours sur votre compte',
            contentRestoration: 'Pour modifier la commande, la commande actuelle devra être annulée. En cas de paiement par carte bancaire, le remboursement sera réalisé dans les prochains jours sur votre compte. Toute la commande précédente sera automatiquement chargée pour que vous puissiez la modifier. Êtes-vous d’accord ?',
            cancellable: 'Annuler la commande',
            restoration: 'Modifier la commande',
            accept: 'Accepter',
            cancel: 'Annuler',
            back: 'Retour',
          },
        },
        closeAccount: {
          confirmAccountClosure: 'Confirmer la fermeture du compte',
          confirmAccountClosureMessage:
            '¿Estás seguro/a de que quieres cerrar tu cuenta?',
          closeMyAccount: 'FERMER MON COMPTE',
          accountClosedSuccessfully: 'Compte fermé avec succès',
          accountClosedFailure: 'Le compte n’a pas pu être fermé',
        },

// @deprecated the updateEmailForm labels are moved to the user lib and will be dropped with the next major release '',
        updateEmailForm: {
          newEmailAddress: {
            label: 'Nouvel e-mail',
            placeholder: 'Indiquer l’e-mail',
          },
          confirmNewEmailAddress: {
            label: 'Confirmer le nouvel e-mail',
            placeholder: 'Indiquer l’e-mail',
          },
          enterValidEmail: 'Veuillez indiquer un e-mail valide.',
          bothEmailMustMatch: 'Les deux e-mails doivent être identiques',
          password: {
            label: 'Mot de passe',
            placeholder: 'Saisir un mot de passe',
          },
          pleaseInputPassword: 'Veuille saisir le mot de passe',
          emailUpdateSuccess: 'Opération réalisée avec succès. Démarrage de session avec {{ newUid }}',
        },

        updatePasswordForm: {
          changePassword: 'Modifier le mot de passe',
          oldPassword: {
            label: 'Mot de passe actuel',
            placeholder: 'Mot de passe actuel',
          },
          oldPasswordIsRequired: 'L’ancien mot de passe est requis',
          newPassword: {
            label: 'Nouveau mot de passe',
            placeholder: 'Nouveau mot de passe',
          },
          passwordMinRequirements:
            'Le mot de passe doit contenir un minimum de six caractères, une lettre majuscule, une lettre minuscule, un chiffre et un symbole',
          confirmPassword: {
            label: 'Confirmer le nouveau mot de passe',
            placeholder: 'Confirmer le mot de passe',
          },
          bothPasswordMustMatch: 'Les deux mots de passes doivent être identiques',
          passwordUpdateSuccess: 'Mot de passe mis à jour avec succès',
          sendForm: 'Modifier le mot de passe',
        },
        updateProfileForm: {
          pageTitle: 'Mes informations',
          contactInfo: 'INFORMATION DU CONTACT',
          addressInfo: 'ADRESSE DE FACTURATION',
          title: '',
          none: '',
          firstName: {
            label: 'Prénom et nom de famille',
            placeholder: 'Prénom et nom de famille',
          },
          firstNameIsRequired: 'Le prénom est requis.',
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
          },
          lastNameIsRequired: 'Le nom de famille est requis.',
          profileUpdateSuccess: 'Données personnelles mises à jour avec succès',
          customerId: 'Client #',
          documentIdentifier: 'NIF',
          bankAccountNumber: 'IBAN',
          phone: {
            label: 'Numéro de téléphone',
            info: '(de préférence le portable pour recevoir les notifications de commandes)',
          },
          uid: 'Adresse e-mail',
          address: 'Adresse',
          town: 'Ville',
          postalCode: 'Code postal',
          state: 'Province',
          country: 'Zone - Pays',
          save: 'Enregistrer les informations',
          selectOne: 'Sélectionner...',
        },
        consentManagementForm: {
          clearAll: 'Tout effacer',
          selectAll: 'Tout sélectionner',
          message: {
            success: {
              given: 'Consentement accepté avec succès.',
              withdrawn: 'Consentement refusé avec succès.',
            },
          },
        },
        myCoupons: {
          noCouponsMessage: 'Vous n’avez pas de coupons disponibles.',
          effectiveTitle: 'Effectif',
          Effective: 'EFFECTIF',
          PreSession: 'EFFECTIF BIENTÔT',
          ExpireSoon: 'EXPIRE BIENTÔT',
          readMore: 'Lire plus',
          notification: 'Notification',
          findProducts: 'Rechercher Produits',
          status: 'États',
          dialogTitle: 'Coupon',
          claimCustomerCoupon: 'Ce coupon a correctement été réclamé.',
          myCoupons: 'Mes coupons',
          startDateAsc: 'Date de début (ascendante)',
          startDateDesc: 'Date de début (descendante)',
          endDateAsc: 'Date de fin (ascendante)',
          endDateDesc: 'Date de fin (descendante)',
          sortByMostRecent: 'Trier par plus récent',
          notesPreffix:
            'Puede establecer sus canales preferidos para recibir notificaciones de cupones en el ',
          notesLink: 'Canaux de notification',
          notesSuffix: 'page.',
        },
        notificationPreference: {
          title: 'NOTIFICATIONS',
          message: 'Sélectionnez vos canaux de notification préférés',
          note: 'Note',
          noteMessage:
            'Si desactiva todos los canales, no podrá recibir más notificaciones.',
          EMAIL: 'Adresse e-mail',
          SMS: 'SMS',
          SITE_MESSAGE: 'Message sur le site',
        },
        myInterests: {
          header: 'MES INTÉRÊTS',
          item: 'ARTICLE',
          price: 'PRIX',
          notifications: 'NOTIFICATIONS',
          noInterests: 'Vous n’avez pas encore d’intérêts enregistrés.',
          inStock: 'Disponible',
          lowStock: 'Peu d’articles en stock',
          outOfStock: 'Non disponible',
          BACK_IN_STOCK: 'De nouveau en stock',
          sortByMostRecent: 'Trier par plus récent',
          expirationDate: 'Jusqu’au {{ expirationDate }}',
          productId: 'ID code {{ code }}',
          remove: 'SUPPRIMER',
          sorting: {
            byNameAsc: 'Prénom (ascendant)',
            byNameDesc: 'Nom (descendant)',
          },
        },
        AccountOrderHistoryTabContainer: {
          tabs: {
            AccountOrderHistoryComponent: 'TOUTES LES COMMANDES ({{param}})',
            OrderReturnRequestListComponent: 'RETOURS ({{param}})',
          },
        },
        returnRequestList: {
          pageTitle: 'Mes retours',
          returnRequestId: 'N° de retour',
          orderId: 'N° de commande',
          orderReturnId: 'N° de bon de retour',
          date: 'Date de création',
          status: 'État',
          total: 'Montant',
          sortByTitle: 'Trier par',
          sortByMostRecent: 'Trier plus récent',
          statusDisplay: '-',
          statusDisplay_APPROVAL_PENDING: 'Approbation en attente',
          statusDisplay_CANCELED: 'Annulée',
          statusDisplay_CANCELLING: 'En cours d’annulation',
          statusDisplay_WAIT: 'En attente',
          statusDisplay_RECEIVED: 'Reçue',
          statusDisplay_RECEIVING: 'En cours de réception',
          statusDisplay_APPROVING: 'Approuvée',
          statusDisplay_REVERSING_PAYMENT: 'Annulation du paiement',
          statusDisplay_PAYMENT_REVERSED: 'Paiement annulé',
          statusDisplay_PAYMENT_REVERSAL_FAILED: 'Annulation de paiement échouée',
          statusDisplay_REVERSING_TAX: 'Annulation des taxes',
          statusDisplay_TAX_REVERSED: 'Taxe annulée',
          statusDisplay_TAX_REVERSAL_FAILED: 'Annulation des taxes échouée',
          statusDisplay_COMPLETED: 'Complétée',
          noReturns: 'Vous n’avez réalisé aucun retour',
          startShopping: 'Continuer les achats',
        },
        returnRequest: {
          pageTitle: 'Demande de retour',
          returnRequestId: 'N° de demande',
          orderCode: 'N° de commande',
          orderReturnId: 'N° de commande de retour',
          status: 'État',
          cancel: 'Annuler la demande de retour',
          item: 'Description',
          itemPrice: 'Prix de l’article',
          returnQty: 'Montant remboursé',
          total: 'Total',
          summary: 'Résumé',
          subtotal: 'Sous-total',
          deliveryCode: 'Frais de livraison',
          estimatedRefund: 'Remboursement',
          note: 'Les totaux sont estimatifs et peuvent ne pas inclure des taxes ou autres suppléments.',
          cancelSuccess: 'Votre demande de retour ({{rma}}) a été annulée',
        },
        wishlist: {
          wishlist: {
            empty: 'Il n’y a pas encore de produits dans votre liste de souhaits',
            title: 'Liste de souhaits',
            remove: 'Supprimer de la liste de souhaits',
          },
        },
      },
      payment: {
        paymentForm: {
          payment: 'Moyen de paiement',
          choosePaymentMethod: 'Choisissez un moyen de paiement',
          paymentType: 'Type de paiement',
          paymentObservations: 'Observations pour la facture',
          accountHolderName: {
            label: 'Nom du titulaire du compte',
            placeholder: 'Nom du titulaire du compte',
          },
          cardNumber: 'Numéro de carte',
          expirationDate: 'Date d’expiration',
          securityCode: 'Code de Sécurité (CVV)',
          securityCodeTitle: 'Valeur de vérification de la carte',
          saveAsDefault: 'Enregistrer en tant prédéterminé',
          setAsDefault: 'Établir en tant que prédéterminé',
          billingAddress: 'Adresse de facturation',
          sameAsShippingAddress: 'Idem que l’adresse d’envoi',
          selectOne: 'Sélectionner...',
          monthMask: 'MM',
          yearMask: 'AAAA',
          useThisPayment: 'Utiliser ce paiement',
          addNewPayment: 'Ajouter nouveau paiement',
          changePayment: 'Modifier paiement',
          documentIdentifier: 'NIF',
        },
        paymentMethods: {
          paymentMethods: 'Modes de paiement',
          newPaymentMethodsAreAddedDuringCheckout:
            'Los nuevos métodos de pago se añaden durante el proceso de compra.',
          invalidField: 'Champ non valide',
        },
        paymentCard: {
          deleteConfirmation:
            '¿Está seguro de que quiere eliminar este método de pago?',
          setAsDefault: 'Établir en tant que prédéterminé',
          expires: 'Expire',
          defaultPaymentMethod: '✓ PRÉDÉTERMINÉ',
          selected: 'Sélectionné',
        },
        paymentTypes: {
          title: 'Moyen de paiement',
          paymentType: '{{ display }}',
          paymentType_BankAccount: '{{ display }} de {{ name }}',
          saveCreditCard: 'Enregistrer carte de crédit',
          creditCard: 'Carte bancaire {{cardNumber}}',
          creditCardDeletePopup: {
            buttonDelete: 'Supprimer',
            buttonCancel: 'Retour',
            message: 'Êtes-vous sûr de vouloir supprimer la carte bancaire numéro {{cardId}}?',
            accountHolderName: 'Nom',
            cardNumber: 'Numéro',
          },
          missingAddress: 'Une erreur s’est produite lors de la validation de l’adresse de livraison. Sélectionnez une nouvelle adresse de livraison pour continuer.',
        },
      },
      product: {
        productDetails: {
          id: 'ID',
          quantity: 'Quantité',
          productDetails: 'Détails du produit',
          specification: 'Spécifications',
          reviews: 'Commentaires',
          shipping: 'Envoi',
          share: 'Partager',
          showReviews: 'Afficher les commentaires',
          noReviews: 'Il n’y a pas d’avis',
          slots: '{{ count }} espace',
          slots_plural: '{{ count }} espaces',
          discount: 'Rem.',
        },
        productList: {
          filterBy: {
            label: 'Filtrer par',
            action: 'Filtrer par',
          },
          appliedFilter: 'Filtres appliqués',
          showLess: 'Afficher moins...',
          showMore: 'Afficher plus...',
          sortByRelevance: 'Trier par pertinence',
          backToTopBtn: 'RETOUR VERS LE HAUT',
          showMoreBtn: 'AFFICHER PLUS',
          sortResults: 'Trier les résultats',
        },
        productFacetNavigation: {
          filterBy: {
            label: 'Filtrer par',
            action: 'Filtrer par',
          },
          appliedFilter: 'Filtres appliqués',
          showLess: 'Afficher moins...',
          showMore: 'Afficher plus...',
          sortByRelevance: 'Trier par pertinence',
        },
        productSummary: {
          id: 'ID',
          showReviews: 'Afficher les commentaires',
          share: 'Partager',
        },
        productReview: {
          overallRating: 'Appréciation globale',
          reviewTitle: 'Ajoutez un titre',
          writeYourComments: 'Ajouter un commentaire écrit',
          rating: 'Appréciation',
          reviewerName: 'Nom (optionnel)',
          writeReview: 'Donner un avis sur ce produit',
          more: 'Plus',
          less: 'Moins',
          thankYouForReview:
            'Gracias por tu opinión! Se aprobará antes de aparecer aquí.',
        },
        addToCart: {
          itemsNotAddedToYourCart: 'Il n’y a pas suffisamment d’unités de cet article en stock pour les ajouter à votre panier',
          itemsAddedToYourCart: 'Des articles ont été ajoutés à votre panier',
          itemsIncrementedInYourCart:
            'Este artículo ya estaba en tu carrito. La cantidad se ha actualizado.',
          items: 'Articles',
          updatingCart: 'Mise à jour du panier en cours...',
          addToCart: 'Ajouter au panier',
          viewCart: 'voir panier',
          proceedToCheckout: 'Aller à Vérifier',
          quantity: 'Quantité',
          outOfStock: 'NON DISPONIBLE',
          inStock: 'Disponible',
          selectStyleAndSize: 'Sélectionnez le style et la taille pour vérifier le stock',
          eachUnit: 'q/u.',
          quantityUnit: 'Quantité',
          quantityUnit_less: 'Quantité',
          continueShopping: 'Continuer les achats',
          outOfStockUnit: 'NON DISPONIBLE',
          unitSelect: 'SÉLECTIONNER',
        },
        TabPanelContainer: {
          tabs: {
            ProductDetailsTabComponent: 'Détails',
            ProductSpecsTabComponent: 'Spécifications',
            ProductReviewsTabComponent: 'Appréciations',
            deliveryTab: 'Envoi',
          },
        },
        /**
         * @deprecated since 3.2
         * Use feature-library @spartacus/product/variants/assets instead
         */
        variant: {
          style: 'Style',
          selectedStyle: 'Style sélectionné',
          size: 'Taille',
          color: 'Couleur',
          sizeGuideLabel: 'Guide de style',
        },
        addToWishList: {
          add: 'Ajouter à la liste de souhaits',
          remove: 'Supprimer de la liste de souhaits',
          anonymous: 'Démarrez une session pour ajouter à la liste de souhaits',
        },
        stockNotification: {
          notifyMe: 'ENVOYEZ-MOI UNE NOTIFICATION',
          stopNotify: 'ANNULER LA NOTIFICATION',
          getNotify: 'Recevez une notification lorsque ce produit sera disponible.',
          getNotifySuffix: 'pour recevoir une notification lorsque ce produit sera disponible.',
          activateChannelsPrefix: 'Pour recevoir des notifications, vous devez activer les',
          channelsLink: 'Canaux de notification',
          activateChannelsSuffix: '.',
          notified: 'Vous recevrez une notification lorsque ce produit sera de nouveau disponible.',
          getNotified: 'Recevez une notification lorsque ce produit sera de nouveau disponible.',
          unsubscribeSuccess: 'Vous ne recevrez plus de notifications de disponibilité pour ce produit.',
          subscriptionDialog: {
            header: 'INSCRIPTION HORS STOCK',
            notifiedPrefix: 'Vous recevez une notification dans',
            notifiedSuffix: 'dès que ce produit sera de nouveau disponible.',
            manageChannelsPrefix: '',
            manageChannelsLink: '',
            manageChannelsSuffix: '',
            manageSubscriptionsPrefix: '',
            manageSubscriptionsLink: '',
            manageSubscriptionsSuffix: '',
            okBtn: 'OK',
            subscribing: 'Inscription aux notifications de disponibilité de stock de ce produit',
          },
        },
        itemCounter: {
          removeOne: 'Enlever un',
          addOneMore: 'En ajouter un autre',
          quantity: 'Quantité',
        },
      },
      productImageZoomTrigger: {
        productImageZoomTrigger: {
          expand: 'Agrandir',
          close: 'Fermer',
        },
      },
      productImageZoomDialog: {
        productImageZoomDialog: {
          expand: 'Agrandir',
          close: 'Fermer',
        },
      },
      statistics: {
        statistics: {
          compare: {
            label: 'Comparaison avec l’année précédente',
            yes: 'Oui',
            no: 'Non',
          },
          label: 'Statistiques',
          month: 'Mois',
          years: 'Année',
          euros: 'Euros',
          table: {
            article: 'Article',
            january: 'Jan.',
            february: 'Fév.',
            march: 'Mar.',
            april: 'Avr.',
            may: 'Mai',
            june: 'Juin',
            july: 'Juil.',
            august: 'Aoû.',
            september: 'Sep.',
            october: 'Oct.',
            november: 'Nov.',
            december: 'Déc.',
          },
        },
      },
      newsletter: {
        newsletter: {
          email: {
            submit: 'INSCRIVEZ-VOUS',
            submitSuccess: 'INSCRIT !',
            placeholder: 'Indiquez votre adresse e-mail',
          },
          accept: {
            label: 'J’accepte le traitement de mes données personnelles pour les activités indiquées ci-après.',
          },
          popup: {
            close: 'Fermer',
          },
          updateSuccess: 'Vous vous êtes inscrit à la newsletter avec succès',
          updateError: 'Une erreur s’est produite lors de l’inscription à la newsletter. Veuillez réessayer plus tard.',
        },
      },
      news: {
        news: {
          readMore: 'Lire plus',
          filterNewsTitle: 'Titre de l’information',
          filterRemove: 'Effacer filtre',
          filterDate: 'Filtrer',
          numberToShow: 'Afficher',
          search: 'Filtrer',
          categories: 'Catégories',
          allCategories: 'Toutes',
          notFound: 'Aucune information n’a été trouvée',
          filtersNotFound: 'Aucune information n’a été trouvée avec les filtres appliqués',
          filters: {
            category: {
              loading: 'Chargement en cours...',
              ALL: 'Toutes',
              BRANDS_AND_NEWS: 'Marques et Nouveautés',
              LIFESTYLE: 'Style de vie',
              GASTRONOMY: 'Gastronomie',
              WINE_TOURISM_AND_VISITS: 'Œnotourisme & Visites',
              TRAINING_AND_INNOVATION: 'Formation et Innovation',
              MEDIA: 'Médias'
            }
          }
        },
      },
      recaptcha: {
        recaptcha: {
          scriptFailedToLoad: 'Erreur lors du chargement du script reCaptcha.',
          recaptchaTokenV2Failed: 'Vérifier la validation de reCaptcha',
          invalidVersion: 'Version version de reCaptcha invalide. Versions disponibles',
          expired: 'La validation du reCaptcha a expiré',
          fail: 'Une erreur s’est produite avec reCaptcha',
        }
      },
      login: {
        login: {
          btnTitle: 'Accéder',
        },
      },
      productCategories: {
        productCategories: {
          title: 'Catégories',
        },
      },
    },
  };
