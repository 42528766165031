import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import {
  Address,
  GlobalMessageService,
  TranslationService,
  UserAddressService
} from '@spartacus/core';
import { AddressBookComponent, AddressBookComponentService } from "@spartacus/user/profile/components";
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BusinessFriends } from "../../../../../custom/cms-components/myaccount/custom-friends/enum/business-friends.enum";
import { filterText } from '../../../../../custom/util/filter-text';
import { CustomUpdateProfileComponentService } from "../../../feature-libs/user/profile/components/update-profile/custom-update-profile-component.service";

@Component({
  selector: 'cx-address-book',
  templateUrl: './custom-address-book.component.html',
  styleUrls: ['./custom-address-book.component.scss'],
})
export class CustomAddressBookComponent extends AddressBookComponent implements OnInit, OnDestroy {
  filteredAddresses$: Observable<Address[]>;
  filter = new FormControl();
  filter$: Observable<string>;
  subscription = new Subscription();
  exclusiveRole$: Observable<string>;
  isFormEditable: Boolean;
  constructor(
    public service: AddressBookComponentService,
    protected translation: TranslationService,
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryService: CheckoutDeliveryAddressFacade,
    protected customUpdateProfileComponentService: CustomUpdateProfileComponentService,
    protected globalMessageService: GlobalMessageService
  ) {
    super(service, translation, globalMessageService);
  }

  ngOnInit(): void {
    this.exclusiveRole$ = this.customUpdateProfileComponentService.exclusiveRole$;

    this.subscription.add(
      this.exclusiveRole$.subscribe(exclusiveRole => {
        this.isFormEditable =
          exclusiveRole === BusinessFriends.FRIENDS ||
          exclusiveRole === BusinessFriends.PUBLIC ||
          exclusiveRole === BusinessFriends.RIOFRIO;
      }));
    this.addresses$ = this.service.getAddresses();
    this.addressesStateLoading$ = this.service.getAddressesStateLoading();
    this.service.loadAddresses();
    this.filter$ = this.filter.valueChanges.pipe(startWith(''));
    this.filteredAddresses$ = combineLatest([this.addresses$, this.filter$])
      .pipe(
        map(([addresses, filterString]) => addresses.filter(address => {
          const fullName = address.firstName + (address.lastName ? ' ' + address.lastName : '');
          return !address.defaultAddress && filterText([
            address.companyName,
            fullName,
            address.formattedAddress,
            address.phone,
            address.town,
          ], filterString);
        },
        )),
      );
    this.filteredAddresses$.subscribe();
  }

  getRowContent(address: Address, canDelete: boolean = true): Observable<any> {
    return combineLatest([
      this.translation.translate('addressCard.default'),
      this.translation.translate('addressCard.setAsDefault'),
      this.translation.translate('common.delete'),
      this.translation.translate('common.edit'),
      this.translation.translate('addressBook.areYouSureToDeleteAddress'),
    ]).pipe(
      map(
        ([
          ,
          setAsDefaultText,
          textDelete,
          textEdit,
          textVerifyDeleteMsg,
        ]) => {
          let region = '';

          if (address.region && address.region.isocode) {
            region = address.region.name + ', ';
          }

          const actions: { name: string; event: string }[] = [];
          if (!address.defaultAddress) {
            actions.push({ name: setAsDefaultText, event: 'default' });
          }
          actions.push({ name: textEdit, event: 'edit' });
          if (canDelete) {
            actions.push({ name: textDelete, event: 'delete' });
          }
          const country = (address?.country?.name ? address.country.name : address?.country?.isocode);

          return {
            text: [
              address.companyName,
              address.firstName + (address.lastName ? ' ' + address.lastName : ''),
              address.line1 + ',' + (address?.line2?.length ? address.line2 + ', ' : '') + address.town + ', ' + region + country + ', ' + address.postalCode,
              address.phone,
              address.deliveryNoteObservations,
            ],
            actions,
            deleteMsg: textVerifyDeleteMsg,
          };
        }
      )
    );
  }

  removeFilter(): void {
    this.filter?.patchValue('');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
