/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CredentialCreationOptionsJSON, CredentialRequestOptionsJSON } from '@github/webauthn-json';
import {
  AuthConfigService,
  AuthToken,
  InterceptorUtil,
  LoggerService,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
  normalizeHttpError
} from '@spartacus/core';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class OsborneWebAuthnAdapter {

  protected logger = inject(LoggerService);

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected authConfigService: AuthConfigService
  ) { }


  getCredentialCreationOptions(userId: string): Observable<CredentialCreationOptionsJSON> {
    const url: string = this.occEndpoints.buildUrl('webAuthnRegistrationOptions', { urlParams: { userId } });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<CredentialCreationOptionsJSON>(url, { headers }).pipe(
      catchError((error) => {
        throw normalizeHttpError(error, this.logger);
      }),
    );
  }

  verifyCredentialRegistration(userId: string, publicKeyCredential: string): Observable<any> {
    const url: string = this.occEndpoints.buildUrl('webAuthnVerifyRegistration', { urlParams: { userId } });
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { publicKeyCredential }
    return this.http.post(url, body, { headers }).pipe(
      catchError((error) => {
        throw normalizeHttpError(error, this.logger);
      }),
    );
  }

  getCredentialAuthenticationOptions(publicKeyUser: string): Observable<CredentialRequestOptionsJSON> {
    const url: string = this.occEndpoints.buildUrl('webAuthnAuthenticationOptions');
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    const body = { publicKeyUser }
    return this.http.post<CredentialRequestOptionsJSON>(url, body, { headers }).pipe(
      catchError((error) => {
        throw normalizeHttpError(error, this.logger);
      }),
    );
  }

  verifyAuthentication(publicKeyUser: string, publicKeyCredential: string): Observable<AuthToken> {
    const url: string = this.occEndpoints.buildUrl('webAuthnVerifyAuthentication');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const clientId = this.authConfigService.getClientId();
    const clientSecret = this.authConfigService.getClientSecret();
    const body = { clientId, clientSecret, publicKeyUser, publicKeyCredential }
    return this.http.post<AuthToken>(url, body, { headers }).pipe(
      catchError((error) => {
        throw normalizeHttpError(error, this.logger);
      }),
    );
  }

}
