<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

@if (site$ | async) {
  <h2>
    {{ (showExtendedLoginInfo ? 'loginForm.accessLong' : 'loginForm.access') | cxTranslate }}
  </h2>
  <div *ngIf="showExtendedLoginInfo">
    <p>
      {{ 'loginForm.accessInfo' | cxTranslate }}
    </p>
  </div>
  
  <ng-container *ngIf="isMobile; else formTemplate">
    <p>
      <a
        class="btn btn-block btn-primary mb-3"
        (click)="loginWithFingerprint()"
      >
        <i class="fa-solid fa-fingerprint"></i> {{ 'loginForm.loginWithFingerPrint' | cxTranslate }}
      </a>
    </p>
    <p class="text-center">{{ 'loginForm.or' | cxTranslate }}</p>
    <div>
      <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
    </div>
  </ng-container>
  
  <ng-template #formTemplate>
    <form id="loginForm" (ngSubmit)="executeRecaptcha()" [formGroup]="form">
      
      <div class="form-group">
        <label>
          <span class="label-content">
            {{ (showExtendedLoginInfo ? 'loginForm.emailAddress.labelLong' : 'loginForm.emailAddress.label') | cxTranslate }}
          </span>
          <input
            id="loginFormEmail"
            type="email"
            class="form-control"
            formControlName="userId"
          />
        </label>
        <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
      </div>
      <div class="form-group">
        <label>
          <span class="label-content">
            {{ 'loginForm.password.label' | cxTranslate }}
          </span>
          <input
            id="loginFormPassword"
            type="password"
            class="form-control"
            formControlName="password"
          />
        </label>
        <cx-form-errors [control]="form.get('password')"></cx-form-errors>
      </div>
      <div
        class="form-group use-finger-print"
        [hidden]="!isMobile"
      >
        <div class="form-check">
          <label>
            <input
              type="checkbox"
              name="useFingerprint"
              formControlName="useFingerprint"
            />
            <span class="form-check-label"> <i class="fa-solid fa-fingerprint"></i> {{ 'webauthn.form' | cxTranslate }} </span>
          </label>
        </div>
      </div>
      <div class="buttons-container form-group">
        <div class="register-reset-password">
          <a
            [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
            aria-controls="reset-password"
            class="btn-link"
          >
            {{ (showExtendedLoginInfo ? 'loginForm.forgotPasswordLong' : 'loginForm.forgotPassword') | cxTranslate }}
          </a>
        </div>
      
        <button
          id="loginFormSubmit"
          type="submit"
          class="btn btn-login"
        >
          {{ (showExtendedLoginInfo ? 'loginForm.signInLong' : 'loginForm.signIn') | cxTranslate }}
        </button>
      </div>
      <div class="buttons-container form-group">
        <osborne-captcha-disclaimer></osborne-captcha-disclaimer>
      </div>
    </form>
  </ng-template>
}
