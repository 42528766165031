import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { Cart } from '@spartacus/cart/base/root';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomCartNotGiftboxGuard  {
  constructor(
    protected activeCartService: ActiveCartService,
    protected semanticPathService: SemanticPathService,
    protected router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.activeCartService.getActive(),
      this.activeCartService.getLoading(),
      this.activeCartService.isStable(),
    ]).pipe(
      filter(([_, loading, stable]) => stable || !loading),
      map(([cart]) => {
        if (this.hasGiftbox(cart)) {
          return this.router.parseUrl(this.semanticPathService.get('giftboxWizard'));
        }
        return true;
      })
    );
  }

  private hasGiftbox(cart: Cart): boolean {
    return cart && !!cart.giftBoxProduct;
  }
}
