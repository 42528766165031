import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageActions, GlobalMessageType, LoggerService, tryNormalizeHttpError, SiteContextActions, withdrawOn } from '@spartacus/core';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { Wishlist, WishlistModification } from 'src/app/model/wishlist.model';
import { CustomWishlistConnector } from '../../connectors/custom-wishlist.connector';
import { UserWishlistActions } from '../actions';
import { AddProductToWishlist, ADD_PRODUCT_TO_WISHLIST, REMOVE_PRODUCT_FROM_WISHLIST } from '../actions/custom-wishlist.action';

@Injectable()
export class CustomUserWishlistEffect {
  private contextChange$ = this.actions$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE
    )
  );

  constructor(
    private actions$: Actions,
    private wishlistConnector: CustomWishlistConnector,
    private logger: LoggerService
  ) { }

  loadUserWishlist$: Observable<UserWishlistActions.UserWishlistAction> = createEffect(() => this.actions$.pipe(
    ofType(UserWishlistActions.LOAD_USER_WISHLIST),
    map((action: UserWishlistActions.LoadUserWishlist) => action.payload),
    switchMap((payload) => {
      return this.wishlistConnector.get(
        payload.userId,
      )
      .pipe(
        map((wishlist: Wishlist) => {
          return new UserWishlistActions.LoadUserWishlistSuccess(wishlist);
        }),
        catchError((error) =>
          of(new UserWishlistActions.LoadUserWishlistFail(tryNormalizeHttpError(error, this.logger)))
        )
      );
    })
  ));

  resetUserWishlist$: Observable<UserWishlistActions.ClearUserWishlist> = createEffect(() => this.actions$.pipe(
    ofType(SiteContextActions.LANGUAGE_CHANGE),
    map(() => {
      return new UserWishlistActions.ClearUserWishlist();
    })
  ));

  addProductToWishlist$: Observable<
    | UserWishlistActions.AddProductToWishlistSuccess
    | UserWishlistActions.AddProductToWishlistFail
    | UserWishlistActions.LoadUserWishlist
    | GlobalMessageActions.AddMessage
  > = createEffect(() => this.actions$.pipe(
    ofType(ADD_PRODUCT_TO_WISHLIST),
    map((action: AddProductToWishlist) => action.payload),
    concatMap((payload) => {
      return this.wishlistConnector
        .addToWishlist(
          payload.userId,
          payload.productCode,
        )
        .pipe(
          map(
            (wishlistModification: WishlistModification) =>
              new UserWishlistActions.AddProductToWishlistSuccess({
                ...payload,
                ...(wishlistModification as Required<WishlistModification>),
              })
          ),
          catchError((error) => {
          const actions: Array<
          | UserWishlistActions.AddProductToWishlistFail
          | UserWishlistActions.LoadUserWishlist
          | GlobalMessageActions.AddMessage
          > = [
            new UserWishlistActions.AddProductToWishlistFail({
              ...payload,
              error: tryNormalizeHttpError(error, this.logger),
            }),
          ];
          error?.error?.errors?.forEach((err) => {
            if (err.message) {
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: { raw: err.message },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                  timeout: 5000,
                })
              );
            }
          });
          return from(actions);
        }
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  removeProductFromWishlist$: Observable<
    | UserWishlistActions.RemoveProductFromWishlistSuccess
    | UserWishlistActions.RemoveProductFromWishlistFail
    | UserWishlistActions.LoadUserWishlist
    | GlobalMessageActions.AddMessage
  > = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_PRODUCT_FROM_WISHLIST),
    map((action: AddProductToWishlist) => action.payload),
    concatMap((payload) => {
      return this.wishlistConnector
        .removeFromWishlist(
          payload.userId,
          payload.productCode,
        )
        .pipe(
          map(
            (wishlistModification: WishlistModification) =>
              new UserWishlistActions.RemoveProductFromWishlistSuccess({
                ...payload,
                ...(wishlistModification as Required<WishlistModification>),
              })
          ),
          catchError((error) => {
          const actions: Array<
          | UserWishlistActions.RemoveProductFromWishlistFail
          | UserWishlistActions.LoadUserWishlist
          | GlobalMessageActions.AddMessage
          > = [
            new UserWishlistActions.RemoveProductFromWishlistFail({
              ...payload,
              error: tryNormalizeHttpError(error, this.logger),
            }),
          ];
          error?.error?.errors?.forEach((err) => {
            if (err.message) {
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: { raw: err.message },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                  timeout: 5000,
                })
              );
            }
          });
          return from(actions);
        }
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  refreshWithoutProcesses$: Observable<UserWishlistActions.LoadUserWishlist> = createEffect(() => this.actions$.pipe(
    ofType(
      UserWishlistActions.ADD_PRODUCT_TO_WISHLIST_SUCCESS,
      UserWishlistActions.REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
    ),
    map(
      (
        action:
          | UserWishlistActions.AddProductToWishlistSuccess
          | UserWishlistActions.RemoveProductFromWishlistSuccess
      ) => action.payload
    ),
    map(
      (payload) =>
        new UserWishlistActions.LoadUserWishlist({
          userId: payload.userId,
        })
    )
  ));
}
