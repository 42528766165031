import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadStatistics,
  LoadStatisticsFail,
  LoadStatisticsProducts,
  LoadStatisticsProductsFail,
  LoadStatisticsProductsSuccess,
  LoadStatisticsSuccess,
  StatisticsActions,
  StatisticsActionType,
  StatisticsProductActions,
} from '../actions/custom-statistics-actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoggerService, tryNormalizeHttpError, UserIdService } from '@spartacus/core';
import { CustomStatisticsService } from '../../services/custom-statistics.service';
import { Statistics, StatisticsProduct } from '../../model/custom-statistics-model';

@Injectable()
export class CustomStatisticsEffects {
  constructor(
    private actions$: Actions,
    protected customStatisticsService: CustomStatisticsService,
    protected userIdService: UserIdService,
    private logger: LoggerService
  ) {}

  loadStatistics$: Observable<StatisticsActions> = createEffect(() => this.actions$.pipe(
    ofType(StatisticsActionType.LOAD_STATISTICS),
    map((action: LoadStatistics) => action.payload),
    mergeMap((payload) => {
      return this.customStatisticsService.loadAll(payload.userId, payload.years).pipe(
        map((value: Statistics[]) => {
          return new LoadStatisticsSuccess(value);
        }),
        catchError((error) => of(new LoadStatisticsFail(tryNormalizeHttpError(error, this.logger))))
      );
    })
  ));

  loadStatisticsProducts$: Observable<StatisticsProductActions> = createEffect(() => this.actions$.pipe(
    ofType(StatisticsActionType.LOAD_STATISTICS_PRODUCTS),
    map((action: LoadStatisticsProducts) => action.payload),
    mergeMap((payload) => {
      return this.customStatisticsService.loadAllProducts(payload.userId, payload.years).pipe(
        map((value: StatisticsProduct[]) => {
          return new LoadStatisticsProductsSuccess(value);
        }),
        catchError((error) => of(new LoadStatisticsProductsFail(tryNormalizeHttpError(error, this.logger))))
      );
    })
  ));
}
