import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { CustomNewsListModel } from '../custom-news.model';
import {
  LOAD_NEWS_LIST,
  LoadNewsList,
  LoadNewsListFail,
  LoadNewsListSuccess,
} from '../actions/custom-news-list.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  GlobalMessageActions,
  GlobalMessageType,
  HttpErrorModel,
  LoggerService,
  tryNormalizeHttpError
} from '@spartacus/core';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';

@Injectable()
export class CustomNewsListEffects {

  loadNews$: Observable<LoadNewsListSuccess | LoadNewsListFail | AddMessage> =
    createEffect(() => this.actions$.pipe(
      ofType(LOAD_NEWS_LIST),
      map((action: LoadNewsList) => action.payload),
      switchMap((newsParams) =>
        this.newsConnector.getNewsList(newsParams)
          .pipe(
            map((response: CustomNewsListModel) => {
              return new LoadNewsListSuccess(response);
            }),
            catchError((error) => {
              const normalizedResponse = tryNormalizeHttpError(error, this.logger) as HttpErrorModel || {};
              const message = normalizedResponse.details[0].message;
              const text = {
                key: message,
                params: null,
              };

              return from([
                new LoadNewsListFail(normalizedResponse),
                new GlobalMessageActions.AddMessage({
                  text,
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              ]);
            }),
          ),
      ),
    ));

  constructor(
    private actions$: Actions,
    private newsConnector: CustomNewsConnector,
    private logger: LoggerService
  ) {
  }
}
