import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { CustomNewsCategoriesModel } from '../custom-news.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  GlobalMessageActions,
  GlobalMessageType, HttpErrorModel,
  LoggerService,
  tryNormalizeHttpError
} from '@spartacus/core';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';
import {
  LOAD_NEWS_CATEGORIES,
  LoadNewsCategoriesFail,
  LoadNewsCategoriesSuccess,
} from '../actions/custom-news-categories.actions';

@Injectable()
export class CustomNewsCategoriesEffects {

  loadNewsCategories$: Observable<LoadNewsCategoriesSuccess | LoadNewsCategoriesFail | AddMessage> =
  createEffect(() => this.actions$.pipe(
      ofType(LOAD_NEWS_CATEGORIES),
      switchMap(() =>
        this.newsConnector.getNewsCategories()
        .pipe(
          map((response: CustomNewsCategoriesModel) => {
            return new LoadNewsCategoriesSuccess(response);
          }),
          catchError((error) => {
            const normalizedResponse = tryNormalizeHttpError(error, this.logger) as HttpErrorModel || {};;
            const message = normalizedResponse.details[0].message;
            const text = {
              key: message,
              params: null,
            };

            return from([
              new LoadNewsCategoriesFail(normalizedResponse),
              new GlobalMessageActions.AddMessage({
                text,
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]);
          }),
        ),
      ),
    ));

  constructor(
    private actions$: Actions,
    private newsConnector: CustomNewsConnector,
    private logger: LoggerService
  ) {
  }
}
