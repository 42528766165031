import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import {
  CmsPageGuard,
  FormErrorsModule,
  PageLayoutComponent
} from '@spartacus/storefront';
import { CustomAmendOrderItemsModule } from '../../custom-amend-order-items/custom-amend-order-items.module';
import { CustomOrderReturnService } from '../custom-order-return.service';
import { CustomReturnOrderComponent } from './custom-return-order.component';
import { AmendOrderActionsModule, OrderAmendService } from '@spartacus/order/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturn',
        },
      },
    ]),
    CustomAmendOrderItemsModule,
    AmendOrderActionsModule,
    FormErrorsModule,
    I18nModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        ReturnOrderComponent: {
          component: CustomReturnOrderComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: CustomOrderReturnService,
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomReturnOrderComponent],
  exports: [CustomReturnOrderComponent],
})
export class CustomReturnOrderModule {}
