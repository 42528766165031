import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OsborneDeviceService {
  private userAgent: string = navigator.userAgent.toLowerCase();

  isAndroid(): boolean {
    return this.userAgent.includes('android');
  }

  isIOS(): boolean {
    return /iphone|ipad|ipod/.test(this.userAgent);
  }

  isWindows(): boolean {
    return /windows/.test(this.userAgent);
  }

  isMac(): boolean {
    return /macintosh|mac os x/.test(this.userAgent);
  }

  isLinux(): boolean {
    return /linux/.test(this.userAgent) && !this.isAndroid();
  }

  isMobile(): boolean {
    return /android|iphone|ipad|ipod/.test(this.userAgent);
  }
}
