import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BasePageMetaResolver, HttpErrorHandler, PageMetaResolver, PRODUCT_NORMALIZER } from '@spartacus/core';
import { BaseStorefrontModule, CurrentProductService, SeoMetaService } from '@spartacus/storefront';
import { CustomProductPrettyNameNormalizer } from '../services/product/custom-product-pretty-name-normalizer';
import { BaseStoreInterceptor } from './base-store.interceptor';
import { CurrentCustomerInterceptor } from './current-customer.interceptor';
import { CustomSeoMetaService } from './custom/cms-structure/seo/custom-seo-meta.service';
import { CustomBasePageMetaResolver } from './custom/core/cms/page/custom-base-page-meta.resolver';
import { CustomContentPageMetaResolver } from './custom/core/cms/page/custom-content-page-meta.resolver';
import { CustomCategoryPageMetaResolver } from './custom/core/product/services/custom-category-page-meta.resolver';
import { CustomProductPageMetaResolver } from './custom/core/product/services/custom-product-page-meta.resolver';
import { CustomSearchPageMetaResolver } from './custom/core/product/services/custom-search-page-meta.resolver';
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusCustomModule } from './spartacus-custom.module';
import { SpartacusFeaturesModule } from './spartacus-features.module';
import {CustomCurrentProductService} from "./custom/core/product/services/custom-current-product.service";
import { CustomVoucherBadRequestHandler } from './custom/core/global-message/http-interceptors/handlers/bad-request/custom-voucher-bad-request.handler';
import { CustomBadRequestHandler } from './custom/core/global-message/http-interceptors/handlers/bad-request/custom-bad-request.handler';
import { CustomForbiddenHandler } from './custom/core/global-message/http-interceptors/handlers/custom-forbidden.handler';
import { CustomBadCartRequestHandler } from './custom/core/global-message/http-interceptors/handlers/bad-request/custom-bad-cart-request.handler';
import { CustomNewsPageMetaResolver } from './custom/core/cms/page/custom-news-page-meta.resolver';
import { WebAuthnService } from './custom/core/user/facade/webauthn.service';
import { OsborneWebAuthnAdapter } from './custom/core/user/connectors/profile/occ-webauthn-adapter';
import { OsborneWebAuthnConnector } from './custom/core/user/connectors/profile/webauthn.connector';

export const CustomSharedSpartacusModuleProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseStoreInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CurrentCustomerInterceptor,
    multi: true
  },
  {
    provide: HttpErrorHandler,
    useExisting: CustomVoucherBadRequestHandler,
    multi: true,
  },
  {
    provide: HttpErrorHandler,
    useExisting: CustomBadRequestHandler,
    multi: true,
  },
  {
    provide: HttpErrorHandler,
    useExisting: CustomForbiddenHandler,
    multi: true,
  },
  {
    provide: HttpErrorHandler,
    useExisting: CustomBadCartRequestHandler,
    multi: true,
  },
  {
    provide: BasePageMetaResolver,
    useClass: CustomBasePageMetaResolver,
    multi: false,
  },
  {
    provide: PageMetaResolver,
    useExisting: CustomContentPageMetaResolver,
    multi: true,
  },
  {
    provide: PageMetaResolver,
    useExisting: CustomNewsPageMetaResolver,
    multi: true,
  },
  {
    provide: PageMetaResolver,
    useExisting: CustomSearchPageMetaResolver,
    multi: true,
  },
  {
    provide: PageMetaResolver,
    useExisting: CustomProductPageMetaResolver,
    multi: true,
  },
  {
    provide: PageMetaResolver,
    useExisting: CustomCategoryPageMetaResolver,
    multi: true,
  },
  {
    provide: SeoMetaService,
    useExisting: CustomSeoMetaService,
  },
  {
    provide: PRODUCT_NORMALIZER,
    useClass: CustomProductPrettyNameNormalizer,
    multi: true
  },
  {
    provide: CurrentProductService,
    useExisting: CustomCurrentProductService,
  },
  WebAuthnService,
  OsborneWebAuthnConnector,
  OsborneWebAuthnAdapter,
];

@NgModule({
  declarations: [],
  providers: CustomSharedSpartacusModuleProviders,
  imports: [
    BaseStorefrontModule,
    SpartacusFeaturesModule,
    SpartacusConfigurationModule,
    SpartacusCustomModule
  ],
  exports: [BaseStorefrontModule]
})
export class SpartacusModule { }
