import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomImageInfoComponent } from './custom-image-info.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';
import { CustomParagraphModule } from '../../../../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomImageInfoComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomImageInfoComponent: {
          component: CustomImageInfoComponent,
        },
      },
    } as CmsConfig),
    YouTubePlayerModule,
    UrlModule,
    HtmlSanitizeModule,
    CustomParagraphModule,
  ],
  exports: [CustomImageInfoComponent],
})
export class CustomImageInfoModule {}
