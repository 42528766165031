import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductAttributesComponent } from './custom-product-attributes.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomProductAttributesComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductSpecsTabComponent: {
          component: CustomProductAttributesComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomProductAttributesModule { }
