import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig, DeferLoadingStrategy,
  FeaturesConfigModule,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule
} from '@spartacus/core';
import { defaultViewConfig, IconModule, ItemCounterModule, ListNavigationModule, MediaModule, OutletModule, SpinnerModule, StarRatingModule, ViewConfig } from '@spartacus/storefront';
import {ProductVariantsModule} from '@spartacus/product/variants';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  CustomProductLabelsModule
} from "../../../../../custom/cms-components/product/custom-product-labels/custom-product-labels.module";
import { CustomAddToCartModule } from '../../cart/custom-add-to-cart/custom-add-to-cart.module';
import { ProductListComponent } from './container/product-list.component';
import { ProductScrollComponent } from './container/product-scroll/product-scroll.component';
import { ProductFacetNavigationComponent } from './product-facet-navigation/product-facet-navigation.component';
import { ProductGridItemComponent } from './product-grid-item/product-grid-item.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { ProductViewComponent } from './product-view/product-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    CustomAddToCartModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    StarRatingModule,
    IconModule,
    SpinnerModule,
    InfiniteScrollModule,
    ProductVariantsModule,
    FeaturesConfigModule,
    OutletModule,
    FormsModule,
    ReactiveFormsModule,
    CustomProductLabelsModule,
  ],
  providers: [
    provideConfig(defaultViewConfig as ViewConfig),
    provideConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: ProductListComponent,
          deferLoading: DeferLoadingStrategy.DEFER
        },
        ProductGridComponent: {
          component: ProductListComponent,
          deferLoading: DeferLoadingStrategy.DEFER
        },
        SearchResultsListComponent: {
          component: ProductListComponent,
          deferLoading: DeferLoadingStrategy.DEFER
        },
      },
    } as CmsConfig),
  ],
  declarations: [
    ProductListComponent,
    ProductListItemComponent,
    ProductGridItemComponent,
    ProductViewComponent,
    ProductScrollComponent,
  ],
  exports: [
    ProductListComponent,
    ProductListItemComponent,
    ProductGridItemComponent,
    ProductViewComponent,
    ProductScrollComponent,
  ],
})
export class ProductListModule { }
