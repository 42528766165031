import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomEmptyCartActionsComponent } from './custom-empty-cart-actions.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomEmptyCartActionsComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomEmptyCartActionsComponent: {
          component: CustomEmptyCartActionsComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [CustomEmptyCartActionsComponent],
})
export class CustomEmptyCartActionsModule { }
