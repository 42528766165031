import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { Price } from '@spartacus/core';
import { Unit } from 'src/app/model/unit';
import { UnitPrice } from 'src/app/model/unit-price';

@Component({
  selector: 'app-custom-unit-display',
  templateUrl: './custom-unit-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomUnitDisplayComponent implements OnInit {
  @Input() unitPrice: UnitPrice;
  @Input() price: Price;
  @Input() unit: Unit;
  @Input() showNostockText = false;
  @Input() from?: boolean = false;
  @HostBinding('class') styleClass = '';

  ngOnInit(): void {
    this.styleClass = this.unitPrice || this.unit ? 'unit' : '';
  }
}
