import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  GlobalMessageActions,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  LoggerService,
  tryNormalizeHttpError,
  UserIdService
} from '@spartacus/core';
import { CustomFriendsConnector } from '../../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/connectors/friends.connector';
import { from, Observable } from 'rxjs';
import {
  FriendsEmailsActionTypes,
  ResetFriendsEmails,
  SelectAllFriends,
  SelectAllFriendsFail,
  SelectAllFriendsSuccess,
  SelectFriendEmails,
  SelectFriendEmailsFail,
  SelectFriendEmailsSuccess,
  SendFriendEmails,
  SendFriendEmailsFail,
  SendFriendEmailsSuccess,
} from '../actions/custom-friends-email.action';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EmailInformationModel } from '../../CustomerEmail.model';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';
import { MyFriendsList } from '../../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { CustomMyFriendListService } from '../../../custom-my-friends-list/custom-my-friend-list.service';


@Injectable()
export class CustomGuestFriendsEffects {
  constructor(
    private actions$: Actions,
    private friendsConnector: CustomFriendsConnector,
    protected userIdService: UserIdService,
    private globalMessageService: GlobalMessageService,
    protected customMyFriendListService: CustomMyFriendListService,
    private logger: LoggerService
  ) {}

  selectFriendEmail$: Observable<SelectFriendEmails | SelectFriendEmailsFail | SelectFriendEmailsSuccess> =
  createEffect(() => this.actions$.pipe(
      ofType(FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS),
      map((action: SelectFriendEmails) => action.payload),
      map((friendsList: Array<{ name: string; email: string }>) => new SelectFriendEmailsSuccess(friendsList)),
    ));

  sendEmail$: Observable<SendFriendEmails | SendFriendEmailsSuccess | SendFriendEmailsFail | ResetFriendsEmails | GlobalMessageActions.AddMessage> =
  createEffect(() => this.actions$.pipe(
      ofType(FriendsEmailsActionTypes.SEND_FRIEND_EMAILS),
      map((action: SendFriendEmails) => action.payload),
      mergeMap((payload: { userId: string; emailInformation: EmailInformationModel }) => {
        return this.friendsConnector.sendEmail(payload.userId, payload.emailInformation).pipe(
          switchMap((response: any) => {
            return [
              new SendFriendEmailsSuccess(null),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.emails.success' },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new ResetFriendsEmails(),
            ];
          }),
          catchError((response) => {
              const normalizedResponse = tryNormalizeHttpError(response, this.logger) as HttpErrorModel || {};;
              const message = normalizedResponse.details[0].message;
              const text = {
                key: message,
                params: null,
              };
              const isMissingUid = message.split('Cannot find user with uid');
              if (isMissingUid.length > 1) {
                text.key = 'myFriends.emails.errors.toCustomersUid';
                text.params = { emailList: isMissingUid[1].trim() };
              }

              return from([
                new SendFriendEmailsFail(normalizedResponse),
                new GlobalMessageActions.AddMessage({
                  text,
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
                new ResetFriendsEmails(),
              ]);
            },
          ),
        );
      }),
    ));

  selectFriend$: Observable<SelectAllFriendsSuccess | SelectAllFriendsFail | SelectFriendEmailsSuccess | AddMessage> = createEffect(() => this.actions$.pipe(
    ofType(FriendsEmailsActionTypes.SELECT_ALL_FRIENDS),
    map((action: SelectAllFriends) => action.payload),
    mergeMap((payload) => {
      return this.friendsConnector.getAll(
        payload.userId,
        payload.pageSize,
        payload.currentPage,
        payload.friendsFilter,
      ).pipe(
        mergeMap((friends: MyFriendsList) => {
          const members = friends.members.map(member => this.customMyFriendListService.convertFriendToEmailData(member));
          return [
            new SelectAllFriendsSuccess(members),
            new SelectFriendEmailsSuccess(members),
          ];
        }),
        catchError((error) => {
            return from(
              [
                new SelectAllFriendsFail(tryNormalizeHttpError(error, this.logger)),
                new GlobalMessageActions.AddMessage({
                  text: { key: 'myFriends.friendsNoLoaded' },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              ]);
          },
        ),
      );
    }),
  ));

  deselectFriendEmail$: Observable<SelectFriendEmails | SelectFriendEmailsFail | SelectFriendEmailsSuccess> =
  createEffect(() => this.actions$.pipe(
      ofType(FriendsEmailsActionTypes.RESET_FRIEND_EMAILS),
      map((action: ResetFriendsEmails) => action),
      map(() => new SelectFriendEmailsSuccess([])),
    ));
}

export const FriendsEmailsEffects: any[] = [CustomGuestFriendsEffects];
