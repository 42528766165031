import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from "@angular/core";
import {ProductUnitPrice} from "../../../../model/unit-price";

@Component({
  selector: 'app-custom-unit-selector',
  templateUrl: 'custom-unit-selector.component.html'
})
export class CustomUnitSelectorComponent implements OnInit {
  @Input() unitPrices: ProductUnitPrice[];
  @Input() selectedUnitPrice?: ProductUnitPrice;
  @Input() showNostockText: boolean = false;
  @Output() selectionChanged: EventEmitter<ProductUnitPrice> = new EventEmitter<ProductUnitPrice>();

  showOptions: boolean = false;
  selectedOption?: ProductUnitPrice;
  fromOption?: ProductUnitPrice;

  ngOnInit(): void {
    this.fromOption = this.unitPrices.reduce((min, unitPrice) => {
      return unitPrice.unitPrice.price.value < min.unitPrice.price.value ? unitPrice : min;
    });
  }

  

  selectOption(option: ProductUnitPrice): void {
    this.selectedOption = option;
    this.selectionChanged.emit(option);
  }

  toggleOptions(): void {
    this.showOptions = !this.showOptions;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('app-custom-unit-selector')) {
      this.showOptions = false;
    }
  }
}
