import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoItemComponent } from './info-item.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule, OutletModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';
import { CustomParagraphModule } from '../../../../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [InfoItemComponent],
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        InfoItemComponent: {
          component: InfoItemComponent,
        },
      },
    } as CmsConfig),
    HtmlSanitizeModule,
    CustomParagraphModule
  ],
  exports: [InfoItemComponent],
})
export class InfoItemModule { }
