import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  OCC_USER_ID_ANONYMOUS,
  PaymentDetails,
  UserIdService,
  WindowRef,
  isNotUndefined
} from '@spartacus/core';
import { ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { fadeIn, fadeOut, rotatedState } from 'src/app/custom/custom-animations';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';
import { CustomFriendsCheckoutModel } from '../../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.model';
import { CustomFriendsCheckoutService } from '../../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.service';
import { CreditCard, Payments, StorageSaveCreditCard } from '../custom-credit-card/store/credit-card.state';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { Cart, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { B2BPaymentTypeEnum, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CustomCheckoutPaymentService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment.service';

@Component({
  selector: 'cx-payment-type',
  templateUrl: './custom-payment-type.component.html',
  styleUrls: ['./custom-payment-type.component.scss'],
  animations: [fadeIn, fadeOut, rotatedState],
})
export class CustomCheckoutPaymentTypeComponent extends CheckoutPaymentTypeComponent {

  // tslint:disable-next-line:variable-name
  @ViewChild('saveCreditCard', { static: false })
  // tslint:disable-next-line:variable-name
  private _saveCreditCard: ElementRef;
  selectedPaymentTypeSub: Subscription;
  customPaymentType = B2BPaymentTypeEnum;
  disableSaveCreditCard: boolean;

  paymentForm: FormGroup = this.fb.group({
    paymentType: ['', Validators.required],
  });

  paymentTypes$: Observable<PaymentType[]> = this.checkoutPaymentTypeService.getPaymentTypes();
  typeSelected$: Observable<PaymentType> = combineLatest([
    this.checkoutPaymentTypeService.getSelectedPaymentTypeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data)
    ),
    this.paymentTypes$,
  ]).pipe(
    map(
      ([selectedPaymentType, availablePaymentTypes]: [
        PaymentType | undefined, PaymentType[]
      ]) => {
        if (selectedPaymentType &&
          availablePaymentTypes.find((availablePaymentType) => {
            return availablePaymentType.code === selectedPaymentType.code;
          })
        ) {
          return selectedPaymentType;
        }
        if (availablePaymentTypes.length) {
          this.busy$.next(true);
          let paymentTypeData;
          this.subscriptions.add(
            this.checkoutPaymentTypeService.setPaymentType(availablePaymentTypes[0].code as B2BPaymentTypeEnum).pipe(
              map((data) => paymentTypeData = data)).subscribe(
                (data) => this.onSuccessPaymentType(paymentTypeData),
                (error) => this.onError()
              )
          );
          return availablePaymentTypes[0];
        }
      }
    ),
    filter(isNotUndefined),
    distinctUntilChanged(),
    tap((selected) => {
      this.typeSelected = selected?.code;
      this.checkoutStepService.resetSteps();
      this.checkoutStepService.disableEnableStep(
        CheckoutStepType.PAYMENT_DETAILS,
        selected?.code === B2BPaymentTypeEnum.BANK_ACCOUNT_PAYMENT
      );
    })
  );

  constructor(
    protected checkoutPaymentTypeService: CustomCheckoutPaymentTypeService,
    protected checkoutPaymentService: CustomCheckoutPaymentService,
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected auth: AuthService,
    protected activeCartService: CustomActiveCartService,
    protected customFriendsCheckoutService: CustomFriendsCheckoutService,
    protected modalService: LaunchDialogService,
    protected fb: FormBuilder,
    protected globalMessageService: GlobalMessageService,
    protected userIdService: UserIdService,
    protected userAccount: UserAccountFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected vcr: ViewContainerRef,
    protected winRef?: WindowRef,
  ) {
    super(checkoutPaymentTypeFacade, checkoutStepService, activatedRoute, globalMessageService);

    this.subscriptions.add(
      this.paymentTypes$.subscribe(
        (paymentTypes: PaymentType[]) => {
          if (paymentTypes.length > 0) {
            this.allowPaymentTypes = true;
            const allowCreditCard = paymentTypes.find(paymentType => paymentType.code === this.customPaymentType.CREDIT_CARD_PAYMENT);
            if (!!allowCreditCard) {
              this.allowCreditCard = !!allowCreditCard;
              this.loadCreditCards();
            }

            setTimeout(function () {
              const creditCardCheck = document.getElementById('paymentType-CreditCard') as HTMLInputElement;
              const bizumCheck = document.getElementById('paymentType-Bizum') as HTMLInputElement;
              const paypalCheck = document.getElementById('paymentType-PayPal') as HTMLInputElement;

              if (!creditCardCheck?.checked && !bizumCheck?.checked && !paypalCheck?.checked) {
                creditCardCheck?.click();
              }
            }, 800);
          }
        }
      ));

    let userId: string;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    if (userId !== OCC_USER_ID_ANONYMOUS) {
      this.checkoutPaymentTypeService.getPaymentDetails().subscribe(paymentDetails => {
        if (paymentDetails?.id?.length) {
          this.selectedCreditCardId = paymentDetails.id;
        }
      });
    }
  }

  onBehalfOf: CustomFriendsCheckoutModel;
  showCoupon: boolean;
  state = 'default';
  checkoutStepType = CheckoutStepType.PAYMENT_TYPE;
  user$: Observable<User>;
  typeSelected: string;
  iconTypes = ICON_TYPE;
  backBtnText = this.checkoutStepService.getBackBntText(this.activatedRoute);
  showCreditCardList = false;
  paymentsList$: Observable<Payments>;
  paymentsLisLoading = false;
  subscriptions = new Subscription();
  selectedCreditCardId: string;
  firstLoad = true;
  setPaymentType = false;
  creditCardPayment = B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT;
  allowCreditCard = false;
  formValue = null;
  allowPaymentTypes = false;
  selectedPaymentType: PaymentType;

  ngOnInit(): void {

    this.changeType(B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT, this.setPaymentType, this.selectedCreditCardId);

    this.user$ = this.activeCartService.isGuestCart() ?
      this.activeCartService.getAssignedUser() :
      this.auth.isUserLoggedIn().pipe(
        switchMap((isUserLoggedIn) => {
          if (isUserLoggedIn) {
            return this.userIdService
              .takeUserId(true)
              .pipe(switchMap((userId) => this.userAccount.getById(userId)))
          } else {
            return of(undefined);
          }
        })
      );

    this.subscriptions.add(
      this.customFriendsCheckoutService.getSelectedOnBehalfOfFromCart()
        .subscribe((onBehalfOf: CustomFriendsCheckoutModel) => this.onBehalfOf = onBehalfOf),
    );
  }

  loadCreditCards(): void {
    if (this.allowCreditCard) {
      this.checkoutPaymentTypeService.loadCreditCardList();
      this.paymentsList$ = this.checkoutPaymentTypeService.getCreditCardList();
      this.subscriptions.add(
        this.paymentsList$.pipe().subscribe(paymentsList => {
          if (paymentsList) {
            this.findPaymentType(paymentsList);
          }
        }),
      );
      this.checkoutPaymentTypeService.saveCreditCardDefaultState();
    }

    this.subscriptions.add(
      combineLatest([
        this.checkoutPaymentTypeService.getCreditCardLoadingState(),
        this.checkoutPaymentTypeService.getSaveCreditCardStateSuccess(),
        this.checkoutPaymentTypeService.getSaveCreditCardStateError(),
      ]).subscribe(([isLoading, success, error]) => {
        this.paymentsLisLoading = isLoading;
        if (success || error) {
          this.paymentsLisLoading = false;
        }
      }),
    );
  }

  findPaymentType(paymentsList): void {
    const matchSelectedCard = paymentsList.payments.filter(creditCard => creditCard.id === this.selectedCreditCardId);
    if (matchSelectedCard.length && matchSelectedCard[0].id !== B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT) {
      this.setPaymentType = true;
      this.changeType(B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT, this.setPaymentType, matchSelectedCard[0].id);
    }
  }

  rotate(): void {
    this.state = this.state === 'default' ? 'rotated' : 'default';
  }

  showCouponApply(): void {
    this.showCoupon = !this.showCoupon;
    this.rotate();
  }

  changeType(code: string, setPayment: boolean = true, value: string = null): void {
    this.toggleDisableSaveCreditCard(Boolean(value));
    this.typeSelected = code;
    this.formValue = this.typeSelected;
    if (setPayment) {
      if (value) {
        let paymentTypeData;
        this.subscriptions.add(
          this.checkoutPaymentTypeService.setPaymentType(code as B2BPaymentTypeEnum, null).pipe(
            map((data) => paymentTypeData = data)).subscribe(
              (data) => this.onSuccessPaymentType(paymentTypeData, value),
              (error) => console.log(error)
            )
        );
        this.paymentForm.get('paymentType').patchValue(value);
        this.formValue = value;
        this.busy$.next(false);

      }
    }
    this.busy$.next(false);

    if (!value) {
      this.setPaymentType = false;
      this.selectedCreditCardId = null;
      let paymentTypeData;

      this.subscriptions.add(
        this.checkoutPaymentTypeService.setPaymentType(code as B2BPaymentTypeEnum).pipe(
          map((data) => paymentTypeData = data),
          catchError((error) => {
            return EMPTY;
          })
        ).subscribe(

          (data) => this.onSuccessPaymentType(paymentTypeData)
        )

      );
      this.paymentsLisLoading = false;

      this.busy$.next(false);
      this.paymentForm.get('paymentType').patchValue(code);
    }
  }

  protected onSuccessPaymentType(data: Cart, paymentInfo?: string): void {
    this.selectedPaymentType = data?.paymentMode;
    this.paymentsLisLoading = false;
    this.checkoutPaymentTypeService.setCartData(data);
    if (paymentInfo) {
      this.checkoutPaymentTypeService.selectCreditCard(this.selectedPaymentType, paymentInfo)
    }
  }

  next(): void {
    switch (true) {
      case (!this.allowPaymentTypes): {
        this.paymentForm.get('paymentType').patchValue(null);
        this.resetSavedCreditCard();
        this.globalMessageService.add({ key: 'checkoutProgress.missingPaymentMethodOptions' }, GlobalMessageType.MSG_TYPE_ERROR);
        return;
      }

      case (this.paymentForm.get('paymentType').value === this.formValue): {
        return;
      }

      default:
        this.paymentForm.get('paymentType').patchValue(null);
        this.paymentForm.markAllAsTouched();
        this.resetSavedCreditCard();
        this.globalMessageService.add({ key: 'checkoutProgress.missingPaymentMethod' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  get saveCreditCard$(): Observable<boolean> {
    return this.checkoutPaymentTypeService.getSaveCreditCardState();
  }

  toggleSaveCreditCard(): void {
    var saveCard = this._saveCreditCard.nativeElement.checked
    this.checkoutPaymentTypeService.toggleSaveCreditCard(saveCard);
    this.checkoutPaymentService.setSaveCardData(saveCard);
  }

  toggleDisableSaveCreditCard(disabled: boolean): void {
    this.disableSaveCreditCard = disabled;
  }

  selectCreditCard(cardId): void {
    if (cardId) {
      this.setPaymentType = true;
      this.selectedCreditCardId = cardId;
      this.changeType(B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT, true, cardId);
      if (this._saveCreditCard) {
        this.resetSavedCreditCard();
      }
    } else {
      this.setPaymentType = false;
      this.selectedCreditCardId = null;
    }
  }

  getSelectedCreditCard(): Observable<PaymentDetails> {
    return this.checkoutPaymentTypeService.getPaymentDetails();
  }

  openDeleteCardModal(creditCard: CreditCard, paymentType: string): void {
    //CustomPaymentTypeModalComponent
    const dialog = this.modalService.openDialog(
      LAUNCH_CALLER.PAYMENT_TYPE,
      undefined,
      this.vcr,
      {
        showButtonCancel: true,
        creditCard: creditCard,
        paymentType: paymentType
      }
    );

    if (dialog) {
      this.subscriptions.add(dialog.subscribe());

      /*this.modalRef.result.then(
        (result) => {
          this.changeType(result, true);
        },
      );*/
    }
  }

  resetSavedCreditCard(): void {
    this._saveCreditCard.nativeElement.checked = false;
    this.checkoutPaymentService.setSaveCardData(false);
    this.winRef?.localStorage?.setItem(StorageSaveCreditCard.SAVE_CREDIT_CARD, false.toString());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
