import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  UserAddressService,
  UserIdService
} from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { AddressBookComponentService } from "@spartacus/user/profile/components";
import { UserProfileFacade } from "@spartacus/user/profile/root";
import { CustomHeaderTablesModule } from 'src/app/custom/cms-components/myaccount/custom-header-tables/custom-header-tables.module';
import { CustomRowModule } from 'src/app/custom/cms-components/myaccount/tables/custom-row.module';
import { CustomUpdateProfileComponentService } from "../../../feature-libs/user/profile/components/update-profile/custom-update-profile-component.service";
import { CustomAddressFormModule } from '../../checkout/components/shipping-address/address-form/custom-address-form.module';
import { CustomAddressBookComponent } from './custom-address-book.component';

@NgModule({
  declarations: [CustomAddressBookComponent],
  imports: [
    CommonModule,
    CardModule,
    CustomAddressFormModule,
    SpinnerModule,
    I18nModule,
    CustomRowModule,
    CustomHeaderTablesModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountAddressBookComponent: {
          component: CustomAddressBookComponent,
          providers: [
            {
              provide: AddressBookComponentService,
              useClass: AddressBookComponentService,
              deps: [UserAddressService],
            },
            {
              provide: CustomUpdateProfileComponentService,
              useClass: CustomUpdateProfileComponentService,
              deps: [UserProfileFacade, GlobalMessageService, UserAccountFacade, UserIdService],
            }
          ],
          guards: [AuthGuard],
        }
      }
    } as CmsConfig)
  ],
  providers: [
    UserAddressService
  ]
})
export class CustomAddressBookModule { }
