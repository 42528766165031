import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { CustomMediaLinksComponent } from './custom-media-links.component';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomMediaLinksComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomMediaLinksComponent: {
          component: CustomMediaLinksComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomMediaLinksComponent],
})
export class CustomMediaLinksModule {}
