import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBannerAndProductsGridComponent } from './custom-banner-and-products-grid.component';
import { CmsConfig, ConfigModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomAddToCartModule } from '../../../../spartacus/custom/cms-components/cart/custom-add-to-cart/custom-add-to-cart.module';

@NgModule({
  declarations: [CustomBannerAndProductsGridComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsBannerAndProductsGridComponent: {
          component: CustomBannerAndProductsGridComponent,
        },
      },
    } as CmsConfig),
    MediaModule,
    RouterModule,
    IconModule,
    CustomAddToCartModule,
    UrlModule,
    SpinnerModule
  ],
  exports: [CustomBannerAndProductsGridComponent],
})
export class CustomBannerAndProductsGridModule {}
