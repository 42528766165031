import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  GlobalMessageActions,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  LoggerService,
  tryNormalizeHttpError,
  UserIdService
} from '@spartacus/core';
import {
  CreateGuestFriend,
  CreateGuestFriendFail,
  CreateGuestFriendSuccess,
  GuestFriendsActionTypes,
  LoadGuestFriend,
  LoadGuestFriendFail,
  LoadGuestFriendSuccess,
  ResetGuestFriends,
} from '../actions/custom-friends-guest.action';
import { Friend } from '../../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { CustomFriendsConnector } from '../../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/connectors/friends.connector';


@Injectable()
export class CustomGuestFriendsEffects {
  constructor(
    private actions$: Actions,
    private friendsConnector: CustomFriendsConnector,
    protected userIdService: UserIdService,
    private globalMessageService: GlobalMessageService,
    private logger: LoggerService
  ) {}

  loadFriend$: Observable<LoadGuestFriendSuccess | LoadGuestFriendFail | GlobalMessageActions.AddMessage> =
  createEffect(() => this.actions$.pipe(
      ofType(GuestFriendsActionTypes.LOAD_GUEST_FRIEND),
      map((action: LoadGuestFriend) => action.token),
      mergeMap((token: string) => {
        return this.friendsConnector.getGuest(token).pipe(
          map((friend: any) => {
            const value: Friend = {
              ...friend,
              defaultPaymentAddress: friend.defaultAddress ? friend.defaultAddress : '',
              firstName: friend.firstName + (friend.lastName ? ' ' + friend.lastName : ''),
            };
            return new LoadGuestFriendSuccess(value);
          }),
          catchError((error) => {
            this.globalMessageService.add(
              { key: 'myFriends.invalidToken' },
              GlobalMessageType.MSG_TYPE_ERROR,
            );
            return of(
              new LoadGuestFriendFail(tryNormalizeHttpError(error, this.logger)),
            );
          }),
        );
      }),
    ));

  createFriend$: Observable<CreateGuestFriendFail | CreateGuestFriendSuccess | GlobalMessageActions.AddMessage | ResetGuestFriends> =
  createEffect(() => this.actions$.pipe(
      ofType(GuestFriendsActionTypes.CREATE_GUEST_FRIEND),
      map((action: CreateGuestFriend) => action.payload),
      mergeMap((payload: { friendSignUp: Friend; token: string }) => {
        return this.friendsConnector.addGuest(payload.friendSignUp, payload.token).pipe(
          switchMap((response: any) => {
            return [
              new CreateGuestFriendSuccess({uid: payload.friendSignUp.uid}),
              new GlobalMessageActions.AddMessage({
                text: { key: 'myFriends.friendCreated' },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new ResetGuestFriends(),
            ];
          }),
          catchError((response) => {
              const normalizedError = tryNormalizeHttpError(response, this.logger) as HttpErrorModel || {};
              const actionsArray = [];
             normalizedError.details.map(error =>
                actionsArray.push(new GlobalMessageActions.AddMessage({
                    text: { key: error.type === 'ModelSavingError' ? `myFriends.${ error.type }` : 'friendsNoCreated' },
                    type: GlobalMessageType.MSG_TYPE_ERROR,
                  }),
                ),
              );
              return from([
                new CreateGuestFriendFail(normalizedError),
                  ...actionsArray,
                  new ResetGuestFriends(),
                ],
              );
            },
          ),
        );
      }),
    ));
}

export const FriendsGuestEffects: any[] = [CustomGuestFriendsEffects];
