import { Cart, OrderEntry } from '@spartacus/cart/base/root';
import { Address, Product } from '@spartacus/core';
import { GiftCard } from 'src/app/custom/cms-components/checkout/custom-gift-card/model/custom-gift-card.model';
import { GiftBoxProduct } from 'src/app/custom/cms-components/giftbox/model/custom-giftbox.model';
import { EntryGroup } from 'src/app/model/entry-groups.model';
import { ProductReferenceTypeEnum } from 'src/app/model/product.model';
import { SeverityEnum } from 'src/app/model/severity.model';
import { Unit } from 'src/app/model/unit';
import { CustomFriendsCheckoutModel } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.model';

declare module '@spartacus/cart/base/root' {
    interface Cart {
      paymentMode?: PaymentType;
      deliveryNoteObservations?: string;
      entryGroups?: EntryGroup[];
      onBehalfOf?: CustomFriendsCheckoutModel;
      giftCardPrice?: {
        value: string;
        formattedValue: string;
      };
      giftCardProduct?: GiftCard;
      giftBoxProduct?: GiftBoxProduct;
      filledNumberOfSlots?: number;
      minNumberOfSlots?: number;
      maxNumberOfSlots?: number;
      paymentAddress?: Address;
    }
  
    interface PaymentType {
      name?: string;
      description?: string;
    }
  
    interface CartModification {
      severity?: SeverityEnum;
    }
  }

  export interface CustomOrderEntry extends OrderEntry {
    unit?: Unit;
    mandatory?: ProductReferenceTypeEnum;
    numberOfSlots?: number;
    requiredQuantity?: number;
    packProductReferenceQuantity?: number;
    packGroup?: number;
    packProduct?: Product;
    initialWarehouse?: string;
  }