import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CustomWishListComponent } from './components/wish-list/custom-wish-list.component';
import { MediaModule, StarRatingModule, ItemCounterModule } from '@spartacus/storefront';
import { CustomAddToCartModule } from 'src/app/spartacus/custom/cms-components/cart/custom-add-to-cart/custom-add-to-cart.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducerWishlistProvider, reducerWishlistToken } from './store/reducer/custom-wishlist.reducer';
import { CustomOccWishlistAdapter } from './connectors/custom-occ-wishlist.adapter';
import { CustomWishlistAdapter } from './connectors/custom-wishlist.adapter';
import { USER_WISHLIST_FEATURE } from './store/selector/custom-wishlist.selectors';
import { CustomUserWishlistEffect } from './store/effects/custom-wishlist.effect';
import { ProductListModule } from 'src/app/spartacus/custom/cms-components/product/product-list';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';

@NgModule({
  imports: [
    CustomAddToCartModule,
    CommonModule,
    I18nModule,
    MediaModule,
    RouterModule,
    StarRatingModule,
    UrlModule,
    ItemCounterModule,
    ProductListModule,
    CustomIconsModule,
    StoreModule.forFeature(USER_WISHLIST_FEATURE, reducerWishlistToken),
    EffectsModule.forFeature([CustomUserWishlistEffect]),
    ConfigModule.withConfig({
      cmsComponents: {
        WishListComponent: {
          component: CustomWishListComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomWishListComponent],
  exports: [CustomWishListComponent],
  providers: [
    reducerWishlistProvider,
    {
      provide: CustomWishlistAdapter,
      useClass: CustomOccWishlistAdapter,
    },
  ],
})
export class CustomWishListModule {}
