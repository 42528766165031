import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHoverAwareImageComponent } from './custom-hover-aware-image.component';
import { MediaModule } from '@spartacus/storefront';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';


@NgModule({
  declarations: [CustomHoverAwareImageComponent],
  imports: [
    CommonModule,
    MediaModule,
    CustomGenericLinkModule,
  ],
  exports: [CustomHoverAwareImageComponent],
})
export class CustomHoverAwareImageModule {}
