import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { CustomCategoryNavigationComponent } from './custom-category-navigation.component';
import { IconModule } from '@spartacus/storefront';
import { CustomCategoryNavigationUiComponent } from './custom-category-navigation-ui.component';
import { CustomGenericLinkModule } from '../../../shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    UrlModule,
    I18nModule,
    CustomGenericLinkModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CategoryNavigationComponent: {
          component: CustomCategoryNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomCategoryNavigationComponent, CustomCategoryNavigationUiComponent],
  exports: [CustomCategoryNavigationComponent, CustomCategoryNavigationUiComponent],
})
export class CustomCategoryNavigationModule {}
