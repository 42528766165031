import { Injectable } from '@angular/core';
import { AuthToken, UserIdService } from '@spartacus/core';
import { Observable, switchMap, take } from 'rxjs';
import { OsborneWebAuthnAdapter } from './occ-webauthn-adapter';
import { CredentialCreationOptionsJSON, CredentialRequestOptionsJSON } from '@github/webauthn-json';

@Injectable()
export class OsborneWebAuthnConnector {

  constructor(
    protected userService: UserIdService,
    protected adapter: OsborneWebAuthnAdapter
  ) { }

  getCredentialCreationOptions(): Observable<CredentialCreationOptionsJSON> {
    return this.userService.getUserId().pipe(
      take(1),
      switchMap(userId => this.adapter.getCredentialCreationOptions(userId))
    )
  }

  verifyCredentialRegistration(registration: string): Observable<any> {
    return this.userService.getUserId().pipe(
      take(1),
      switchMap(userId => this.adapter.verifyCredentialRegistration(userId, registration))
    )
  }

  getCredentialAuthenticationOptions(user: string): Observable<CredentialRequestOptionsJSON> {
    return this.adapter.getCredentialAuthenticationOptions(user);
  }

  verifyAuthentication(user: string, registration: string): Observable<AuthToken> {
    return this.adapter.verifyAuthentication(user, registration);
  }


}
