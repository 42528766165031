import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaypalButtonComponent } from './custom-paypal-button.component';

@NgModule({
  declarations: [CustomPaypalButtonComponent],
  exports: [CustomPaypalButtonComponent],
  imports: [
    CommonModule
  ]
})
export class CustomPaypalButtonModule { }
