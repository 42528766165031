import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomColumnsWithFileListGroupComponent } from './custom-columns-with-file-list-group.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomFilesInfoModule } from './custom-files-info/custom-files-info.module';
import { HtmlSanitizeModule } from '../../../../util/html-sanitize/html-sanitize.module';


@NgModule({
  declarations: [CustomColumnsWithFileListGroupComponent],
  exports: [
    CustomColumnsWithFileListGroupComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomColumnsWithFileList: {
          component: CustomColumnsWithFileListGroupComponent,
        },
      },
    } as CmsConfig),
    CustomFilesInfoModule,
    HtmlSanitizeModule,
  ],
})
export class CustomColumnsWithFileListGroupModule {}
