import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBoxLinkImageItemComponent } from './custom-box-link-image-item.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { IconModule, MediaModule, OutletModule, PageComponentModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';
import { customDialogModalLayoutConfig } from './custom-box-link-image-item.config';

@NgModule({
  declarations: [CustomBoxLinkImageItemComponent, CustomModalComponent],
  imports: [
    CommonModule,
    PageComponentModule,
    OutletModule,
    I18nModule,
    RouterModule,
    UrlModule,
    CustomGenericLinkModule,
    MediaModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BoxLinkImageItem: {
          component: {
            CustomBoxLinkImageItemComponent,
          },
        },
      },
    } as CmsConfig),
    HtmlSanitizeModule,
  ],
  providers: [
    provideConfig(customDialogModalLayoutConfig)
  ],
  exports: [CustomBoxLinkImageItemComponent],
})
export class CustomBoxLinkImageItemModule {}
